import { Dispatch } from 'redux';
import types from './types';
import { Process } from '../../../interfaces/ItemListInterface';

export const setItemToProcess = (process: Process) => {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      type: types.ADD_ITEM_PROCESS,
      payload: process,
    });
  };
};

export const removeItemToProcess = (process: Process) => {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      type: types.REMOVE_ITEM_PROCESS,
      payload: process,
    });
  };
};
