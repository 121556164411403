import { ItemInterface } from './ItemListInterface';

enum ItemCollectorTaskStatus {
  DRAFT,
  READY,
  FORCE_PUSH,
}

enum ItemCollectorTaskType {
  DEFAULT_ITEM_COLLECTION = 'dic',
  MISSING_ITEM_ANALYSIS_COLLECTION = 'mia',
}

interface ItemCollectorInput {
  id: string;
  description: string;
  type: ItemCollectorTaskType;
  items: Array<Partial<ItemCollectorItemInterface>>;
}

interface ItemCollectorTaskInterface {
  [index: string]: unknown;

  id: string;
  documentId?: string;
  description: string;
  items: ItemCollectorItemInterface[];
  type: ItemCollectorTaskType;
  timestamp: Date;
  updatedAt?: Date;
}

interface ItemCollectorItemInterface extends ItemInterface {
  quantity: number;
  timestamp: Date;
}

interface ItemCollectionListInterface {
  id: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  lineItems: Omit<ItemCollectorItemInterface, 'timestamp'>[];
}

export { ItemCollectorTaskStatus, ItemCollectorTaskType };

export type { ItemCollectorItemInterface, ItemCollectorTaskInterface, ItemCollectorInput, ItemCollectionListInterface };
