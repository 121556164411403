import { setItemInventoryEvents } from './actions';
import { ThunkDispatch } from 'redux-thunk';
import { DB } from '../../modules/app/client/dataProvider/storageClient';

export const setActiveEventsByInstruction =
  (instruction: string) => async (dispatch: ThunkDispatch<any, null, any>) => {
    const currentDate = new Date();

    const events = await DB.events.where({ instruction: instruction, status: 'pending' }).toArray();

    const filteredEvents = events.filter(
      (event) => currentDate > new Date(event.planStartDate) && currentDate < new Date(event.planEndDate)
    );

    if (filteredEvents.length > 0) {
      dispatch(setItemInventoryEvents(filteredEvents));
    }
  };
