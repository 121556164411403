import { Process } from '../../../interfaces/ItemListInterface';

export const getItemToProcess = (state: any): Array<Process> => state.itemsDetail.itemsToProcess;

export const getItemByProcess = (state: any, process: string) => {
  for (const item of getItemToProcess(state)) {
    if (item.target === process) {
      return item;
    }
  }
};
