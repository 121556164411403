import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TablePagination,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { ExposureZero as ExposureZeroIcon } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HistoryIcon from '@material-ui/icons/History';
import React, { useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setSnack } from '@meeva/service-client-core/redux/interface/actions';
import {
  ItemInventoryLocalTask,
  ItemInventoryStockUpdateMode,
  StockTakeStatus,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { addStockTake } from '../../redux/modules/stockTake/operations';
import ItemInventoryTaskForm from './ItemInventoryTaskForm';
import ItemInventoryTaskItem from './ItemInventoryTaskItem';
import InfoIcon from '@material-ui/icons/Info';
import ScanInput from './ScanInput';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import ImageIcon from '@material-ui/icons/Image';
import ItemImageDialog from '@meeva/service-client-core/modules/common/components/ItemImageDialog';
import { playSuccessSound, playErrorSound } from '@meeva/service-client-core/utils/audioHelper';
import { codeToSearchList } from '@meeva/service-client-core/utils/items';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    progress: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    divider: {
      backgroundColor: '#ececec',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      marginLeft: 'auto',
    },
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    taskList: {
      width: '100%',
    },
    loadingModal: {
      display: 'flex',
    },
    loadingSpinner: {
      margin: 'auto',
    },
  });

interface ItemInventoryTaskListProps {
  bulkSetZeroAllowed?: boolean;
  shelfId: string;
  tasks: ItemInventoryLocalTask[];
  inventoryLocation: [string, string];
  onListComplete: () => void;
}

interface ItemInventoryTaskListConnectedProps extends ItemInventoryTaskListProps, RouteComponentProps, WithStyles {
  setSnack: typeof setSnack;
}

//TODO: Wird auch für freeInventory/ItemInventoryTaskList.tsx genutzt. Bei mehrfach Verwendung ggf. auslagern und erweitern oder beim Zusammenführen beider Dateien entfernen.
export interface PaginationInterface {
  currentPage: number;
  qtyRows: number;
}

export const InventoryItemMenu = ({
  open,
  onAdd,
  onClose,
  onSetStockToZero,
  onEdit,
  onReset,
  onItemDetails,
  onItemImageDisplay,
  anchorEl,
  menuTask = null,
}: any) => {
  const reducedMenu = useMemo(() => AppConfig.getConfig().itemInventory?.inventoryItemMenu?.reducedMenu, []);
  const disallowQuantityInput = useMemo(() => AppConfig.getConfig().itemInventory?.scanMode?.disallowQuantityInput, []);

  const menuList = useMemo(() => {
    const listItems = [];
    if (!reducedMenu) {
      listItems.push(
        <MenuItem onClick={onSetStockToZero}>
          <ListItemIcon>
            <ExposureZeroIcon fontSize="small" />
          </ListItemIcon>
          Kein Bestand
        </MenuItem>
      );

      if (!disallowQuantityInput) {
        listItems.push(
          <MenuItem onClick={onAdd}>
            <ListItemIcon>
              <AddCircleIcon fontSize="small" />
            </ListItemIcon>
            Hinzufügen
          </MenuItem>
        );
      }
    }
    listItems.push(
      ...[
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Bearbeiten
        </MenuItem>,
        <MenuItem onClick={onReset}>
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          Zurücksetzen
        </MenuItem>,
      ]
    );

    if (!reducedMenu) {
      listItems.push(
        <MenuItem onClick={onItemDetails}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          Artikelinfo
        </MenuItem>
      );

      if (menuTask?.item?.media?.imagePath) {
        listItems.push(
          <MenuItem onClick={onItemImageDisplay}>
            <ListItemIcon>
              <ImageIcon fontSize="small" />
            </ListItemIcon>
            Bild anzeigen
          </MenuItem>
        );
      }
    }

    return listItems;
  }, [reducedMenu, menuTask, onSetStockToZero, onItemDetails, onItemImageDisplay, onAdd, onEdit, onReset]);

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {...menuList}
    </Menu>
  );
};

const ItemInventoryTaskList = (props: ItemInventoryTaskListConnectedProps) => {
  const [showBulkUpdateConfirmation, setShowBulkUpdateConfirmation] = React.useState(false);
  const [isItemMenuOpen, setItemMenuOpen] = React.useState(false);
  const [isItemImageDialogOpen, setIsItemImageDialogOpen] = React.useState<boolean>(false);
  const [menuTask, setMenuTask] = React.useState<ItemInventoryLocalTask | undefined>(undefined);
  const [menuAnchor, setMenuAnchor] = React.useState<EventTarget | undefined>(undefined);
  const [taskFormItem, setTaskFormItem] = React.useState<ItemInventoryLocalTask | undefined>(undefined);
  const [previousTaskFormItem, setPreviousTaskFormItem] = React.useState<ItemInventoryLocalTask | null>(null);
  const [taskFormMode, setTaskFormMode] = React.useState<ItemInventoryStockUpdateMode>('add');
  const [taskProgress, setTaskProgress] = React.useState(0);
  const [tasksWithCountings, setTasksWithCountings] = React.useState<ItemInventoryLocalTask[]>([]);
  const [tasksWithoutCountings, setTasksWithoutCountings] = React.useState<ItemInventoryLocalTask[]>([]);
  const [isBackgroundProcessActive, setBackgroundProcessActive] = React.useState(false);

  const [paginationStateForTasksWithoutCounting, setPaginationStateForTasksWithoutCounting] =
    React.useState<PaginationInterface>({ currentPage: 0, qtyRows: 100 });
  const [paginationStateForTasksWithCounting, setPaginationStateForTasksWithCounting] =
    React.useState<PaginationInterface>({ currentPage: 0, qtyRows: 100 });

  const { bulkSetZeroAllowed, classes, tasks, setSnack, inventoryLocation } = props;

  const confirmBulkUpdate = useCallback(() => {
    setShowBulkUpdateConfirmation(true);
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    quantityChangeLimit = {},
    checkPrevQtyConfirmed = false,
    sortTasksListByLastScan = false,
    sortTasksListWithoutCountingsByItemNo = false,
  } = AppConfig.getConfig().physicalCount ?? {};
  const { lowerLimit = null, upperLimit = null } = quantityChangeLimit;

  React.useEffect(() => {
    const tempTasksWithCountings: ItemInventoryLocalTask[] = [];
    const tempTasksWithoutCountings: ItemInventoryLocalTask[] = [];
    if (!tasks) {
      return;
    }
    tasks.forEach((task) => {
      const stockTakesInThisLocation = task.countings.filter((stockTake) => stockTake[0] === inventoryLocation[0]);
      if (stockTakesInThisLocation.length === 0) {
        tempTasksWithoutCountings.push(task);
      } else {
        tempTasksWithCountings.push(task);
      }
    });

    if (sortTasksListByLastScan) {
      tempTasksWithCountings.sort((a, b) => b.countings?.[0]?.[1]?.timestamp - a.countings?.[0]?.[1]?.timestamp);
    }

    if (sortTasksListWithoutCountingsByItemNo) {
      tempTasksWithoutCountings.sort((a, b) =>
        (a.item.customItemNo || a.item.itemNo).localeCompare(b.item.customItemNo || b.item.itemNo)
      );
    }

    const max = tempTasksWithCountings.length + tempTasksWithoutCountings.length;
    setTaskProgress((max != 0 ? tempTasksWithCountings.length / max : 0) * 100);
    setTasksWithCountings(tempTasksWithCountings);
    setTasksWithoutCountings(tempTasksWithoutCountings);
  }, [inventoryLocation, tasks]);

  const closeItemMenu = useCallback(() => {
    setItemMenuOpen(false);
    setMenuTask(undefined);
    setMenuAnchor(undefined);
  }, [setItemMenuOpen, setMenuTask, setMenuAnchor]);

  const editTask = useCallback(
    (event: any, task: ItemInventoryLocalTask | undefined, countMode: 'replace' | 'add') => {
      closeItemMenu();
      setTaskFormItem(task || menuTask);
      setTaskFormMode(countMode);
    },
    [closeItemMenu, setTaskFormItem, setTaskFormMode, menuTask]
  );

  const closeTaskForm = useCallback(() => {
    setTaskFormItem(undefined);
  }, [setTaskFormItem]);

  const modifyItemStock = useCallback(
    (task: ItemInventoryLocalTask, mode: ItemInventoryStockUpdateMode, value: number) => {
      dispatch(
        addStockTake(mode, {
          amount: value,
          itemId: task.itemId,
          timestamp: Date.now(),
          location: inventoryLocation[0],
          reason: task.reason,
          status: StockTakeStatus.DRAFT,
        })
      );

      // Can be null as it is not use anymore if stock was modified
      setPreviousTaskFormItem(null);
    },
    [inventoryLocation, setSnack]
  );

  const bulkSetZero = useCallback(async () => {
    setShowBulkUpdateConfirmation(false);

    dispatch(
      addStockTake(
        'replace',
        tasksWithoutCountings.map((task) => {
          return {
            amount: 0,
            itemId: task.itemId,
            timestamp: Date.now(),
            location: inventoryLocation[0],
            reason: task.reason,
            status: StockTakeStatus.DRAFT,
          };
        })
      )
    );

    // Can be null as it is not use anymore if stock was modified
    setPreviousTaskFormItem(null);
  }, [inventoryLocation, setSnack, tasksWithoutCountings]);

  const resetStockTake = useCallback(() => {
    if (!menuTask) {
      return;
    }

    closeItemMenu();
    modifyItemStock(menuTask, 'reset', 0);
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const setStockToZero = useCallback(() => {
    if (!menuTask) {
      return;
    }

    modifyItemStock(menuTask, 'replace', 0);
    closeItemMenu();
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const updateTaskCounting = useCallback(
    (value?: number) => {
      if (value === undefined || !taskFormItem) {
        closeTaskForm();

        window.setTimeout(() => {
          setSnack({
            severity: 'info',
            autoHideDuration: 3000,
            text: 'Es wurde kein Angabe erfasst',
          });
        }, 1);
        return;
      }

      modifyItemStock(taskFormItem, taskFormMode, value);
      closeTaskForm();
    },
    [closeTaskForm, modifyItemStock, taskFormItem, taskFormMode, setSnack]
  );

  const openItemMenu = useCallback(
    (target: EventTarget, task: ItemInventoryLocalTask) => {
      setItemMenuOpen(true);
      setMenuTask(task);
      setMenuAnchor(target);
    },
    [setItemMenuOpen, setMenuTask, setMenuAnchor]
  );

  const onScan = useCallback(
    (code: string) => {
      const codes = codeToSearchList(code);

      // check if code is contained in the current task list
      const task = tasks.find(
        (task) =>
          codes.includes(task.item.itemNo) ||
          (task.item.customItemNo && codes.includes(task.item.customItemNo)) ||
          (task.item.scanCodes && task.item.scanCodes.filter((code) => codes.includes(code)).length > 0)
      );

      if (!task) {
        playErrorSound('item');
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: `Der Artikel mit dem Barcode "${code}" steht nicht auf dieser Inventurliste.`,
        });

        return;
      } else {
        playSuccessSound('item');
      }

      if (previousTaskFormItem?.itemId && previousTaskFormItem.itemId !== task.itemId) {
        playErrorSound('item');
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: `Bitte bestätige die Menge des vorherigen Artikels (${previousTaskFormItem.item.itemNo})`,
        });

        return;
      }

      checkPrevQtyConfirmed && setPreviousTaskFormItem(task);

      if (AppConfig.getConfig()?.itemInventory?.scanMode?.ifItemExistsCountUp) {
        let amount = 0;
        for (const counting of task.countings) {
          if (counting[0] === inventoryLocation[0]) {
            amount = counting[1].amount;
          }
        }

        modifyItemStock(task, 'replace', amount + 1);
      } else {
        editTask(null, task, 'add');
      }

      playSuccessSound('item');
    },
    [editTask, tasks, setSnack, previousTaskFormItem]
  );

  const openItemDetails = useCallback(() => {
    if (!menuTask) {
      closeItemMenu();
      return;
    }
    const posIdentity = menuTask.item.scanCodes.slice(0, 1);

    if (!posIdentity) {
      closeItemMenu();
      return;
    }

    history.push(`/itemInventory/item/details/byCode/${posIdentity}`);

    closeItemMenu();
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const openItemImageDialog = useCallback(() => {
    if (!menuTask) {
      closeItemMenu();
      return;
    }

    setIsItemImageDialogOpen(true);
  }, [menuTask, setIsItemImageDialogOpen, closeItemMenu]);

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <Typography variant="h5" component="h2" className={classes.title}>
            {inventoryLocation[1]}
          </Typography>
          <Box className={classes.progress}>
            <ScanInput scanEvent={onScan} />
          </Box>
          <Box className={classes.progress}>
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={taskProgress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(taskProgress)}%`}</Typography>
            </Box>
          </Box>

          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Ungezählte Artikel</Typography>
              <Typography className={classes.secondaryHeading}>{tasksWithoutCountings.length} Artikel</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {bulkSetZeroAllowed && tasksWithoutCountings.length > 0 && (
                <Box ml={2} mr={2}>
                  <Button variant="contained" color="primary" fullWidth onClick={confirmBulkUpdate}>
                    Übrige Artikel haben keinen Bestand
                  </Button>
                </Box>
              )}
              {Boolean(tasksWithoutCountings.length) && (
                <TablePagination
                  component="div"
                  labelRowsPerPage="Zeilen:"
                  count={tasksWithoutCountings.length}
                  page={paginationStateForTasksWithoutCounting.currentPage}
                  rowsPerPage={paginationStateForTasksWithoutCounting.qtyRows}
                  onPageChange={(_, value) =>
                    setPaginationStateForTasksWithoutCounting((prevState) => ({
                      ...prevState,
                      currentPage: value,
                    }))
                  }
                  onRowsPerPageChange={(event) =>
                    setPaginationStateForTasksWithoutCounting((prevState) => ({
                      ...prevState,
                      qtyRows: Number(event.target.value) || 100,
                    }))
                  }
                />
              )}
              <List component="nav" className={classes.taskList}>
                {tasksWithoutCountings
                  .slice(
                    paginationStateForTasksWithoutCounting.currentPage * paginationStateForTasksWithoutCounting.qtyRows,
                    paginationStateForTasksWithoutCounting.currentPage *
                      paginationStateForTasksWithoutCounting.qtyRows +
                      paginationStateForTasksWithoutCounting.qtyRows
                  )
                  .map((task, i) => (
                    <React.Fragment key={i}>
                      <ItemInventoryTaskItem
                        inventoryLocation={inventoryLocation[0]}
                        task={task}
                        openMenu={openItemMenu}
                        onClick={openItemMenu}
                      />
                      {i < tasksWithoutCountings.length - 1 && <Divider className={classes.divider} component="li" />}
                    </React.Fragment>
                  ))}
              </List>
              <TablePagination
                component="div"
                labelRowsPerPage="Zeilen:"
                count={tasksWithoutCountings.length}
                page={paginationStateForTasksWithoutCounting.currentPage}
                rowsPerPage={paginationStateForTasksWithoutCounting.qtyRows}
                onPageChange={(_, value) =>
                  setPaginationStateForTasksWithoutCounting((prevState) => ({
                    ...prevState,
                    currentPage: value,
                  }))
                }
                onRowsPerPageChange={(event) =>
                  setPaginationStateForTasksWithoutCounting((prevState) => ({
                    ...prevState,
                    qtyRows: Number(event.target.value) || 100,
                  }))
                }
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Bereits gezählt</Typography>
              <Typography className={classes.secondaryHeading}>{tasksWithCountings.length} Artikel</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {Boolean(tasksWithCountings.length) && (
                <TablePagination
                  component="div"
                  labelRowsPerPage="Zeilen:"
                  count={tasksWithCountings.length}
                  page={paginationStateForTasksWithCounting.currentPage}
                  rowsPerPage={paginationStateForTasksWithCounting.qtyRows}
                  onPageChange={(_, value) =>
                    setPaginationStateForTasksWithCounting((prevState) => ({
                      ...prevState,
                      currentPage: value,
                    }))
                  }
                  onRowsPerPageChange={(event) =>
                    setPaginationStateForTasksWithCounting((prevState) => ({
                      ...prevState,
                      qtyRows: Number(event.target.value) || 100,
                    }))
                  }
                />
              )}
              <List component="nav" className={classes.taskList}>
                {tasksWithCountings
                  .slice(
                    paginationStateForTasksWithCounting.currentPage * paginationStateForTasksWithCounting.qtyRows,
                    paginationStateForTasksWithCounting.currentPage * paginationStateForTasksWithCounting.qtyRows +
                      paginationStateForTasksWithCounting.qtyRows
                  )
                  .map((task, i) => (
                    <React.Fragment key={i}>
                      <ItemInventoryTaskItem
                        inventoryLocation={inventoryLocation[0]}
                        task={task}
                        openMenu={openItemMenu}
                        onClick={openItemMenu}
                      />
                      {i < tasksWithCountings.length - 1 && <Divider className={classes.divider} component="li" />}
                    </React.Fragment>
                  ))}
              </List>
              <TablePagination
                component="div"
                labelRowsPerPage="Zeilen:"
                count={tasksWithCountings.length}
                page={paginationStateForTasksWithCounting.currentPage}
                rowsPerPage={paginationStateForTasksWithCounting.qtyRows}
                onPageChange={(_, value) =>
                  setPaginationStateForTasksWithCounting((prevState) => ({
                    ...prevState,
                    currentPage: value,
                  }))
                }
                onRowsPerPageChange={(event) =>
                  setPaginationStateForTasksWithCounting((prevState) => ({
                    ...prevState,
                    qtyRows: Number(event.target.value) || 100,
                  }))
                }
              />
            </AccordionDetails>
          </Accordion>

          <InventoryItemMenu
            open={isItemMenuOpen}
            anchorEl={menuAnchor}
            onClose={closeItemMenu}
            onAdd={() => editTask(null, undefined, 'add')}
            onEdit={() => editTask(null, undefined, 'replace')}
            onReset={resetStockTake}
            onSetStockToZero={setStockToZero}
            onItemDetails={openItemDetails}
            onItemImageDisplay={openItemImageDialog}
            menuTask={menuTask}
          />

          {taskFormItem !== undefined && (
            <ItemInventoryTaskForm
              mode={taskFormMode}
              task={taskFormItem}
              inventoryLocation={inventoryLocation}
              onClose={closeTaskForm}
              onSave={updateTaskCounting}
              {...(lowerLimit && { lowerStockLimit: lowerLimit })}
              {...(upperLimit && { upperStockLimit: upperLimit })}
            />
          )}

          <Dialog open={showBulkUpdateConfirmation}>
            <DialogTitle>{'Prüfung'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Bist du sicher, dass die verbleibenden <strong>{tasksWithoutCountings.length} Artikel</strong> mit einem{' '}
                <strong>Bestand von 0</strong> gebucht werden sollen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowBulkUpdateConfirmation(false)} color="primary">
                Abbrechen
              </Button>
              <Button onClick={bulkSetZero} color="primary" autoFocus>
                Ja, fortfahren
              </Button>
            </DialogActions>
          </Dialog>
          {menuTask?.item && (
            <ItemImageDialog
              open={isItemImageDialogOpen}
              onClose={() => {
                setIsItemImageDialogOpen(false);
                closeItemMenu();
              }}
              item={menuTask.item}
            />
          )}
        </Paper>
      </Box>

      <Box mt={2} mb={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={props.onListComplete}
          disabled={tasksWithCountings.length === 0}
        >
          {inventoryLocation[1]} abschließen
        </Button>
      </Box>

      <Modal
        disableBackdropClick
        disableEnforceFocus
        disableAutoFocus
        className={classes.loadingModal}
        open={isBackgroundProcessActive}
        onClose={() => setBackgroundProcessActive(false)}
      >
        <CircularProgress className={classes.loadingSpinner} />
      </Modal>
    </Container>
  );
};

export default connect(null, {
  setSnack,
})(withRouter(withStyles(styles)(ItemInventoryTaskList))) as React.ComponentType<ItemInventoryTaskListProps>;
