import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import {
  RequestTransferDocumentLineItemInterface,
  RequestTransferDocumentLineItemTask,
} from '@meeva/service-client-core/src/interfaces/RequestTransferDocumentInterface';
import classNames from 'classnames';
import React from 'react';
import { rowStyles, TaskRowInterface } from '../../freeStockTransfer/components/StockTransferItemRow';
import { RequestTransferTaskResult } from './RequestStockTransferForm';

export interface ContextMenuInterface {
  anchorEl: EventTarget;
  selectedTask?: TaskRowInterface;
  documentId: string;
  lineItem?: RequestTransferDocumentLineItemInterface;
  taskType: 'TransferTask' | 'ItemTask' | 'ContainerSealTask' | null;
  containerCode?: string;
}

const useRowStyles = makeStyles({
  ...rowStyles,
  taskRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  taskCell: {
    paddingLeft: 36,
    fontWeight: 900,
  },
  errorCell: {
    paddingLeft: 36,
    fontWeight: 900,
  },
  taskItemCell: {
    '&:last-child': {
      paddingRight: 36,
    },
  },
  declined: {
    textDecoration: 'line-through',
  },
});
interface Props {
  document: RequestTransferTaskResult;
  lineItem: RequestTransferDocumentLineItemTask;
  declined: boolean;
  setContextMenu: (contextMenu: ContextMenuInterface | null) => void;
}

const RequestStockTransferItemRow = ({ document, lineItem, setContextMenu, declined }: Props) => {
  const classes: any = useRowStyles();

  return (
    <>
      <TableRow
        className={classNames(classes.itemRow, classes['statusCode' + document.status])}
        data-cy="stock-transfer-form-item-table-row"
        onClick={(event) =>
          setContextMenu({
            anchorEl: event.target,
            documentId: document.id,
            lineItem,
            taskType: 'TransferTask',
          })
        }
      >
        <TableCell className={classes.itemCell} size="small">
          {lineItem.item.customItemNo || lineItem.item.itemNo}
        </TableCell>
        <TableCell
          className={classNames(classes.itemCell, {
            [classes.declined]: declined,
          })}
          align="right"
          data-cy="stock-transfer-form-item-table-cell-qty"
        >
          {`${lineItem.scannedQuantity} / ${lineItem.unitCount}`}
        </TableCell>
      </TableRow>
      <TableRow
        className={classNames(classes.itemRow, classes['statusCode' + document.status])}
        onClick={(event) =>
          setContextMenu({
            anchorEl: event.target,
            documentId: document.id,
            lineItem,
            taskType: 'TransferTask',
          })
        }
      >
        <TableCell className={classes.itemCell} colSpan={2}>
          {lineItem.item.name}
        </TableCell>
      </TableRow>
      {document.individualTasks
        ?.filter((task) => task.item?.id === lineItem.item.id)
        .map((task, i) => {
          return (
            <React.Fragment key={i}>
              <TableRow
                className={classNames(classes['statusCode' + task.status], classes.taskRow)}
                onClick={(event) =>
                  setContextMenu({
                    anchorEl: event.target,
                    selectedTask: task,
                    documentId: document.id,
                    lineItem: {
                      ...lineItem,
                      scannedQuantity: task.scannedQuantity,
                    },
                    containerCode: task.containerCode,
                    taskType: 'ItemTask',
                  })
                }
              >
                <TableCell className={classes.taskCell}>{task.containerCode}</TableCell>
                <TableCell className={classNames(classes.itemCell, classes.taskItemCell)} align="right">
                  {task.scannedQuantity}
                </TableCell>
              </TableRow>
              {task.error && (
                <TableRow
                  className={classNames(classes.taskRow, classes['statusCode' + task.status])}
                  data-cy="stock-transfer-form-item-table-row-error"
                >
                  <TableCell className={classes.errorCell} colSpan={2}>
                    {task.error}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};
export default RequestStockTransferItemRow;
