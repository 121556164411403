import { IconButton, InputAdornment, TextField, makeStyles, Theme } from '@material-ui/core';
import { Backspace as BackspaceIcon } from '@material-ui/icons';
import { useCallback, useRef, useState } from 'react';
import Numpad, { NumpadMobileKeyboardLayout } from './Numpad';

interface NumpadInputProps {
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  value: string;
  autoFocus?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  stockInput: {
    marginTop: theme.spacing(1),
    '& input': {
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 900,
      color: '#000',
      padding: '14px 0 14px 60px',

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
    '& fieldset': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  numPad: {
    marginTop: 'auto',
    border: '1px solid #aeaeae',
    borderTop: 0,
  },
}));

const NumpadInput = ({ onChange, onSubmit, value, autoFocus }: NumpadInputProps) => {
  const classes = useStyles();
  const [numPadFirstSet, setNumPadFirstSet] = useState(true);
  const input = useRef<HTMLInputElement>(null);
  const onNumpadInput = useCallback(
    (code: string) => {
      if (code === 'backspace' || code === 'Backspace') {
        if (value.length > 0) {
          onChange(value.slice(0, -1));
        }
      } else if (code === 'return' || code === 'Enter') {
        onSubmit(value);
      } else if (!isNaN(Number(code))) {
        if (numPadFirstSet) {
          onChange(Number(code).toString());
        } else {
          onChange(Number(value + code).toString());
        }
        setNumPadFirstSet(false);
      }
      input.current?.focus();
    },
    [value, onChange, onsubmit, input]
  );
  const displayValue = value !== '' ? Number(value).toString() : '';

  return (
    <>
      <TextField
        fullWidth
        autoFocus={autoFocus}
        variant="outlined"
        value={displayValue}
        onChange={() => {
          /* ignore react warning */
        }}
        onKeyDown={(e) => onNumpadInput(e.key)}
        className={classes.stockInput}
        inputRef={input}
        inputProps={{
          inputMode: 'none',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="backspace" onClick={() => onNumpadInput('backspace')}>
                <BackspaceIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Numpad keys={NumpadMobileKeyboardLayout} onClick={onNumpadInput} className={classes.numPad} />
    </>
  );
};

export default NumpadInput;
