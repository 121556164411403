import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ArrowBack, FindInPage } from '@material-ui/icons';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MenuIcon from '@material-ui/icons/Menu';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setNavigationUpwardTarget, setOnlineStatus, setSnack } from '../../../redux/interface/actions';
import {
  getOnlineStatus,
  getSyncStatus,
  getTitle,
  getUpwardNavigationTarget,
} from '../../../redux/interface/selectors';
import { logout } from '../../../redux/security/operations';
import { getIsAuthenticated } from '../../../redux/security/selectors';
import { AppMode } from '../../../redux/appBehavior/appModeSlice';
import AppConfig from '../config';
import App from '../index';
import { STATUS_MAPPING } from '../sync/SyncOverview';
import ConfirmDialog from '../../common/components/ConfirmDialog';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    environmentTitle: {
      flexGrow: 1,
      fontSize: '1em',
      fontWeight: 900,
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

interface HeaderProps extends WithStyles<typeof styles> {
  onDrawerToggle: () => void;
  logout: () => void;
  title?: string;
  onlineStatus?: boolean;
  isAuthenticated: boolean;
}

const getOnLineStatus = () => (typeof navigator !== 'undefined' ? navigator.onLine : true);

const OnlineStatusTracker = connect(null, { setOnlineStatus })(({ setOnlineStatus }: any) => {
  const updateStatus = () => setOnlineStatus(getOnLineStatus());

  React.useEffect(() => {
    updateStatus();
    window.addEventListener('online', updateStatus, { passive: true, capture: false });
    window.addEventListener('offline', updateStatus, { passive: true, capture: false });

    return () => {
      window.removeEventListener('online', updateStatus, { capture: false });
      window.removeEventListener('offline', updateStatus, { capture: false });
    };
  });

  return null;
});

const Header = (props: HeaderProps) => {
  const { classes, logout, onDrawerToggle, title, onlineStatus, isAuthenticated } = props;
  const isStagingEnvironment = 'development' === process.env.REACT_APP_ENVIRONMENT;
  const dispatch = useDispatch();
  const history = useHistory();
  const [blockingProcesses, setBlockingProcesses] = useState<string[]>([]);
  const [blockingText, setBlockingText] = useState<string>('');
  const upwardNavigation = useSelector((state) => getUpwardNavigationTarget(state));
  const syncStatus = useSelector((state) => getSyncStatus(state));
  //TODO: Implement RootState (@meeva/service-client-core/redux/store) with store.getState types
  const appModeState = useSelector((state: any) => state.app.mode);
  const isMissingItemAnalysisMode = AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState;

  useEffect(() => {
    if (isMissingItemAnalysisMode) {
      dispatch(
        setSnack({
          severity: 'success',
          autoHideDuration: 10000,
          text: 'Fehlartikelanalyse gestartet, um zu beenden schließe die Analyse ab',
          positionTop: 52,
        })
      );
    }
  }, [isMissingItemAnalysisMode]);

  const navigateBack = useCallback(() => {
    if (upwardNavigation) {
      dispatch(setNavigationUpwardTarget(null));
      history.push(upwardNavigation);
    }
  }, [dispatch, history, upwardNavigation]);

  const handleLogout = async () => {
    const processesToCheck = AppConfig.getConfigOption('logout.syncProcessesThatBlockedLogout') || [];
    const checkPreLogoutEvent = AppConfig.getConfigOption('logout.checkPreLogoutEvent') || false;

    if (!processesToCheck.length && !checkPreLogoutEvent) {
      logout();
    }

    let newBlockingText = '';

    if (checkPreLogoutEvent) {
      const preLogoutEventResult = (await App.dispatchEvent('pre_logout')) || [];
      const preLogoutDialogData = [];
      for (const result of preLogoutEventResult) {
        if (!result.allowed) {
          preLogoutDialogData.push(result.reason);
        }
      }
      if (preLogoutDialogData.length) {
        newBlockingText += preLogoutDialogData.join(' ');
      }
    }

    setBlockingText(newBlockingText);

    const foundBlockingProcesses = [];

    for (const processToCheck of processesToCheck) {
      for (const statusElem of syncStatus) {
        if (statusElem.name === processToCheck && ['active', 'initializing'].includes(statusElem.status)) {
          foundBlockingProcesses.push(STATUS_MAPPING[processToCheck]);
        }
      }
    }

    setBlockingProcesses(foundBlockingProcesses);

    if (!newBlockingText.length && !foundBlockingProcesses.length) {
      logout();
    }
  };

  const handleCloseBlockingText = () => {
    setBlockingText('');
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        open={!!blockingText.length}
        title="Hinweis zur Abmeldung"
        contentText={blockingText}
        confirmText="Zurück"
        handleConfirm={handleCloseBlockingText}
        handleClose={handleCloseBlockingText}
      />
      <ConfirmDialog
        open={!!blockingProcesses.length && !blockingText.length}
        title="Übertragung noch nicht abgeschlossen"
        contentText={
          'Es wurden noch nicht alle Datensätze übertragen. Zur Zeit besteht keine Verbindung zum Server. Folgende Übermittlungen stehen noch aus: ' +
          blockingProcesses.join(', ') +
          ' Sobald wieder eine Verbindung besteht, werden die Datensätze automatisch übertragen.'
        }
        confirmText="Warten auf Übertragung"
        cancelText="Sofort abmelden"
        handleClose={() => {
          setBlockingProcesses([]);
          logout();
        }}
        handleConfirm={() => {
          setBlockingProcesses([]);
          history.push('/sync');
        }}
      />

      <OnlineStatusTracker />
      <AppBar
        color="primary"
        position="sticky"
        elevation={0}
        style={isMissingItemAnalysisMode ? { backgroundColor: '#0B4D0B' } : {}}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              {upwardNavigation && (
                <IconButton
                  color="inherit"
                  data-cy="menuBurger"
                  aria-label="open drawer"
                  onClick={navigateBack}
                  className={classes.menuButton}
                >
                  <ArrowBack />
                </IconButton>
              )}

              <Hidden smUp>
                {!upwardNavigation && (
                  <IconButton
                    color="inherit"
                    data-cy="menuBurger"
                    aria-label="open drawer"
                    onClick={onDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Hidden>
            </Grid>
            {title && (
              <Typography variant="h6" className={classes.title} data-cy="appBarTitle">
                {title}
              </Typography>
            )}
            {isStagingEnvironment && (
              <Typography variant="h6" className={classes.environmentTitle} data-cy="appBarEnvironmentTitle">
                - STAGING -
              </Typography>
            )}
            {!onlineStatus && (
              <Grid item data-cy="appBarConnectivityIndicator">
                <IconButton color="inherit">
                  <WarningRoundedIcon />
                </IconButton>
              </Grid>
            )}
            {isAuthenticated && (
              <Grid item style={{ marginLeft: 'auto' }} data-cy="appBarLogout">
                {isMissingItemAnalysisMode ? (
                  <IconButton color="inherit" onClick={() => history.push('/missing-item-analysis')}>
                    <FindInPage />
                  </IconButton>
                ) : (
                  <IconButton color="inherit" onClick={handleLogout}>
                    <MeetingRoomIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    title: getTitle(state),
    onlineStatus: getOnlineStatus(state),
    isAuthenticated: getIsAuthenticated(state),
  }),
  {
    logout,
  }
)(withStyles(styles)(Header));
