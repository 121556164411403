import {
  Container,
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormHelperText,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  setNavigationUpwardTarget,
  setSnack,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { debounce } from 'lodash';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  DistributionPackagingSlipDistributionTaskDistributionInterface,
  DistributionPackagingSlipDistributionTaskInterface,
} from '@meeva/service-client-core/interfaces/DistributionPackagingSlipInterface';
import useLongPress from '@meeva/service-client-core/modules/common/hooks/useLongPress';
import ScanInput from '../../common/ScanInput';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import { updateTaskDistributedQuantity, createOrUpdateCountTask, getTaskById } from '../utils/packagingSlipHelper';
import { findItemByCode } from '@meeva/service-client-core/utils/items';
import { playSuccessSound, playErrorSound } from '@meeva/service-client-core/utils/audioHelper';

export interface CustomRouterProps {
  packagingSlipIndicator: string | undefined;
  taskId: string | undefined;
}

interface PackagingSlipStoreDistributionTaskViewProps extends RouteComponentProps<CustomRouterProps>, WithStyles {}

interface PackagingSlipStoreDistributionItemTableRowProps {
  distribution: DistributionPackagingSlipDistributionTaskDistributionInterface;
  isChecked: boolean;
  onClick: () => void;
  onLongPress: (event: any) => void;
  displayCheckBox?: boolean;
}

interface PackagingSlipStoreDistributionItemMenuProps {
  open: boolean;
  onReduction: () => void;
  onClose: () => void;
  anchorEl: any;
}

const styles = () =>
  createStyles({
    paper: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
    },
  });

const PackagingSlipStoreDistributionItemMenu = (props: PackagingSlipStoreDistributionItemMenuProps) => {
  const { open, onClose, anchorEl, onReduction } = props;

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      <MenuItem onClick={onReduction}>
        <ListItemIcon>
          <ExposureNeg1Icon fontSize="small" />
        </ListItemIcon>
        Artikelbestand reduzieren
      </MenuItem>
    </Menu>
  );
};

const PackagingSlipStoreDistributionItemTableRow = (props: PackagingSlipStoreDistributionItemTableRowProps) => {
  const { distribution, isChecked, onClick, onLongPress, displayCheckBox = false } = props;
  const longPressEvent = useLongPress(onLongPress, onClick, { delay: 500 });

  return (
    <TableRow {...longPressEvent}>
      {displayCheckBox && (
        <TableCell padding="none">
          <Checkbox color="primary" checked={isChecked} {...longPressEvent} />
        </TableCell>
      )}
      <TableCell padding="none">{distribution.name}</TableCell>
      <TableCell align="right">{distribution.distributedUnitCount}</TableCell>
      <TableCell align="right">{distribution.actualDistributedUnitCount}</TableCell>
    </TableRow>
  );
};

const PackagingSlipStoreDistributionTaskView = ({ classes, match }: PackagingSlipStoreDistributionTaskViewProps) => {
  const [scanValue, setScanValue] = useState<string>('');
  const [containerCode, setContainerCode] = useState<string>('');
  const [taskList, setTaskList] = useState<DistributionPackagingSlipDistributionTaskInterface>();
  const [selectedTaskEntry, setSelectedTaskEntry] =
    useState<DistributionPackagingSlipDistributionTaskDistributionInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contextMenu, setContextMenu] = useState<{
    anchorEl: EventTarget | null;
    selectedDistribution: DistributionPackagingSlipDistributionTaskDistributionInterface | null;
  }>({
    anchorEl: null,
    selectedDistribution: null,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { packagingSlipIndicator, taskId } = match.params;

    dispatch(setTitle(`Filialverteilung`));
    dispatch(setNavigationUpwardTarget(`/packagingSlip/${packagingSlipIndicator}`));

    if (!taskId) {
      return history.push('/packagingSlip');
    }

    dispatch(showGlobalProgressModal(true));
    prepareTaskList(taskId);
    dispatch(showGlobalProgressModal(false));
  }, [match]);

  const notifyServiceWorker = useCallback(
    debounce(
      async () => {
        await dispatch(triggerBackgroundSync('distributionPackagingSlipQueueTasks', true));
      },
      10000,
      { maxWait: 30000 }
    ),
    []
  );

  useEffect(() => {
    notifyServiceWorker();
  }, [taskList]);

  const prepareTaskList = useCallback(
    async (newTaskId) => {
      if (isLoading) {
        return;
      }

      const distributionTask = await getTaskById(newTaskId);

      if (!distributionTask?.distribution.length) {
        dispatch(
          setSnack({
            severity: 'warning',
            autoHideDuration: 3000,
            text: 'Die Lieferung enthält keine Artikel oder Filialen',
          })
        );

        return history.push('/packagingSlip');
      }

      setTaskList({
        ...distributionTask,
        distribution: distributionTask.distribution.sort((unitA, unitB) => {
          if (unitA.number && unitB.number) {
            return unitA.number - unitB.number;
          }

          return unitA.name.localeCompare(unitB.name);
        }),
      });

      setSelectedTaskEntry(distributionTask.distribution[0]);
    },
    [taskList, selectedTaskEntry]
  );

  const handleScan = async () => {
    const isContainerCode = scanValue.match(/^[Cc]/g);
    const allowAssignmentWithoutContainer =
      AppConfig.getConfig()?.packagingSlip?.storeDistribution?.allowEmptyContainer || false;
    let selectedItemNumber = scanValue;

    if (isContainerCode) {
      return setContainerCode(scanValue);
    }

    if (!selectedTaskEntry) {
      playErrorSound('businessUnit');
      return dispatch(
        setSnack({
          severity: 'warning',
          autoHideDuration: 3000,
          text: 'Es wurde keine Filiale ausgewählt',
        })
      );
    }

    if (!allowAssignmentWithoutContainer && !containerCode) {
      playErrorSound('container');
      return dispatch(
        setSnack({
          severity: 'warning',
          autoHideDuration: 3000,
          text: 'Es muss vorher ein Container ausgewählt sein',
        })
      );
    }

    dispatch(showGlobalProgressModal(true));
    const item = await findItemByCode(scanValue);
    dispatch(showGlobalProgressModal(false));

    if (item) {
      selectedItemNumber = item.itemNo;
    }

    if (taskList!.taskType === 'Item') {
      if (selectedItemNumber !== String(taskList!.taskObject.number)) {
        playErrorSound('item');
        return dispatch(
          setSnack({
            severity: 'warning',
            autoHideDuration: 3000,
            text: 'Der angegebene Artikel stimmt nicht mit dem Artikel aus dem Lieferschein überein',
          })
        );
      }
    }

    if (taskList!.taskType === 'BusinessUnit') {
      let itemExist = false;

      for (const _distribution of taskList!.distribution) {
        if (selectedItemNumber === String(_distribution.number)) {
          itemExist = true;

          break;
        }
      }

      if (!itemExist) {
        playErrorSound('item');
        return dispatch(
          setSnack({
            severity: 'warning',
            autoHideDuration: 3000,
            text: 'Der angegebene Artikel stimmt nicht mit dem Artikel aus dem Lieferschein überein',
          })
        );
      }
    }

    playSuccessSound('item');

    await handleQuantityAndContainerChange(1, selectedTaskEntry);
  };

  const handleQuantityAndContainerChange = async (
    quantity: number,
    selectedDistributionEntry: DistributionPackagingSlipDistributionTaskDistributionInterface
  ) => {
    setIsLoading(true);
    dispatch(showGlobalProgressModal(true));

    const businessUnitId =
      'BusinessUnit' === taskList!.taskType ? taskList!.taskObject.id : selectedDistributionEntry.id;
    const itemId = 'Item' === taskList!.taskType ? taskList!.taskObject.id : selectedDistributionEntry.id;

    try {
      // Update currently grouped distribution (e.g. Item)
      await updateTaskDistributedQuantity(
        taskList!.packagingSlipId,
        taskList!.taskObject.id,
        selectedDistributionEntry.id,
        containerCode,
        quantity
      );

      // Update other grouped distribution (e.g. BusinessUnit)
      await updateTaskDistributedQuantity(
        taskList!.packagingSlipId,
        selectedDistributionEntry.id,
        taskList!.taskObject.id,
        containerCode,
        quantity
      );

      await createOrUpdateCountTask(taskList!.packagingSlipId, itemId, containerCode, businessUnitId, quantity);

      prepareTaskList(taskList!.id);
    } catch (error) {
      console.error(error);
    }

    if ('BusinessUnit' === taskList?.taskType) {
      setContainerCode('');
    }

    dispatch(showGlobalProgressModal(false));
    setIsLoading(false);
  };

  return (
    <Container>
      <Paper className={classes.paper}>
        {taskList && taskList.distribution.length ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textSecondary" gutterBottom>
                {taskList.taskObject.number} - {taskList.taskObject.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ScanInput
                onChange={(value) => setScanValue(value as string)}
                value={scanValue}
                scanEvent={handleScan}
                inputType="text"
              />
              <FormHelperText hidden={!containerCode}>Container: {containerCode}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" colSpan={'Item' === taskList?.taskType ? 2 : 1}>
                        {'BusinessUnit' === taskList.taskType ? 'Artikel' : 'Filiale'}
                      </TableCell>
                      <TableCell component="th" align="right">
                        Soll Menge
                      </TableCell>
                      <TableCell component="th" align="right">
                        Gescannte Menge
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskList.distribution.map((distributionEntry, i: number) => (
                      <PackagingSlipStoreDistributionItemTableRow
                        key={i}
                        distribution={distributionEntry}
                        isChecked={distributionEntry.id === selectedTaskEntry?.id}
                        displayCheckBox={'Item' === taskList?.taskType}
                        onClick={() => {
                          if ('Item' === taskList?.taskType) {
                            setSelectedTaskEntry(distributionEntry);
                            setContainerCode('');
                          }
                        }}
                        onLongPress={(event) =>
                          setContextMenu({
                            anchorEl: event.target,
                            selectedDistribution: distributionEntry,
                          })
                        }
                      />
                    ))}
                    <PackagingSlipStoreDistributionItemMenu
                      open={!!contextMenu.selectedDistribution}
                      onClose={() => setContextMenu({ anchorEl: null, selectedDistribution: null })}
                      anchorEl={contextMenu.anchorEl}
                      onReduction={async () => {
                        await handleQuantityAndContainerChange(-1, contextMenu.selectedDistribution!);
                        setContextMenu({ anchorEl: null, selectedDistribution: null });
                      }}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          <Typography color="textSecondary" gutterBottom>
            Wird geladen...
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(PackagingSlipStoreDistributionTaskView) as React.ComponentType;
