import { useCallback, useRef, useState } from 'react';

type Options = {
  shouldPreventDefault?: boolean;
  delay?: number;
};

const useLongPress = (onLongPress: (event?: any) => void, onClick: (event?: any) => void, options: Options) => {
  const { shouldPreventDefault = true, delay = 300 } = options;

  const [longPressTriggered, setLongPressTriggered] = useState(false);

  const timeout = useRef<any>();
  const target = useRef<any>();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefaultOnTouchEvent, { passive: false });
        target.current = event.target;
      }

      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event: React.TouchEvent | React.MouseEvent, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();

      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefaultOnTouchEvent);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (event: React.MouseEvent) => start(event),
    onTouchStart: (event: React.TouchEvent) => start(event),
    onMouseUp: (event: React.MouseEvent) => clear(event),
    onMouseLeave: (event: React.MouseEvent) => clear(event, false),
    onTouchEnd: (event: React.TouchEvent) => clear(event),
  };
};

const preventDefaultOnTouchEvent = (event: TouchEvent | MouseEvent) => {
  if (event instanceof TouchEvent && event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPress;
