import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSnack } from '../../../redux/interface/actions';
import { getSnackMessage } from '../../../redux/interface/selectors';
import { SnackInterface } from '../../../redux/interface/reducers';

const Messages = () => {
  const snackMessage: (SnackInterface & { open: boolean }) | undefined = useSelector((state) => getSnackMessage(state));
  const dispatch = useDispatch();
  const closeSnackMessage = () => dispatch(resetSnack());

  if (!snackMessage) {
    return null;
  }

  return (
    <>
      <Snackbar
        style={snackMessage.positionTop ? { top: snackMessage.positionTop + 'px' } : {}}
        onClose={closeSnackMessage}
        onClick={closeSnackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackMessage.open}
        autoHideDuration={snackMessage.autoHideDuration || null}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.text}</Alert>
      </Snackbar>
    </>
  );
};

export default Messages;
