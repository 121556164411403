import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import SyncWorker from 'worker-loader?filename=sync.js!../../webworker/SyncWorker';
import { setSyncStatus } from '../../redux/interface/actions';
import { getSyncMessageQueue } from '../../redux/interface/selectors';
import { logout } from '../../redux/security/operations';
import { getIsAuthenticated } from '../../redux/security/selectors';
import AppConfig from './config';

interface CustomWindowInterface extends Window {
  Cypress?: unknown;
}

export interface SyncWorkerWrapperProps {
  isAuthenticated: boolean;
  logout: () => void;
  setSyncStatus: typeof setSyncStatus;
  messages: any[];
}

let syncWorker: SyncWorker | undefined;
const SyncWorkerWrapper = (props: SyncWorkerWrapperProps) => {
  const { isAuthenticated, logout, setSyncStatus, messages } = props;
  const [showSyncInterrupted, setShowSyncInterrupted] = React.useState(false);

  const onProgressClose = useCallback(() => setShowSyncInterrupted(false), [setShowSyncInterrupted]);

  useEffect(() => {
    syncWorker = new Worker(new URL('../../webworker/SyncWorker', import.meta.url));

    return () => {
      if (syncWorker) {
        syncWorker.terminate();
        syncWorker = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (!syncWorker) {
      return;
    }

    let message: any;
    // eslint-disable-next-line
    while ((message = messages.shift()) !== undefined) {
      syncWorker.postMessage(message);
    }
  }, [messages]);

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === 'security/logout') {
        logout();
      } else if (event.data.type === 'sync/start') {
        setSyncStatus({
          name: event.data.syncType,
          progress: 0,
          status: 'initializing',
          additionalInfo: event.data.additionalInfo,
        });
      } else if (event.data.type === 'sync/interrupted') {
        setShowSyncInterrupted(true);
        setSyncStatus({
          name: event.data.syncType,
          progress: 1,
          status: 'error',
          additionalInfo: event.data.additionalInfo,
        });
      } else if (event.data.type === 'sync/progress') {
        setSyncStatus({
          name: event.data.syncType,
          progress: event.data.progress,
          estimation: event.data.estimation,
          status: 'active',
          lastRun: event.data.lastRun,
          lastUpdate: event.data.lastUpdate,
          additionalInfo: event.data.additionalInfo,
        });
        setShowSyncInterrupted(false);
      } else if (event.data.type === 'sync/complete') {
        setSyncStatus({
          name: event.data.syncType,
          progress: 1,
          status: 'complete',
          lastRun: event.data.lastRun,
          lastUpdate: event.data.lastUpdate,
          additionalInfo: event.data.additionalData,
        });
      }
    },
    [logout, setShowSyncInterrupted, setSyncStatus]
  );

  useEffect(() => {
    const isCypressWindow = !!(window as CustomWindowInterface).Cypress;

    if (!isCypressWindow && syncWorker) {
      syncWorker.addEventListener('message', messageHandler);
    }

    return () => {
      syncWorker && syncWorker.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  useEffect(() => {
    if (!syncWorker) {
      return;
    }

    syncWorker.postMessage({
      msg: 'appConfig',
      config: {
        serviceWorker: AppConfig.getServiceWorker(),
        ...AppConfig.getConfig(),
      },
    });

    if (isAuthenticated) {
      syncWorker.postMessage('init');
    } else {
      syncWorker.postMessage('abort');
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Snackbar
        open={showSyncInterrupted}
        onClose={onProgressClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="warning">Die Synchronisation wurde unterbrochen</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: getIsAuthenticated(state),
    messages: getSyncMessageQueue(state),
  }),
  {
    logout,
    setSyncStatus,
  }
)(SyncWorkerWrapper);
