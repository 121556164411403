import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BusinessUnitInterface } from '@meeva/service-client-core/src/interfaces/BusinessUnitInterface';
import { useDispatch } from 'react-redux';
import { showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';

interface Props {
  onChange: (value: BusinessUnitInterface | null) => void;
  value: BusinessUnitInterface | null;
  errorMessage?: string;
}

const StockTransferBusinessUnitSelect = ({ onChange, value, errorMessage = '' }: Props) => {
  const [businessUnitList, setBusinessUnitList] = useState<BusinessUnitInterface[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!businessUnitList.length) {
      fetchBusinessUnitList();
    }
  }, []);

  const fetchBusinessUnitList = async () => {
    dispatch(showGlobalProgressModal(true));
    const _businessUnitList = await DB.businessUnits.orderBy('number').toArray();

    if (_businessUnitList.length) {
      setBusinessUnitList(_businessUnitList);
    }

    dispatch(showGlobalProgressModal(false));
  };

  return (
    <Autocomplete
      options={businessUnitList}
      value={value}
      onChange={(_, newValue) => onChange(newValue as BusinessUnitInterface | null)}
      getOptionLabel={(option: BusinessUnitInterface) => `${option.number} - ${option.name}`}
      getOptionSelected={(option: BusinessUnitInterface, value: any) => option.id === value.id}
      data-cy="stock-transfer-form-destination-business-unit-input"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ziel Filiale"
          variant="outlined"
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
      )}
    />
  );
};

export default StockTransferBusinessUnitSelect;
