import { SessionPermission, SessionPermissionRequest, SessionQueryResult } from '../../modules/app/client/authProvider';
import { useSelector } from 'react-redux';

export const getIsAuthenticated = (state: any): any => state.security.authenticated;
export const getCurrentSession = (state: any): SessionQueryResult | null => state.security?.sessionInfo ?? null;
export const getPermissions = (state: any): SessionPermission[] => {
  const session = getCurrentSession(state);
  if (!session) {
    return [];
  }

  return session.permissions || [];
};

export const getPermission = (state: any, name: string): SessionPermission | undefined =>
  getPermissions(state).find((permission) => permission.name === name);
export const hasPermission = (state: any, request: SessionPermissionRequest): boolean => {
  const permission = getPermission(state, request.name);
  if (!permission) {
    return false;
  }

  let allowed = null;
  for (const prop in request) {
    if (
      !Object.prototype.hasOwnProperty.call(request, prop) ||
      !Object.prototype.hasOwnProperty.call(permission, prop) ||
      prop === 'name'
    ) {
      continue;
    }

    if (allowed === null) {
      allowed = !!(permission as any)[prop];
    } else {
      allowed = allowed && !!(permission as any)[prop];
    }
  }

  return allowed !== null && allowed;
};

export const usePermission = (request: SessionPermissionRequest) => {
  return useSelector((state) => hasPermission(state, request));
};
