import { DB } from '../modules/app/client/dataProvider/storageClient';
import { ItemInventoryMetricInterface, ItemInventoryMetricType } from '../interfaces/ItemInventoryMetricInterface';

async function getLatestJournalEntriesByItemId(itemId: string): Promise<ItemInventoryMetricInterface[]> {
  return (await DB.itemInventoryMetrics.where('itemId').equals(itemId).toArray()) ?? [];
}

async function getLatestJournalEntryByItemId(
  itemId: string,
  type: ItemInventoryMetricType
): Promise<ItemInventoryMetricInterface | null> {
  return (await DB.itemInventoryMetrics.where({ itemId, type }).first()) ?? null;
}

export { getLatestJournalEntriesByItemId, getLatestJournalEntryByItemId };
