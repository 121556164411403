import { ItemInterface } from './ItemListInterface';

export enum StockTakeStatus {
  DRAFT,
  READY,
  SENT,
  SENDING,
}

export interface ItemInventoryShelf {
  number: number;
  name: string;
  tasks: ItemInventoryTask[];
  taskItemIds: string[];
  taskIds: string[];
}
export interface ItemInventoryTask {
  id: string;
  itemId: string;
  item: ItemInterface;
  priority: number;
  reason: string;
  shelfNumber: number;
  comment?: string;
  brandName?: string;
  completedAt?: Date;
  deletedAt?: Date;
}

export interface ItemInventoryStockTake {
  timestamp: number;
  amount: number;
  itemId: string;
  location: string;
  reason: string;
  status: StockTakeStatus;
}

export interface ItemInventoryLocalTask extends ItemInventoryTask {
  item: ItemInterface;
  countings: Array<[string, ItemInventoryStockTake]>;
}

export type ItemInventoryStockUpdateMode = 'add' | 'replace' | 'reset';

export interface ShelfListing {
  name?: string;
  number: number;
  taskCount: number;
  tasks: ItemInventoryLocalTask[];
}

export interface BrandTasksInterface {
  [key: string]: ItemInventoryLocalTask[];
}
