import { Button, Snackbar } from '@material-ui/core';
import React, { FC, useCallback, useEffect } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';
import { useDispatch } from 'react-redux';
import { checkForAppUpdate } from '../../redux/interface/operations';
import { parseCronExpression } from 'cron-schedule';

function randomMinMax(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

function shouldCheckForUpdate() {
  const inputCron = process.env.REACT_APP_UPDATE_CRON || '';

  if (inputCron === '') {
    return true;
  }

  try {
    const cronExpression = parseCronExpression(inputCron);

    return cronExpression.matchDate(new Date());
  } catch (e) {
    return true;
  }
}

function rollUpdateDelay(): number {
  switch (process.env.REACT_APP_UPDATE_DELAY) {
    case 'randomMinute':
      // innerhalb einer Minute zufällig verteilen: 0-59 Sekunden
      return randomMinMax(0, 59) * 1000;

    case 'randomHour':
      // innerhalb einer Stunde zufällig verteilen: 0-59 Minuten
      return randomMinMax(0, 59 * 60) * 1000;

    case 'randomDay':
      // innerhalb eines Tages zufällig verteilen: 0-1440 Minuten
      return randomMinMax(0, 24 * 60 * 60) * 1000;
  }

  const inputDelay = Number.parseInt(process.env.REACT_APP_UPDATE_DELAY || '');

  return Number.isNaN(inputDelay) ? randomMinMax(0, 59) * 1000 : inputDelay;
}

const updateDelay = rollUpdateDelay();

const ServiceWorkerWrapper: FC = () => {
  const dispatch = useDispatch();
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const messageHandler = useCallback((event: MessageEvent) => {
    if (event.data === 'window/reload') {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    if (!navigator.serviceWorker) {
      return;
    }

    navigator.serviceWorker.addEventListener('message', messageHandler);

    return () => {
      navigator.serviceWorker.removeEventListener('message', messageHandler);
    };
  });

  const checkForUpdate = useCallback(async () => {
    if (!shouldCheckForUpdate()) {
      return;
    }

    await dispatch(checkForAppUpdate(false));
    window.setTimeout(checkForUpdate, updateDelay);
  }, []);

  useEffect(() => {
    checkForUpdate();
  }, [checkForUpdate]);

  const reloadPage = () => {
    if (!waitingWorker) {
      return;
    }
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
  };

  return (
    <Snackbar
      open={showReload}
      message="Eine neue Version ist verfügbar!"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          Aktualisieren
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
