import { Breadcrumb } from '@sentry/browser';
import * as Sentry from '@sentry/browser';

const isDebugEnabled = process.env.REACT_APP_SENTRY_DEBUG === 'true' ? () => true : () => false;

interface AddBreadcrumb {
  (breadcrumb: Breadcrumb): void;

  /**
   * Callback wird nur ausgeführt, wenn Debugging aktiviert ist
   */
  (breadcrumb: () => Promise<Breadcrumb>): Promise<void>;
}
export default function createDebugLogger(target: string) {
  const logFunction = (message: string, extras: Record<string, any> = {}, tags: Record<string, any> = {}) => {
    if (!isDebugEnabled()) {
      return;
    }

    Sentry.withScope((scope) => {
      scope.setLevel('debug');
      scope.setTag('debug-target', target);
      scope.setTags(tags);
      scope.setExtras(extras);
      scope.clearBreadcrumbs();
      Sentry.captureMessage(message);
    });
  };
  return Object.assign(logFunction, {
    create: () => {
      const breadcrumbs: Breadcrumb[] = [];
      return {
        addBreadcrumb: (async (breadcrumb: Breadcrumb | (() => Promise<Breadcrumb>)) => {
          if (!isDebugEnabled()) {
            return;
          }
          if (typeof breadcrumb === 'function') {
            breadcrumb = await breadcrumb();
          }

          breadcrumbs.push({ timestamp: new Date().getTime(), ...breadcrumb });
        }) as AddBreadcrumb,
        log: (message: string, extras: Record<string, any> = {}, tags: Record<string, any> = {}) => {
          if (!isDebugEnabled()) {
            return;
          }

          Sentry.withScope((scope) => {
            scope.setLevel('debug');
            scope.setTag('debug-target', target);
            scope.setTags(tags);
            scope.setExtras(extras);
            scope.clearBreadcrumbs();
            for (const breadcrumb of breadcrumbs) {
              scope.addBreadcrumb(breadcrumb);
            }
            Sentry.captureMessage(message);
          });
        },
      };
    },
  });
}
