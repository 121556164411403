import * as types from './types';
import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';

export const shelfTasks = (state: Array<[number, ShelfListing]> = [], action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.SET_SHELF_TASKS:
      return [...action.payload];
    default:
      return state;
  }
};
