import { Dispatch } from 'redux';
import { Event } from '../../interfaces/EventInterface';
import types from './types';

export const setItemInventoryEvents = (events: Array<Event>) => {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      type: types.SET_ITEM_INVENTORY_EVENTS,
      payload: events,
    });
  };
};
