import * as Sentry from '@sentry/browser';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { SessionQueryResult } from '../../app/client/authProvider';
import GraphQLClient from '../../app/client/dataProvider/apiClient';
import { ConfigurationKeyValue, DB } from '../../app/client/dataProvider/storageClient';
import { SyncStatusAdditionalInfo } from '../../../redux/interface/reducers';
import createDebugLogger from '../../../utils/debug';

declare const self: any; // WorkerGlobalScope;

// We alias self to ctx and give it our newly created type
const ctx: Worker = self;

export enum RunResultStatus {
  SUCCESS,
  FINISHED,
  ERROR,
  UNAUTHORIZED,
}

export interface RunResult {
  status: RunResultStatus;
  progress?: {
    /**
     * Aktuelle cursor-Position (wird neuer this.cursor)
     */
    lastId?: string;
    /**
     * Zeitstempel des neusten Datensatzes für die Anzeige "Datenstand"
     */
    lastUpdate?: string;
    records: {
      remaining?: number;
      processed: number;
    };
  };
  checkpointData?: ConfigurationKeyValue[];
  restart?: boolean;
  additionalInfo?: SyncStatusAdditionalInfo;
}

export interface SyncLoaderInterface {
  init(): Promise<void>;
  start(): Promise<void>;
  abort(): void;
}

export interface QueryInputListArguments {
  pagination: {
    limit: number;
    offset: number;
  };
  filters: Array<{ column: string; operator: string; payload: any }>;
  sorts: Array<{ column: string; direction: -1 | 1 }>;
}

export const maxUpdatedAt = (nodes: Array<{ updatedAt: string }>) =>
  nodes.reduce((val, node) => (!val || node.updatedAt > val ? node.updatedAt : val), undefined as string | undefined);

export type Diff<T, U> = T extends U ? never : T;
export type RequiredExceptFor<T, TOptional extends keyof T> = Pick<T, Diff<keyof T, TOptional>> & Partial<T>;

export enum SyncResetReason {
  UNKNOWN = 'UNKNOWN',
  MANUAL = 'MANUAL',
  SCHEMA_VERSION_MISMATCH = 'SCHEMA_VERSION_MISMATCH',
  CLEANUP = 'CLEANUP',
}

export default abstract class AbstractSyncLoader implements SyncLoaderInterface {
  protected schemaVersion = 1;
  protected batchSize: number = 500 * Number(process.env.REACT_APP_BATCH_FACTOR || 1);
  protected client!: ApolloClient<any>;
  protected session!: SessionQueryResult;
  protected doAbort = false;

  // Pagination-Status
  protected cursor?: string;
  /**
   * Zeitstempel der letzten erfolgreichen Synchronisierung
   */
  protected lastRun?: Date;
  /**
   * Server-Zeitstempel des Begin!! der aktuellen Synchronisierung.
   * Wird nach abschluss zum neuen lastRun
   */
  protected syncTs?: Date;
  protected total?: number;

  protected constructor(protected readonly typeName: string) {}

  private async getServerTime() {
    const result = await this.client.query<{ systemTime: string }>({
      fetchPolicy: 'no-cache',
      query: gql`
        query ServerTime {
          systemTime
        }
      `,
    });
    return new Date(result.data.systemTime);
  }

  public async init() {
    const sessionData = await DB.configuration.where('key').equals('session/information').first();
    if (!sessionData) {
      throw new Error('No session data found, cannot continue');
    }
    this.session = sessionData.value;
    this.client = await GraphQLClient();

    // Benutzer und Filiale auch für SyncWorker loggen
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: this.session.username,
        ip_address: this.session.remoteAddress,
      });
      scope.setTag('businessUnitId', this.session.businessUnitGroup.unit.id);
      scope.setTag('businessUnitNumber', this.session.businessUnitGroup.unit.number.toString());
    });

    // force full sync if schema version has changed
    const syncVersion = await DB.configuration.get({
      key: `sync/${this.typeName}/version`,
    });
    if (!syncVersion || syncVersion.value !== this.schemaVersion) {
      const logDebug = createDebugLogger(this.typeName);
      logDebug(
        `Schema version mismatch, forcing reset of ${this.typeName}`,
        {
          previousVersion: syncVersion ? syncVersion.value : 'unknown',
          newVersion: this.schemaVersion,
        },
        {
          loaderType: this.typeName,
          businessUnitId: this.session.businessUnitGroup.unit.id,
          businessUnitNumber: this.session.businessUnitGroup.unit.number.toString(),
        }
      );

      await this.reset(SyncResetReason.SCHEMA_VERSION_MISMATCH);
    } else {
      await this.restorePagination();
    }
  }

  private async restorePagination() {
    this.cursor = (
      await DB.configuration.get({
        key: `sync/${this.typeName}/lastId`,
      })
    )?.value;

    const lastRun = await DB.configuration.get({
      key: `sync/${this.typeName}/lastRun`,
    });
    if (lastRun && lastRun.value) {
      this.lastRun = new Date(lastRun.value);
    } else {
      this.lastRun = undefined;
    }

    const syncTs = await DB.configuration.get({
      key: `sync/${this.typeName}/syncTs`,
    });
    if (syncTs && syncTs.value) {
      this.syncTs = new Date(syncTs.value);
    } else {
      this.syncTs = undefined;
    }
  }

  public abort() {
    this.doAbort = true;
  }

  public async start() {
    this.doAbort = false;
    ctx.postMessage({
      type: 'sync/start',
      syncType: this.typeName,
    });

    // Dauer der Verarbeitung (Abruf + Speicherung) __pro__ Item
    const runTimings: number[] = [];

    if (!this.syncTs) {
      try {
        this.syncTs = await this.getServerTime();
        DB.configuration.put({
          key: `sync/${this.typeName}/syncTs`,
          value: this.syncTs.toISOString(),
        });
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes('401')) {
            ctx.postMessage({
              type: 'sync/interrupted',
              syncType: this.typeName,
            });
            ctx.postMessage({ type: 'security/logout' });
            return;
          }
          if (e.message.includes('Network error')) {
            throw e;
          }
        }

        Sentry.captureException(e);
        console.error(e);
        return;
      }
    }

    let runResult: RunResult | undefined;
    do {
      // Verarbeitung ausführen und Dauer für den einzelnen Lauf erfassen
      const startTime = Date.now();
      runResult = await this.run(runResult);
      const runTiming = Date.now() - startTime;

      const checkpointData = runResult.checkpointData || [];
      if (runResult.status === RunResultStatus.FINISHED) {
        this.lastRun = this.syncTs || new Date();
        checkpointData.push({
          key: `sync/${this.typeName}/lastRun`,
          value: this.lastRun.toISOString(),
        });
        this.syncTs = undefined;
        checkpointData.push({
          key: `sync/${this.typeName}/syncTs`,
          value: undefined,
        });
        this.cursor = undefined;
        checkpointData.push({
          key: `sync/${this.typeName}/lastId`,
          value: undefined,
        });
      }
      if (runResult.progress !== undefined) {
        if (runResult.progress.lastId && runResult.status !== RunResultStatus.FINISHED) {
          this.cursor = runResult.progress.lastId;
          checkpointData.push({
            key: `sync/${this.typeName}/lastId`,
            value: runResult.progress.lastId,
          });
        }

        if (runResult.progress.lastUpdate) {
          const newLastUpdate = new Date(runResult.progress.lastUpdate);
          const lastUpdate = (await DB.configuration.get(`sync/${this.typeName}/lastUpdate`))?.value;
          if (!lastUpdate || newLastUpdate > new Date(lastUpdate)) {
            checkpointData.push({
              key: `sync/${this.typeName}/lastUpdate`,
              value: newLastUpdate.toISOString(),
            });
          }
        }
      }

      if (checkpointData.length > 0) {
        await DB.configuration.bulkPut(checkpointData);
      }

      const lastUpdate = await DB.configuration.get(`sync/${this.typeName}/lastUpdate`);

      if (runResult.progress !== undefined) {
        // Durchschnittliche Verarbeitungsdauer pro Eintrag speichern
        if (runResult.progress.records.processed > 0) {
          runTimings.push(runTiming / runResult.progress.records.processed);
          runTimings.splice(0, runTimings.length - 5);
        }

        // Verbleibende Restzeit in Sekunden berechnen
        const recordsLeft = runResult.progress.records.remaining || 0;
        if (!this.total) {
          this.total = recordsLeft + runResult.progress.records.processed;
        }
        const avgTimingPerRecord =
          runTimings.length === 0
            ? 0
            : runTimings.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / runTimings.length;

        const timingEstimation = Math.max(0, Math.ceil((recordsLeft * avgTimingPerRecord) / 1000));

        ctx.postMessage({
          type: 'sync/progress',
          syncType: this.typeName,
          progress: !this.total ? 1 : Math.max(0, Math.min(1, 1 - recordsLeft / this.total)),
          estimation: timingEstimation,
          lastRun: this.lastRun,
          lastUpdate: lastUpdate && lastUpdate.value ? new Date(lastUpdate.value) : undefined,
          additionalInfo: runResult.additionalInfo,
        });
      }

      if (runResult.status === RunResultStatus.UNAUTHORIZED) {
        ctx.postMessage({
          type: 'sync/interrupted',
          syncType: this.typeName,
        });
        ctx.postMessage({ type: 'security/logout' });
        break;
      } else if (this.doAbort || runResult.status === RunResultStatus.ERROR) {
        ctx.postMessage({
          type: 'sync/interrupted',
          syncType: this.typeName,
        });
        break;
      } else if (runResult.status === RunResultStatus.FINISHED) {
        ctx.postMessage({
          type: 'sync/complete',
          syncType: this.typeName,
          lastRun: this.lastRun,
          lastUpdate: lastUpdate && lastUpdate.value ? new Date(lastUpdate.value) : undefined,
          additionalData: runResult.additionalInfo,
        });
        break;
      }

      // der Job soll wieder von vorne starten
      if (runResult.restart) {
        runResult = undefined;
      }
    } while (true);
    this.total = undefined;
  }

  /**
   * Track required time for promise completion and return optimized batch size
   */
  protected async slowDownTracker(promise: Promise<any>, currentBatchSize: number, thresholdMs = 500) {
    const startTime = Date.now();

    await promise;

    let newBatchSize: number;
    const timeRequiredMs = Date.now() - startTime;
    if (timeRequiredMs > thresholdMs) {
      newBatchSize = Math.max(Math.round(currentBatchSize * 0.9), 100);
      // console.log(`${this.typeName} took ${timeRequiredMs}ms, slowing down -10% to`, newBatchSize);
    } else {
      newBatchSize = Math.min(Math.round(currentBatchSize * 1.05), 1000);
      // console.log(`${this.typeName} took ${timeRequiredMs}ms, speeding up +5% to`, newBatchSize);
    }

    return newBatchSize;
  }

  /**
   * Reset sync state
   */
  protected async reset(reason: SyncResetReason = SyncResetReason.UNKNOWN) {
    const logDebug = createDebugLogger(this.typeName);
    logDebug(
      `${this.typeName} was reset`,
      {},
      {
        reason,
        businessUnitId: this.session.businessUnitGroup.unit.id,
        businessUnitNumber: this.session.businessUnitGroup.unit.number.toString(),
      }
    );

    await DB.configuration.bulkDelete([
      `sync/${this.typeName}/lastId`,
      `sync/${this.typeName}/lastUpdate`,
      `sync/${this.typeName}/lastRun`,
      `sync/${this.typeName}/syncTs`,
    ]);

    this.syncTs = undefined;
    this.total = undefined;
    this.cursor = undefined;
    this.lastRun = undefined;
    await DB.configuration.put({ key: `sync/${this.typeName}/version`, value: this.schemaVersion });
  }

  protected abstract run(prevRunResult?: RunResult): Promise<RunResult>;

  protected getPaginationQueryArgs(
    prevRunResult?: RunResult,
    queryArgs?: Partial<QueryInputListArguments>
  ): QueryInputListArguments {
    if (queryArgs?.sorts) {
      throw new Error('LogicException: Can not paginate with existing sorts');
    }
    const filters: QueryInputListArguments['filters'] = [...(queryArgs?.filters || [])];
    if (this.lastRun) {
      filters.push({
        column: 'updatedAt',
        operator: 'greater_or_equal',
        payload: this.lastRun.toISOString(),
      });
    }
    if (this.cursor) {
      filters.push({ column: 'id', operator: 'greater', payload: this.cursor });
    }

    return {
      filters,
      pagination: { limit: this.batchSize, offset: 0 },
      sorts: [{ column: 'id', direction: 1 }],
    };
  }
}
