import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { push } from 'connected-react-router';
import React, { useEffect, useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { checkForAppUpdate } from '../../redux/interface/operations';
import { getIsAuthenticated } from '../../redux/security/selectors';
import HomeScreen from './home';
import ItemDetails from './items/details';
import ItemsList from './items/list';
import GlobalProgressModal from './layout/GlobalProgressModal';
import Header from './layout/header';
import Messages from './layout/messages';
import Navigator, { CategoryInterface } from './layout/navigator';
import Login from './security/login';
import ServiceWorkerWrapper from './serviceWorkerWrapper';
import SyncWorkerWrapper from './SyncWorkerWrapper';
import { lightTheme } from './theme';
import DatabaseWorkerWrapper from './DatabaseWorkerWrapper';
import { SyncOverview } from './sync/SyncOverview';
import AppConfig from './config';

const drawerWidth = 256;

const styles: StyleRules = createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [lightTheme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  main: {
    flex: 1,
    // padding: lightTheme.spacing(1, 1),
    background: '#eaeff1',
  },
  footer: {
    padding: lightTheme.spacing(2),
    background: '#eaeff1',
  },
});
export interface AppProps extends WithStyles<typeof styles> {
  isAuthenticated: boolean;
  push: typeof push;
}

const navCategories = [
  {
    id: 'Menü',
    children: [{ id: 'Login', icon: <LockIcon />, active: true }],
  },
];

const App = (props: AppProps) => {
  const { classes, isAuthenticated } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [routesMap, setRoutesMap] = React.useState<Route[]>([]);
  const [navigationMap, setNavigationMap] = React.useState<CategoryInterface[]>([]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // check for updates on app start
    dispatch(checkForAppUpdate(false));
  }, [dispatch]);

  useEffect(() => {
    setRoutesMap(AppConfig.getRoutesMap());
  }, AppConfig.getRoutesMap());

  useEffect(() => {
    const loggedInNavCategories: CategoryInterface[] = [
      {
        id: 'Menü',
        children: AppConfig.getNavigationMap(),
      },
    ];

    setNavigationMap(loggedInNavCategories);
  }, AppConfig.getNavigationMap());

  return (
    <div className={classes.root}>
      <ServiceWorkerWrapper />
      <DatabaseWorkerWrapper />
      <SyncWorkerWrapper />
      <CssBaseline />
      <Messages />
      <GlobalProgressModal />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            data-cy="navigationMenu"
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            categories={isAuthenticated ? navigationMap : navCategories}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator
            data-cy="navigationMenu"
            categories={isAuthenticated ? navigationMap : navCategories}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>
          {isAuthenticated ? (
            <Switch>
              <Route path="/sync" component={SyncOverview} />
              {routesMap.map((route: Route, key) => (
                <Route key={key} {...route.props} />
              ))}
              <Route path="/items/list" component={ItemsList} />
              <Route path="/items/details" exact component={ItemDetails} />
              <Route path="/items/details/byCode/:code/:previousCode?" component={ItemDetails} />
              <Route component={HomeScreen} />
            </Switch>
          ) : (
            <Switch>
              <Route component={Login} />
            </Switch>
          )}
        </main>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: getIsAuthenticated(state),
  }),
  {
    push,
  }
)(withStyles(styles)(App));
