import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient, DefaultOptions } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { ConfigurationKeyValue, DB } from './storageClient';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_URL,
  headers: {
    'X-App-Version': process.env.REACT_APP_VERSION_HASH || 'dev',
  },
});

let apiToken: ConfigurationKeyValue | undefined;
let clientInstance: ApolloClient<any>;

const cache = new InMemoryCache();

const defaultOptions: DefaultOptions = {
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = async () => {
  const _apiToken = await DB.configuration.where({ key: 'api/token' }).first();

  if (clientInstance && _apiToken && _apiToken.value === apiToken) {
    return clientInstance;
  }

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (_apiToken) {
      apiToken = _apiToken.value;

      operation.setContext({
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'X-App-Version': process.env.REACT_APP_VERSION_HASH || 'dev',
        },
      });
    }

    return forward ? forward(operation) : null;
  });

  clientInstance = new ApolloClient({
    link: from([authMiddleware, httpLink]),
    cache,
    defaultOptions,
  });

  return clientInstance;
};

export default client;
