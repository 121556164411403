import { ButtonProps, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import { CommonProps } from '@material-ui/core/OverridableComponent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import BackspaceIcon from '@material-ui/icons/Backspace';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';

export interface NumpadBaseKeyInterface {
  width?: number | string;
}

export interface NumpadStringLabeledKeyInterface extends NumpadBaseKeyInterface {
  label: string;
  code?: string;
}

export interface NumpadElementLabeledKeyInterface extends NumpadBaseKeyInterface {
  label: JSX.Element;
  code: string;
}

export interface NumpadLabeledKeyProps {
  inputProps?: any;
}

export type NumpadPlaceholderKeyInterface = Required<NumpadBaseKeyInterface>;

type NumpadLabeledKeyInterface = (NumpadStringLabeledKeyInterface | NumpadElementLabeledKeyInterface) &
  NumpadLabeledKeyProps;

export type NumpadKeyInterface = NumpadLabeledKeyInterface | NumpadPlaceholderKeyInterface;

export interface NumpadProps extends WithStyles {
  className?: string;
  keys: NumpadKeyInterface[][];
  onClick: (code: string) => void;
}

export interface NumpadKeyProps extends CommonProps<ButtonTypeMap>, ButtonProps {
  data: NumpadKeyInterface;
  onNumPadClick: (code: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      flex: '1 1 calc(100% / 3)',
      borderRight: '1px solid #aeaeae',
      borderRadius: 0,
      borderBottom: '1px solid #aeaeae',
      fontSize: theme.typography.pxToRem(22),
      fontWeight: 'bold',

      '&:last-child': {
        borderRight: 0,
      },
    },
    lastRow: {
      borderBottom: 0,
    },
  });

function NumpadKey({ data, onNumPadClick, ...rest }: NumpadKeyProps) {
  const [code, setCode] = React.useState<string | undefined>(undefined);
  const onButtonClick = React.useCallback(() => {
    if (code === undefined) {
      return;
    }

    onNumPadClick(code);
  }, [code, onNumPadClick]);

  React.useEffect(() => {
    if (isLabelKey(data)) {
      setCode(data.code || (data.label as string));
    }
  }, [data]);

  if (!isLabelKey(data)) {
    return null;
  }

  return (
    <Button onClick={onButtonClick} {...rest}>
      {data.label}
    </Button>
  );
}

export const NumpadKeyboardLayout: NumpadKeyInterface[][] = [
  [
    {
      label: '7',
    },
    {
      label: '8',
    },
    {
      label: '9',
    },
  ],
  [
    {
      label: '4',
    },
    {
      label: '5',
    },
    {
      label: '6',
    },
  ],
  [
    {
      label: '1',
    },
    {
      label: '2',
    },
    {
      label: '3',
    },
  ],
  [
    {
      width: 1,
    },
    {
      label: '0',
    },
    {
      label: <BackspaceIcon />,
      code: 'backspace',
    },
  ],
];

export const NumpadMobileKeyboardLayout: NumpadKeyInterface[][] = [
  [
    {
      label: '1',
    },
    {
      label: '2',
    },
    {
      label: '3',
    },
  ],
  [
    {
      label: '4',
    },
    {
      label: '5',
    },
    {
      label: '6',
    },
  ],
  [
    {
      label: '7',
    },
    {
      label: '8',
    },
    {
      label: '9',
    },
  ],
  [
    {
      label: <BackspaceIcon />,
      code: 'backspace',
    },
    {
      label: '0',
    },
    {
      label: 'OK',
      code: 'return',
      inputProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
  ],
];

function isLabelKey(key: NumpadKeyInterface): key is NumpadLabeledKeyInterface {
  return (key as NumpadLabeledKeyInterface).label !== undefined;
}

const Numpad = (props: NumpadProps) => {
  const { keys: rows, onClick, classes } = props;

  const lastRowIndex = rows.length - 1;

  return (
    <div className={clsx(classes.container, props.className)}>
      {rows.map((keys, rowIndex) => (
        <div key={rowIndex} className={classes.buttonRow}>
          {keys.map((keyData, keyIndex) =>
            isLabelKey(keyData) ? (
              <NumpadKey
                data-cy="numpad-button"
                className={rowIndex !== lastRowIndex ? classes.button : clsx(classes.button, classes.lastRow)}
                key={keyIndex}
                data={keyData}
                onNumPadClick={(code) => onClick(code)}
                size="large"
                {...keyData.inputProps}
              />
            ) : (
              <Button
                data-cy="numpad-button"
                className={rowIndex !== lastRowIndex ? classes.button : clsx(classes.button, classes.lastRow)}
                key={keyIndex}
                disabled
                size="large"
              />
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default connect()(withStyles(styles)(Numpad));
