import React from 'react';
import { ItemPriceInformationType } from '../../../interfaces/ItemListInterface';
import { Chip, Typography } from '@material-ui/core';

type CurrencyProps = {
  value: number;
  locales?: 'de-DE' | 'en-EN';
  currency?: 'EUR' | 'USD';
};

type DetailedCurrencyFieldProps = {
  priceInformation?: ItemPriceInformationType | null;
  showValidityPeriod?: boolean;
  locale?: 'de-DE' | 'en-EN';
};

const Currency = ({ value, locales = 'de-DE', currency = 'EUR' }: CurrencyProps) => {
  const valueFormatter = new Intl.NumberFormat(locales, { style: 'currency', currency });

  return <React.Fragment>{valueFormatter.format(value)}</React.Fragment>;
};

const DetailedCurrencyField = ({
  priceInformation,
  showValidityPeriod = false,
  locale = 'de-DE',
}: DetailedCurrencyFieldProps) => {
  if (!priceInformation) {
    return <Chip size="small" label="unbekannt" disabled variant="outlined" />;
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  let textComponent: JSX.Element | null = null;
  if (priceInformation?.effectiveDate) {
    const additionalStrings: string[] = [];

    additionalStrings.push(`Von ${new Date(priceInformation.effectiveDate).toLocaleDateString(locale, dateOptions)}`);

    if (priceInformation.expirationDate) {
      additionalStrings.push(
        `bis ${new Date(priceInformation.expirationDate).toLocaleDateString(locale, dateOptions)}`
      );
    }

    textComponent = (
      <Typography key="additional-price-text" variant="subtitle2">
        ({additionalStrings.join(' ')})
      </Typography>
    );
  } else if (priceInformation?.expirationDate) {
    textComponent = (
      <Typography variant="subtitle2">
        (Bis {new Date(priceInformation.expirationDate).toLocaleDateString(locale, dateOptions)})
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <Typography>
        <Currency value={priceInformation.price} />
      </Typography>
      {showValidityPeriod && textComponent}
    </React.Fragment>
  );
};

export { Currency, DetailedCurrencyField };
