import React from 'react';
import { Route, Switch } from 'react-router';
import RequestStockTransferList from './components/RequestStockTransferList';
import RequestStockTransferForm from './components/RequestStockTransferForm';

const RequestStockTransferRouter = () => (
  <Switch>
    <Route path="/stock-transfer/request/:id/:type" component={RequestStockTransferForm} />
    <Route component={RequestStockTransferList} />
  </Switch>
);

export default RequestStockTransferRouter;
