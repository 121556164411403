import { v4 as uuidv4 } from 'uuid';
import { SnackInterface, SyncStatus } from './reducers';
import {
  DATABASE_MESSAGE,
  RESET_SNACK,
  SET_NAVIGATION_UPWARD_TARGET,
  SET_ONLINE_STATUS,
  SET_SNACK,
  SET_TITLE,
  SHOW_PROGRESS_MODAL,
  SYNC_MESSAGE,
  SYNC_STATUS,
} from './types';

export const setSyncStatus = (status: SyncStatus) => ({
  type: SYNC_STATUS,
  payload: status,
});

export const setTitle = (title: string | null) => ({
  type: SET_TITLE,
  payload: title,
});

export const setOnlineStatus = (isOnline: boolean | undefined) => ({
  type: SET_ONLINE_STATUS,
  payload: isOnline,
});

export const setSnack = (message: SnackInterface) => ({
  type: SET_SNACK,
  payload: { open: true, ...message },
});

export const sendMessageToSyncWorker = (message: any) => ({
  type: SYNC_MESSAGE,
  payload: message,
});

export const sendMessageToDatabaseWorker = (name: string, args: any, callback?: (result: any) => void) => {
  return {
    type: DATABASE_MESSAGE,
    payload: {
      name,
      args,
      responseId: uuidv4(),
      callback,
    },
  };
};

export const resetSnack = () => ({
  type: RESET_SNACK,
});

export const showGlobalProgressModal = (show = true) => ({
  type: SHOW_PROGRESS_MODAL,
  payload: show,
});

export const setNavigationUpwardTarget = (target: string | null) => ({
  type: SET_NAVIGATION_UPWARD_TARGET,
  payload: target,
});
