import { FormControl, InputLabel, MenuItem, Select, TextField, Theme, withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../redux/interface/actions';
import { checkLocalStorageToken, checkUserLoginCredential } from '../../../redux/security/operations';
import Content from '../layout/content';
import { SessionQueryBusinessUnitGroupInterface } from '../client/authProvider';
import { DB } from '../client/dataProvider/storageClient';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    submit: {
      marginTop: theme.spacing(2),
    },
  });

const Login = (props: any) => {
  const availableBusinessUnitGroups = useSelector<any, SessionQueryBusinessUnitGroupInterface[]>(
    (state) => state?.security?.availableBusinessUnitGroups || []
  );
  const dispatch = useDispatch();
  const { classes, setTitle, authenticating, checkLocalStorageToken } = props;
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [selectedBusinessUnitGroupId, setSelectedBusinessUnitGroupId] = React.useState('');

  useEffect(() => {
    if (!availableBusinessUnitGroups.length) {
      return;
    }

    (async () => {
      const lastBusinessUnitGroupResult = await DB.configuration.get('general/lastBusinessUnitGroup');
      const lastBusinessUnitGroup = availableBusinessUnitGroups.find(
        ({ id }) => id === lastBusinessUnitGroupResult?.value?.id
      );

      setSelectedBusinessUnitGroupId(lastBusinessUnitGroup?.id || '');
    })();
  }, [availableBusinessUnitGroups]);

  useEffect(() => {
    checkLocalStorageToken();
  }, [checkLocalStorageToken]);

  useEffect(() => {
    setTitle(`Login`);
  }, [setTitle]);

  const handleLogin = () => {
    if (selectedBusinessUnitGroupId) {
      const businessUnitGroup = availableBusinessUnitGroups.find(({ id }) => id === selectedBusinessUnitGroupId);

      return dispatch(checkUserLoginCredential(username, password, businessUnitGroup));
    }

    dispatch(checkUserLoginCredential(username, password));
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Content>
      <form noValidate autoComplete="off">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {availableBusinessUnitGroups.length ? (
            <>
              <Typography component="h1" variant="h5">
                Wählen Sie Ihre Filiale aus
              </Typography>
              <FormControl variant="outlined" margin="normal" fullWidth>
                {/* ugly workaround to display label properly without overlapping; see https://github.com/mui/material-ui/issues/22572 */}
                <InputLabel id="businessUnitGroup-select-label">Filiale</InputLabel>
                <Select
                  data-cy="businessUnitGroup-select"
                  label="Filiale"
                  labelId="businessUnitGroup-select-label"
                  variant="outlined"
                  value={selectedBusinessUnitGroupId}
                  onChange={(event) => setSelectedBusinessUnitGroupId(event.target.value as string)}
                >
                  {availableBusinessUnitGroups
                    .filter(
                      (group) =>
                        'active' === group.unit?.statusCode &&
                        null !== group.unit?.number &&
                        undefined !== group.unit?.number
                    )
                    .sort((bugA, bugB) => Number(bugA.unit.number) - Number(bugB.unit.number))
                    .map(({ id, unit }, key: number) => (
                      <MenuItem key={key} value={id} data-cy={`businessUnitGroup-select-option-entry`}>
                        {`${unit.number} - ${unit.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <Typography component="h1" variant="h5">
                Melden Sie sich an
              </Typography>
              <TextField
                fullWidth
                required
                autoComplete="username"
                label="Benutzername"
                variant="outlined"
                margin="normal"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
              <TextField
                fullWidth
                required
                autoComplete="current-password"
                label="Passwort"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                margin="normal"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyUp={async (e) => {
                  if (e.code === 'Enter') {
                    e.preventDefault();
                    await handleLogin();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
          <Button
            className={classes.submit}
            disabled={(!username.length || !password.length || authenticating) && !selectedBusinessUnitGroupId}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Anmelden
          </Button>
        </div>
      </form>
    </Content>
  );
};

export default connect((state: any) => ({ authenticating: state.security?.authenticating }), {
  checkLocalStorageToken,
  setTitle,
})(withStyles(styles)(Login));
