import { BusinessUnitInterface } from '@meeva/erp-types-core/dist';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import { RequestTransferDocumentInterface } from '@meeva/service-client-core/src/interfaces/RequestTransferDocumentInterface';

const getEntries = async () => (await DB.requestTransferDocuments.toArray()) ?? [];

enum ScannedBusinessUnitStatus {
  DRAFT,
  STARTED,
  FINISHED,
}

const getScannedStatusByEntry = (entry: RequestTransferDocumentInterface) => {
  let status = ScannedBusinessUnitStatus.DRAFT;

  for (const lineItem of entry.lineItems) {
    if (lineItem.scannedQuantity !== 0 && lineItem.scannedQuantity < lineItem.unitCount) {
      status = ScannedBusinessUnitStatus.STARTED;
      break;
    } else if (lineItem.scannedQuantity >= lineItem.unitCount) {
      status = ScannedBusinessUnitStatus.FINISHED;
    }
  }
  return status;
};

export interface BusinessUnitStatusInterface extends BusinessUnitInterface {
  status?: number;
}

const getBusinessUnitsWithEntries = async () => {
  const resultObj: { [key: string]: BusinessUnitStatusInterface } = {};

  for (const entry of await getEntries()) {
    const scannStatus = getScannedStatusByEntry(entry);
    const dBuId = entry.destinationBusinessUnit.id;
    resultObj[dBuId] = entry.destinationBusinessUnit;

    const lastStatus = resultObj[dBuId]?.status;
    if (!lastStatus) {
      resultObj[dBuId].status = scannStatus;
    } else {
      if (lastStatus === ScannedBusinessUnitStatus.DRAFT || lastStatus === scannStatus) {
        // Der Status bleibt unverändert
        continue;
      }
      resultObj[dBuId].status = ScannedBusinessUnitStatus.DRAFT;
    }
  }

  const businessUnits = Object.values(resultObj);
  businessUnits.sort((a, b) => (a.number || 0) - (b.number || 0));

  return businessUnits;
};

// TODO: Use Index
const getEntriesByBusinessUnit = async (id: string) =>
  (await getEntries()).filter((node) => node.destinationBusinessUnit.id === id);

const getEntryById = async (id: string) => DB.requestTransferDocuments.get({ id });

// const updateContainerCode = async (id: string, containerCode: string) => {
//   await DB.requestTransferDocuments.where({ id }).modify((entry) => {
//     entry.containerCode = containerCode;
//   });
// };

const updateScannedQuantity = async (documentId: string, itemId: string, quantity = 1) => {
  let foundItem = false;
  const success = await DB.requestTransferDocuments.where({ id: documentId }).modify((document) => {
    for (const lineItem of document.lineItems) {
      if (lineItem.item.id !== itemId) {
        continue;
      }
      foundItem = true;
      lineItem.scannedQuantity = quantity;
    }
  });

  return Boolean(success) && foundItem;
};

const updateScannedQuantityFromTasks = async (
  destinationBusinessUnitId: string,
  documentId: string,
  itemId: string
) => {
  let quantity = 0;
  await DB.stockTransferTasks
    .where({ destinationBusinessUnitId })
    .and((task) => task.type === 'request')
    .and((task) => task.requestDocumentId === documentId)
    .each((task) => {
      task.lineItems.filter((li) => li.item.id === itemId).forEach((li) => (quantity += li.unitCount));
    });

  return updateScannedQuantity(documentId, itemId, quantity);
};

export {
  getEntries,
  getEntriesByBusinessUnit,
  getBusinessUnitsWithEntries,
  getEntryById,
  // updateContainerCode,
  updateScannedQuantity,
  updateScannedQuantityFromTasks,
};
