import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ItemInterface } from '../../../interfaces/ItemListInterface';
import { useDispatch } from 'react-redux';
import { setSnack } from '../../../redux/interface/actions';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DB } from '../../app/client/dataProvider/storageClient';

interface CustomProps {
  item: ItemInterface;
  open: boolean;
  onClose: () => void;
}

type Props = CustomProps & WithStyles<typeof styles>;

const styles = createStyles({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 240,
    minHeight: 200,
  },
  image: {
    width: 240,
    height: 200,
  },
});

const ItemImageDialog = ({ item, open, onClose, classes }: Props) => {
  const [itemImageObjectUrl, setItemImageObjectUrl] = React.useState<string>('');

  const dispatch = useDispatch();

  const fetchProductImage = async () => {
    try {
      const apiToken = await DB.configuration.where({ key: 'api/token' }).first();
      const response = await fetch(item.media!.imagePath!, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apiToken?.value || null}`,
          Accept: 'image/*',
        },
      });

      if (response.status === 200) {
        const imageBlob = await response.blob();

        setItemImageObjectUrl(URL.createObjectURL(imageBlob));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      if (!item.media?.imagePath) {
        dispatch(
          setSnack({
            severity: 'warning',
            autoHideDuration: 3000,
            text: 'Zu diesem Artikel gibt es keine Bilder',
          })
        );

        return onClose();
      }

      fetchProductImage();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} data-cy="item-image-dialog">
      <DialogContent>
        <div className={classes.imageContainer}>
          {itemImageObjectUrl ? (
            <img src={itemImageObjectUrl} className={classes.image} alt={item.itemNo} data-cy="item-image-dialog-img" />
          ) : (
            <CircularProgress size={64} data-cy="item-image-dialog-loading-indicator" />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={onClose} data-cy="item-image-dialog-close-button">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ItemImageDialog);
