import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { ItemInterface } from '@meeva/service-client-core/interfaces/ItemListInterface';
import {
  StockTransferStatus,
  StockTransferTaskFormInterface,
} from '@meeva/service-client-core/interfaces/StockTransferInterface';
import React, { Fragment } from 'react';

export interface TaskRowInterface extends Omit<StockTransferTaskFormInterface, 'item' | 'scannedQuantity'> {
  type: string;
  item?: ItemInterface;
  scannedQuantity: number;
  scannTime?: Date;
}

interface Props {
  task: TaskRowInterface;
  onRowClick?: (event: React.MouseEvent | React.TouchEvent) => void;
  highlightAddition?: boolean;
  addDivider?: boolean;
}

export const rowStyles = {
  destinationRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  itemRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  divider: {
    borderBottom: '2px solid #939393',
  },
  itemCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  errorCell: {
    fontWeight: 900,
  },
  bold: {
    fontWeight: 900,
  },
  highlightRowAdditionBorderTop: {
    borderTop: '3px solid #da2739',
    borderLeft: '3px solid #da2739',
    borderRight: '3px solid #da2739',
  },
  highlightRowAdditionBorderBottom: {
    borderBottom: '3px solid #da2739',
    borderLeft: '3px solid #da2739',
    borderRight: '3px solid #da2739',
  },
  highlightRowAdditionBorderSides: {
    borderLeft: '3px solid #da2739',
    borderRight: '3px solid #da2739',
    borderBottom: '3px solid #da2739',
  },
  statusCode0: {
    backgroundColor: '#FFF',
  },
  statusCode1: {
    // StockTransferStatus.READY
    backgroundColor: '#FFC547E0',
  },
  statusCode2: {
    // StockTransferStatus.SENT
    backgroundColor: '#69af4c99',
  },
  statusCode3: {
    // StockTransferStatus.ERROR
    backgroundColor: '#FF00003A',
  },
  statusCode4: {
    // StockTransferStatus.CONTAINER_SEALING
    backgroundColor: '#FFC547E0',
  },
  statusCode5: {
    // StockTransferStatus.CONTAINER_SEALING_ERROR
    backgroundColor: '#FF00003A',
  },
  statusCode6: {
    // StockTransferStatus.SENDING
    backgroundColor: '#FFC547E0',
  },
};
const useRowStyles = makeStyles(rowStyles);

const StockTransferItemRow = ({ task, onRowClick, highlightAddition = false }: Props) => {
  const classes: any = useRowStyles();

  return (
    <Fragment>
      <TableRow
        className={`${classes.destinationRow} ${highlightAddition && classes.highlightRowAdditionBorderTop} ${
          classes['statusCode' + task.status]
        }`}
        onClick={onRowClick}
        data-cy="stock-transfer-form-item-table-row"
      >
        <TableCell>
          <span className={classes.bold}>{task.containerCode}</span>
          {`: ${task.destinationBusinessUnit.number} - ${task.destinationBusinessUnit.name}`}
          {[StockTransferStatus.CONTAINER_SEALING, StockTransferStatus.CONTAINER_SEALING_ERROR].includes(task.status)
            ? ' - Container abschliessen'
            : ''}
        </TableCell>
        <TableCell className={classes.bold} align="right" data-cy="stock-transfer-form-item-table-cell-qty">
          {task.type === 'item' ? task.scannedQuantity : ''}
          {task.type === 'sum' ? <b>Gesamt: {task.scannedQuantity}</b> : ''}
        </TableCell>
      </TableRow>
      {task.item && (
        <TableRow
          className={`${classes.itemRow} ${highlightAddition && classes.highlightRowAdditionBorderSides} ${
            classes['statusCode' + task.status]
          }`}
          onClick={onRowClick}
          data-cy="stock-transfer-form-item-table-row-item"
        >
          <TableCell className={classes.itemCell} colSpan={2}>{`${task.item.customItemNo || task.item.itemNo} - ${
            task.item.description
          }`}</TableCell>
        </TableRow>
      )}
      {task.error && (
        <TableRow
          className={`${classes.itemRow} ${highlightAddition && classes.highlightRowAdditionBorderSides} ${
            classes['statusCode' + task.status]
          }`}
          data-cy="stock-transfer-form-item-table-row-error"
        >
          <TableCell className={classes.errorCell} colSpan={2}>
            {task.error}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default StockTransferItemRow;
