import React, { useEffect } from 'react';
import { ItemInventoryLocalTask, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import Summary from './Summary';
import ZoneCountList from './ZoneCountList';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';

interface ZoneCountProps {
  tasks: Array<[number, ShelfListing]>;
}

const ZoneCount = (props: ZoneCountProps) => {
  const [tasks, setTasks] = React.useState([] as ItemInventoryLocalTask[]);
  const { tasks: tasksByShelf } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Zonen Zählung'));
    dispatch(setNavigationUpwardTarget(`/itemInventory`));
  }, [dispatch]);

  useEffect(() => {
    const allTasks = [] as ItemInventoryLocalTask[];
    tasksByShelf.forEach(([, shelf]) => allTasks.push(...shelf.tasks));
    setTasks(allTasks);
  }, [tasksByShelf]);

  return (
    <Switch>
      <Route path="/itemInventory/zoneCount/summary" exact render={() => <Summary tasks={tasks} />} />
      <Route render={() => <ZoneCountList tasks={tasks} />} />
    </Switch>
  );
};

export default ZoneCount;
