import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import { BrandTasksInterface, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';

interface BrandListProps {
  tasks: BrandTasksInterface;
}

export const getShelfName = (shelf: ShelfListing) => {
  if (shelf.name) {
    return shelf.name;
  }

  // Artikel ohne Regalzuordnung sind Aktionsware
  if (shelf.number === -1) {
    return 'Aktionsware';
  }

  return `Regal ${shelf.number}`;
};

export const ShelfName = ({ shelf }: { shelf: ShelfListing }) => {
  const shelfName = useMemo(() => {
    return getShelfName(shelf);
  }, [shelf]);

  return <>{shelfName}</>;
};

const BrandList = ({ tasks, history }: BrandListProps & RouteComponentProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [brands, setBrands] = useState<{ name: string; taskCount: number }[]>([]);
  const dispatch = useDispatch();

  const navigateToShelf = (brandName: string) => history.push(`/itemInventory/type/brands/${brandName}`);

  React.useEffect(() => {
    dispatch(setTitle('Inventur Markenübersicht'));
    dispatch(setNavigationUpwardTarget(`/itemInventory`));

    const brandNames = Object.keys(tasks);
    brandNames.sort();
    const brands: { name: string; taskCount: number }[] = [];
    for (const brandName of brandNames) {
      brands.push({ name: brandName, taskCount: tasks[brandName].length });
    }
    setBrands(brands);
    setLoading(false);
  }, [tasks]);

  if (isLoading) {
    return (
      <Container>
        <Box mt={8} textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (brands.length === 0) {
    return (
      <Container>
        <Alert severity="info">
          <AlertTitle>Nichts zu tun</AlertTitle>
          Es stehen aktuell keine Inventuraufgaben aus.
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <List component="nav">
            {brands.map((brand, key) => (
              <ListItem key={key} button color="primary" onClick={() => navigateToShelf(brand.name)}>
                <ListItemText primary={brand.name} />
                <ListItemSecondaryAction>
                  <Avatar>{brand.taskCount > 99 ? '99+' : brand.taskCount}</Avatar>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default connect()(withRouter(BrandList)) as React.ComponentType<BrandListProps>;
