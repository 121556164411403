import { Paper } from '@material-ui/core';
import { createStyles, withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import React from 'react';

const styles = (): StyleRules =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: 'auto',
      overflow: 'hidden',
      borderRadius: '8px',
    },
    contentWrapper: {
      margin: '10px 10px',
    },
  });

export interface ContentProps extends WithStyles<typeof styles> {
  children: React.ReactElement<any>;
}

const Content = (props: ContentProps) => {
  const { children, classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>{children}</div>
    </Paper>
  );
};

export default withStyles(styles)(Content);
