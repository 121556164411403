import * as types from './types';

export const config = (state = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case types.SET_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
