import { useEffect, useState } from 'react';

const QR_CODE_IS_EMPTY_ERROR = 'qr_code_is_empty';
const QR_CODE_IS_INVALID_ERROR = 'qr_code_is_invalid';
const QR_CODE_CANT_BE_SCANNED_MULTIPLE_TIMES_ERROR = 'qr_code_cant_be_scanned_multiple_times';

type QrCodeInformationType = {
  id: string | null;
  type: string | null;
  error: string | null;
};

interface UseQrCodeScanOptionsInterface {
  allowScansAfterwards?: boolean;
}

interface UseQrCodeScanInterface {
  (
    initialQrCode?: string,
    options?: UseQrCodeScanOptionsInterface
  ): [
    qrCodeInformation: QrCodeInformationType,
    scanQrCode: (qrCode: string) => void,
    getQrErrorNotification: () => string,
  ];
}

const useQrCodeScan: UseQrCodeScanInterface = (initialQrCode = '', options = {}) => {
  const { allowScansAfterwards = true } = options;

  const [isAllowedToScanAfterwards, setIsAllowedToScanAfterwards] = useState<boolean>(true);
  const [qrCodeInformation, setQrCodeInformation] = useState<QrCodeInformationType>({
    id: null,
    type: null,
    error: null,
  });

  // Check for the first three characters followed by the objectID
  const qrCodeRegExp = new RegExp(/^([A-Z]{3});([0-9a-fA-F]{24}$)/);

  useEffect(() => {
    if (initialQrCode) {
      scanQrCode(initialQrCode);
      if (!allowScansAfterwards) {
        setIsAllowedToScanAfterwards(false);
      }
    }
  }, [initialQrCode]);

  const scanQrCode = (qrCode: string) => {
    if (!qrCode) {
      setQrCodeInformation((prevState) => ({ ...prevState, error: QR_CODE_IS_EMPTY_ERROR }));

      return;
    }

    if (!isAllowedToScanAfterwards) {
      setQrCodeInformation((prevState) => ({ ...prevState, error: QR_CODE_CANT_BE_SCANNED_MULTIPLE_TIMES_ERROR }));

      return;
    }

    const qrCodeMatch = qrCode.match(qrCodeRegExp);

    if (!qrCodeMatch) {
      setQrCodeInformation((prevState) => ({ ...prevState, error: QR_CODE_IS_INVALID_ERROR }));

      return;
    }

    setQrCodeInformation((prevState) => ({ ...prevState, id: qrCodeMatch[2], type: qrCodeMatch[1] }));

    if (!allowScansAfterwards) {
      setIsAllowedToScanAfterwards(false);
    }
  };

  const getQrErrorNotification = () => {
    let errorMessage = 'Unbekannter QR Code Fehler';

    switch (qrCodeInformation.error) {
      case QR_CODE_IS_EMPTY_ERROR:
        errorMessage = 'Der QR Code ist leer';
        break;
      case QR_CODE_IS_INVALID_ERROR:
        errorMessage = 'Der QR Code ist ungültig';
        break;
      case QR_CODE_CANT_BE_SCANNED_MULTIPLE_TIMES_ERROR:
        errorMessage = 'Ein weiterer Scan eines QR Codes ist nicht möglich';
        break;
    }

    return errorMessage;
  };

  return [qrCodeInformation, scanQrCode, getQrErrorNotification];
};

export default useQrCodeScan;
