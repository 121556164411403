import React from 'react';
import { Route, Switch } from 'react-router';
import IncomingTransferList from './components/IncomingTransferList';
import IncomingTransferDetail from './components/IncomingTransferDetail';

const IncomingTransferRouter = () => (
  <Switch>
    <Route path="/stock-transfer/receive/:id" component={IncomingTransferDetail} />
    <Route component={IncomingTransferList} />
  </Switch>
);

export default IncomingTransferRouter;
