import { Dispatch } from 'redux';
import types from './types';
import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';

export const setShelfTasks = (shelves: Array<[number, ShelfListing]>) => {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      type: types.SET_SHELF_TASKS,
      payload: shelves,
    });
  };
};
