import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import { ItemInventoryStockTake, StockTakeStatus } from '../../interfaces/ItemInventoryInterface';
import { DB } from '../../modules/app/client/dataProvider/storageClient';
import AbstractSyncLoader, { RunResult, RunResultStatus } from '../../modules/common/serviceWorker/AbstractSyncLoader';
import WebWorkerConfig from '../config';

interface StockTakesByItem {
  [itemId: string]: ItemInventoryStockTake[];
}

interface QueryVariables {
  businessUnitId: string;
  transactionId: string;
  items: Array<{
    itemId: string;
    reason: string;
    stockTakes: Array<{
      timestamp: string;
      amount: number;
      location: string;
    }>;
  }>;
}

export const bulkTaskSetStatus = async (itemIds: string[], status: StockTakeStatus) => {
  // update stock take status in DB
  await DB.transaction('rw', DB.stockTakeResults, () => {
    for (const itemId of itemIds) {
      DB.stockTakeResults.where('itemId').equals(itemId).modify({
        status,
      });
    }
  });
};

/**
 * Removes stock takes from the database which are older than today midnight
 */
export const removeOutdatedStockTakes = async () => {
  if (WebWorkerConfig.getConfig()?.stockTake?.skipRemoveOutdatedStockTakes) {
    return;
  }

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  await DB.stockTakeResults.where('timestamp').below(startOfDay.getTime()).delete();
};

export default class StockTakeResultsProcessor extends AbstractSyncLoader {
  protected batchSize = 1000;
  private variables!: QueryVariables;
  private query = gql`
    mutation ($businessUnitId: ID!, $items: [StockTakeItemInput!]!, $transactionId: ID!) {
      pushStockTakes(businessUnitId: $businessUnitId, transactions: [{ items: $items, id: $transactionId }]) {
        successIds
      }
    }
  `;

  constructor() {
    super('stockTakeResults');
  }

  public async init(): Promise<void> {
    await super.init();

    this.variables = {
      transactionId: '',
      businessUnitId: this.session.businessUnitGroup.unit.id,
      items: [],
    };
  }

  protected async run(): Promise<RunResult> {
    await removeOutdatedStockTakes();

    // get local StockTakeResults that are ready
    const stockTakes = await DB.stockTakeResults.where('status').equals(StockTakeStatus.READY).toArray();

    if (stockTakes.length === 0) {
      return {
        status: RunResultStatus.FINISHED,
        additionalInfo: {
          Entwurf: await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.DRAFT)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
          'Zu senden': 0,
          'Wird gesendet': await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.SENDING)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
          Gesendet: await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.SENT)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
        },
      };
    }

    // lock results for export
    await bulkTaskSetStatus(
      this.variables.items.map((item) => item.itemId),
      StockTakeStatus.SENDING
    );

    // merge by item
    const stockTakesByItem: StockTakesByItem = stockTakes.reduce((carry, stockTake) => {
      if (!Object.prototype.hasOwnProperty.call(carry, stockTake.itemId)) {
        carry[stockTake.itemId] = [];
      }

      carry[stockTake.itemId].push(stockTake);

      return carry;
    }, {} as StockTakesByItem);

    // limit to batch size
    this.variables.transactionId = uuidv4();

    //TODO WIP
    // const lastStockItem = ((Object.entries(stockTakesByItem).at(-1) || []).at(-1) || []).at(
    //   -1
    // ) as ItemInventoryStockTake;
    // if (lastStockItem) {
    //   const stockTakeUniqueIdString =
    //     lastStockItem.amount +
    //     lastStockItem.itemId +
    //     lastStockItem.location +
    //     lastStockItem.reason +
    //     lastStockItem.timestamp;
    //   const STOCK_TAKE_NAMESPACE = '8aa5c42b-da4a-442a-9570-e87cf89007f7';
    //   this.variables.transactionId = uuidv5(stockTakeUniqueIdString, STOCK_TAKE_NAMESPACE);
    // }

    this.variables.items = Object.entries(stockTakesByItem)
      // do not limit, we want all results in a single transaction (?)
      // .slice(0, this.batchSize)
      .map(([itemId, stockTakes]) => {
        return {
          itemId,
          reason: stockTakes[0].reason, // Reason should always be the same, so we can just grab the first array element
          stockTakes: stockTakes.map((stockTake) => {
            return {
              timestamp: new Date(stockTake.timestamp).toISOString(),
              amount: stockTake.amount,
              location: stockTake.location,
            };
          }),
        };
      });

    try {
      const response: any = await this.client.query({
        fetchPolicy: 'no-cache',
        query: this.query,
        variables: this.variables,
        context: {
          fetchOptions: {
            signal: AbortSignal.timeout(600000),
          },
        },
      });

      if (response && response.data.pushStockTakes.successIds.length > 0) {
        await bulkTaskSetStatus(
          this.variables.items.map((item) => item.itemId),
          StockTakeStatus.SENT
        );
      }

      return {
        status: RunResultStatus.FINISHED,
        additionalInfo: {
          Entwurf: await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.DRAFT)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
          'Zu senden': await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.READY)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
          'Wird gesendet': await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.SENDING)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
          Gesendet: await DB.stockTakeResults
            .where('status')
            .equals(StockTakeStatus.SENT)
            .toArray()
            .then(StockTakeResultsProcessor.groupByItem)
            .then((v) => v.length),
        },
      };
    } catch (e) {
      // revert status so tasks can be retried
      await bulkTaskSetStatus(
        this.variables.items.map((item) => item.itemId),
        StockTakeStatus.READY
      );

      if (e instanceof Error && e.message.includes('401')) {
        return {
          status: RunResultStatus.UNAUTHORIZED,
        };
      }

      Sentry.captureException(e);
      console.error(e);
      return {
        status: RunResultStatus.ERROR,
        additionalInfo: {
          Entwurf: await DB.stockTakeResults.where('status').equals(StockTakeStatus.DRAFT).count(),
          'Zu senden': await DB.stockTakeResults.where('status').equals(StockTakeStatus.READY).count(),
          'Wird gesendet': await DB.stockTakeResults.where('status').equals(StockTakeStatus.SENDING).count(),
          Gesendet: await DB.stockTakeResults.where('status').equals(StockTakeStatus.SENT).count(),
        },
      };
    }
  }

  private static groupByItem(stockTakes: ItemInventoryStockTake[]) {
    const grouped = stockTakes
      .reduce((carry, stockTake) => {
        if (carry.has(stockTake.itemId)) {
          carry.set(stockTake.itemId, [...carry.get(stockTake.itemId)!, stockTake]);

          return carry;
        }

        carry.set(stockTake.itemId, [stockTake]);

        return carry;
      }, new Map<string, ItemInventoryStockTake[]>())
      .values();

    return Array.from(grouped);
  }
}
