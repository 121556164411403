import { v4 as uuidv4 } from 'uuid';
import { DB } from '../modules/app/client/dataProvider/storageClient';
import { ItemCollectorTaskInterface, ItemCollectorTaskType } from '../interfaces/ItemCollectorTaskInterface';

const createTask = async (task: ItemCollectorTaskInterface) => DB.itemCollectorTasks.add({ ...task, id: uuidv4() });

const updateTask = async (id: string, changes: ItemCollectorTaskInterface) => DB.itemCollectorTasks.update(id, changes);

const removeTask = async (id: string) => await DB.itemCollectorTasks.delete(id);

const getTasksByType = async (type: ItemCollectorTaskType = ItemCollectorTaskType.DEFAULT_ITEM_COLLECTION) => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  await DB.itemCollectorTasks.where('timestamp').belowOrEqual(startOfDay).delete();

  return DB.itemCollectorTasks.where('type').equals(type).toArray() ?? [];
};

const getTaskById = async (id: string) => DB.itemCollectorTasks.get({ id });

const getTaskItems = async (id: string) => {
  const task = await getTaskById(id);

  return task ? task.items : [];
};

const resetTaskItems = async (id: string) => {
  await DB.itemCollectorTasks
    .where('id')
    .equals(id)
    .modify((task) => {
      task.items = [];
    });
};

const updateTaskItemQty = async (id: string, itemId: string, qty: number, override?: boolean) => {
  const item = await DB.items.where('id').equals(itemId).first();

  if (item) {
    await DB.itemCollectorTasks
      .where('id')
      .equals(id)
      .modify((task) => {
        let itemIsAlreadySaved = false;

        task.items.forEach((taskItem) => {
          if (taskItem.id === itemId) {
            if (override) {
              taskItem.quantity = qty;
            } else {
              taskItem.quantity += qty;
            }

            itemIsAlreadySaved = true;
          }
        });

        !itemIsAlreadySaved &&
          task.items.push({
            ...item,
            timestamp: new Date(),
            quantity: qty,
          });
      });
  }
};

const removeTaskItem = async (id: string, itemIds: string[]) => {
  await DB.itemCollectorTasks
    .where('id')
    .equals(id)
    .modify((task) => {
      task.items.forEach(async (item, i) => {
        if (itemIds.includes(item.id)) {
          task.items.splice(i, 1);
        }
      });
    });
};

export {
  createTask,
  updateTask,
  removeTask,
  getTaskById,
  getTaskItems,
  getTasksByType,
  resetTaskItems,
  updateTaskItemQty,
  removeTaskItem,
};
