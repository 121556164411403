import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';

interface Props extends PropsCallbackFunction {
  open: boolean;
  onClose: () => void;
  anchorEl: any;
}

interface PropsCallbackFunction {
  [key: string]: any;

  // TODO: Generic type for future functions
  onDecline?: () => void;
  onReduction?: () => void;
  onQuantityEdit?: () => void;
  onRemoveError?: () => void;
}

const MENU_ENTRIES = [
  {
    label: 'Filialtausch ablehnen',
    icon: <BlockIcon fontSize="small" />,
    callbackName: 'onDecline',
  },
  {
    label: 'Artikelbestand reduzieren',
    icon: <ExposureNeg1Icon fontSize="small" />,
    callbackName: 'onReduction',
  },
  {
    label: 'Mengeneingabe',
    icon: <EditIcon fontSize="small" />,
    callbackName: 'onQuantityEdit',
  },
  {
    label: 'Fehlerhaften Eintrag entfernen',
    icon: <HighlightOffIcon fontSize="small" />,
    callbackName: 'onRemoveError',
  },
];

const ItemRowContextMenu = ({ open, onClose, anchorEl, ...props }: Props) => {
  const menuElements = open
    ? MENU_ENTRIES.map((menuEntry, key) => {
        if (props[menuEntry.callbackName]) {
          return (
            <MenuItem
              onClick={() => props[menuEntry.callbackName]()}
              data-cy="stock-transfer-form-item-table-row-context-menu-entry"
              key={key}
            >
              <ListItemIcon>{menuEntry.icon}</ListItemIcon>
              {menuEntry.label}
            </MenuItem>
          );
        }

        return null;
      }).filter(Boolean)
    : [];

  return (
    <Menu anchorEl={anchorEl} keepMounted open={menuElements.length > 0} onClose={onClose}>
      {menuElements}
    </Menu>
  );
};

export default ItemRowContextMenu;
