import makeStyles from '@material-ui/core/styles/makeStyles';

const useDefaultStyles = makeStyles({
  paper: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  paperSearch: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
});

export default useDefaultStyles;
