import { TouchEventHandler } from 'react';

/**
 * @example <button { ...longPressEvents(() => handle(item)) }>{item}</button>
 */
export default function longPressEvents<T = any>(callback: TouchEventHandler<T>, ms = 2000) {
  let timeout: number | null = null;

  const start = () => (timeout = setTimeout(callback, ms));
  const stop = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return {
    onTouchStart: start,
    onTouchMove: stop,
    onTouchEnd: stop,
  };
}
