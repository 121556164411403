export class WebWorkerConfig {
  private config: any = {
    logo: null,
  };

  public setConfig(config: any) {
    this.config = { ...this.config, ...config };
  }

  public getConfig(): any {
    return this.config;
  }
}

export default new WebWorkerConfig();
