import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import { BrandTasksInterface, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { markTasksAsReady } from '../../redux/modules/stockTake/operations';
import { ReplaceReturnType } from '@meeva/service-client-core/redux/store';
import { ShelfName } from './ShelfList';
import ConfirmDialog from '@meeva/service-client-core/modules/common/components/ConfirmDialog';
import { getConfig } from '@meeva/service-client-core/redux/common/app/selectors';
import { getLocations } from '@meeva/service-client-core/utils/inventoryTaskHelper';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    actionTitle: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    timelineResult: {
      fontWeight: 'bold',
    },
    timelineDotSuccess: {
      backgroundColor: theme.palette.success.light,
    },
    timelineDotWarning: {
      backgroundColor: theme.palette.warning.light,
    },
    loadingModal: {
      display: 'flex',
    },
    loadingSpinner: {
      margin: 'auto',
    },
  });

const StyledTimelineItem = withStyles({
  root: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem);

interface ShelfSummaryRouteProps {
  type: string;
  shelfId?: string;
  brandName?: string;
}

interface ShelfSummaryProps {
  tasks: Array<[number, ShelfListing]> | BrandTasksInterface;
}

interface ShelfSummaryConnectedProps
  extends ShelfSummaryProps,
    RouteComponentProps<ShelfSummaryRouteProps>,
    WithStyles {
  setTitle: typeof setTitle;
  markTasksAsReady: ReplaceReturnType<typeof markTasksAsReady, Promise<void>>;
  config?: any;
}

const ShelfSummary = ({ config, setTitle, ...props }: ShelfSummaryConnectedProps) => {
  const localConfirmTextConfig = config.itemInventory?.confirmDialog?.localConfirmText;
  const [isLoading, setLoading] = React.useState(true);
  const [shelves, setShelves] = React.useState(new Map<number, ShelfListing>());
  const [shelf, setShelf] = React.useState<ShelfListing | null>(null);
  const [locations, setLocations] = React.useState(new Map<string, string>([]));
  const [numTotalCountedArticles, setNumTotalCountedArticles] = React.useState(0);
  const [numTotalArticleCount, setNumTotalArticleCount] = React.useState(0);
  const [numTotalStockDifference, setNumTotalStockDifference] = React.useState(0);
  const [numTotalStockDifferenceItems, setNumTotalStockDifferenceItems] = React.useState(0);
  const [isBackgroundProcessActive, setBackgroundProcessActive] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { tasks, classes, history, markTasksAsReady } = props;
  const { brandName } = props.match.params;
  const shelfId = brandName ? 0 : props.match.params.shelfId;

  const returnToInventoryStart = useCallback(() => {
    history.push('/itemInventory');
  }, [history]);

  const sendStockTakes = useCallback(() => {
    const shelf = shelves.get(Number(shelfId));
    if (!shelf) {
      return;
    }

    setBackgroundProcessActive(true);

    // Aufgaben welche in allen Standorten gezählt wurden
    const locationEntries = Array.from(locations.entries());
    const locationNames = locationEntries.map(([locationName]) => locationName);
    const completedShelfTasks = shelf.tasks.filter((task) => {
      return (
        task.countings.length > 0 &&
        locationNames.every((stageName) =>
          task.countings.some(([stockTakeLocation]) => stockTakeLocation === stageName)
        )
      );
    });

    (async () => {
      await markTasksAsReady(completedShelfTasks);
      setBackgroundProcessActive(false);
      if (brandName) {
        history.push('/itemInventory/type/brands');
      } else {
        history.push('/itemInventory');
      }
    })();
  }, [history, locations, markTasksAsReady, shelfId, shelves]);

  const showShelfDifferences = useCallback(() => {
    if (brandName) {
      history.push(`/itemInventory/type/brands/${brandName}/differences`);
    } else {
      history.push(`/itemInventory/type/${props.match.params.type}/shelf/${shelfId}/differences`);
    }
  }, [history, shelfId]);

  React.useEffect(() => {
    setTitle('Zusammenfassung');

    if (brandName) {
      setLocations(new Map([[brandName, brandName]]));
      dispatch(setNavigationUpwardTarget(`/itemInventory/type/brands/${brandName}`));
    } else {
      const tmpLocations = getLocations();
      setLocations(tmpLocations);

      dispatch(
        setNavigationUpwardTarget(
          `/itemInventory/type/${props.match.params.type}/shelf/${shelfId}/location/${[...tmpLocations.keys()].pop()}`
        )
      );
    }
  }, [setTitle, shelfId]);

  React.useEffect(() => {
    if (tasks instanceof Array) {
      setShelves(new Map(tasks));
    } else if (brandName) {
      const brandTasks = tasks[brandName] || [];
      const brandShelve: [number, ShelfListing][] = [];
      brandShelve.push([
        0,
        {
          name: brandName,
          number: -1,
          taskCount: brandTasks.length,
          tasks: brandTasks,
        },
      ]);
      setShelves(new Map(brandShelve));
    }
    setLoading(false);
  }, [tasks]);

  React.useEffect(() => {
    const shelf = shelves.get(Number(shelfId));
    setShelf(shelf || null);
    if (!shelf) {
      return;
    }

    // Aufgaben welche in allen Standorten gezählt wurden
    const locationEntries = Array.from(locations.entries());
    const locationNames = locationEntries.map(([locationName]) => locationName);
    const completedShelfTasks = shelf.tasks.filter((task) => {
      return (
        task.countings.length > 0 &&
        locationNames.every((stageName) =>
          task.countings.some(([stockTakeLocation]) => stockTakeLocation === stageName)
        )
      );
    });

    setNumTotalArticleCount(shelf.tasks.length);
    setNumTotalCountedArticles(completedShelfTasks.length);

    // Summe gezählter Bestand
    const countedStock = completedShelfTasks.reduce((carry, task) => {
      // Anzahl gezählt über alle Standorte
      const counted = task.countings.reduce((cCarry, [, stockTake]) => cCarry + stockTake.amount, 0);
      const stockDiff = Math.abs((counted - task.item.stockQty) * (task.item.priceInformation?.stock?.price || 0));

      return stockDiff >= 15
        ? task.countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0)
        : task.item.stockQty;
    }, 0);

    // Summe erwarteter Bestand
    const expectedStock = completedShelfTasks.reduce((carry, task) => {
      return carry + task.item.stockQty;
    }, 0);
    setNumTotalStockDifference(countedStock - expectedStock);

    const itemsByStockPriceDiff = completedShelfTasks.filter(({ countings, item }) => {
      const counted = countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0);
      const stockDiff = Math.abs((counted - item.stockQty) * (item.priceInformation?.stock?.price || 0));

      if (stockDiff >= 15) {
        return item;
      }
    });

    setNumTotalStockDifferenceItems(itemsByStockPriceDiff.length);
  }, [shelfId, shelves, locations]);

  if (isLoading) {
    return (
      <Container>
        <Box mt={8} textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <Box mt={1}>
          <Paper>
            <Typography variant="h5" component="h2" className={classes.title}>
              {shelf && <ShelfName shelf={shelf} />}
            </Typography>

            <Timeline align="left">
              <StyledTimelineItem>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotSuccess}>
                    <CheckIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Zu zählen:
                  <div className={classes.timelineResult}>{numTotalArticleCount} Artikel</div>
                </TimelineContent>
              </StyledTimelineItem>
              <StyledTimelineItem>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotSuccess}>
                    <CheckIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Gezählt:
                  <div className={classes.timelineResult}>{numTotalCountedArticles} Artikel</div>
                </TimelineContent>
              </StyledTimelineItem>
              <StyledTimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    className={
                      Math.abs(numTotalStockDifferenceItems) > 0
                        ? classes.timelineDotWarning
                        : classes.timelineDotSuccess
                    }
                  >
                    {Math.abs(numTotalStockDifferenceItems) > 0 ? <WarningIcon /> : <CheckIcon />}
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  Artikel mit Differenz:
                  <div className={classes.timelineResult}>{numTotalStockDifferenceItems} Artikel</div>
                </TimelineContent>
              </StyledTimelineItem>
            </Timeline>
          </Paper>
        </Box>

        <Box mt={1}>
          <Paper>
            <Typography className={classes.actionTitle}>Was möchtest du als nächstes tun?</Typography>
            <List component="nav">
              <ListItem button color="primary" disabled={numTotalStockDifference === 0}>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Differenzen prüfen" onClick={showShelfDifferences} />
              </ListItem>
              <ListItem button color="primary">
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText primary="Inventur fortsetzen" onClick={returnToInventoryStart} />
              </ListItem>
              <ListItem button color="primary" onClick={() => setConfirmDialogOpen(true)}>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Absenden" />
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Modal
          disableBackdropClick
          disableEnforceFocus
          disableAutoFocus
          className={classes.loadingModal}
          open={isBackgroundProcessActive}
          onClose={() => setBackgroundProcessActive(false)}
        >
          <CircularProgress className={classes.loadingSpinner} />
        </Modal>
      </Container>

      <ConfirmDialog
        open={confirmDialogOpen}
        title="Achtung"
        contentText={
          localConfirmTextConfig
            ? 'Sind Sie sicher, dass Sie die Daten absenden möchten?'
            : 'Bestätigung am Computer erforderlich'
        }
        cancelText={localConfirmTextConfig && 'Abbrechen'}
        confirmText="Okay"
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={sendStockTakes}
      />
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    config: getConfig(state),
  }),
  {
    setTitle,
    markTasksAsReady,
  }
)(withRouter(withStyles(styles)(ShelfSummary))) as React.ComponentType<ShelfSummaryProps>;
