import { ItemInterface } from './ItemListInterface';

export enum ItemLabelsCollectionStatus {
  DRAFT,
  READY,
  SENT,
}

export interface ItemLabelInterface extends ItemInterface {
  usedBarcode: string;
  quantity?: number;
  collectionItemId: string;
}

export interface ItemLabelsCollection {
  id: string;
  name: string;
  customName?: string;
  itemLabels: ItemLabelInterface[];
  timestamp: string;
  status: ItemLabelsCollectionStatus;
}
