type QuantityType = number | string | undefined;

export const validateItemQuantity = (
  quantity: QuantityType,
  lowerLimit: number,
  upperLimit: number,
  saleUnitPerPackUnitCount = 1
) => {
  const inputQuantity = Number(quantity);

  if (isNaN(inputQuantity)) {
    return { hasError: true, message: 'Die eingegebene Menge ist ungültig' };
  }

  if (inputQuantity < lowerLimit) {
    return { hasError: true, message: `Die Menge darf nicht kleiner als ${lowerLimit} sein` };
  }

  if (inputQuantity > upperLimit) {
    return { hasError: true, message: `Die Menge darf nicht größer als ${upperLimit} sein` };
  }

  if (inputQuantity * saleUnitPerPackUnitCount > upperLimit) {
    return {
      hasError: true,
      message: `Die EA-Menge (Anzahl VE * VE-Größe) darf nicht größer als ${upperLimit} sein`,
    };
  }

  return { hasError: false, message: '' };
};
