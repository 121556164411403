import { Process } from '../../../interfaces/ItemListInterface';
import * as types from './types';

export const itemsToProcess = (state: Array<Process> = [], action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.ADD_ITEM_PROCESS:
      return state.concat([action.payload]);
    case types.REMOVE_ITEM_PROCESS:
      return state.filter((row) => row.target !== action.payload.target);
    default:
      return state;
  }
};
