import {
  Box,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  setNavigationUpwardTarget,
  setSnack,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { getLocations } from '@meeva/service-client-core/utils/inventoryTaskHelper';
import {
  ItemInventoryLocalTask,
  ItemInventoryStockUpdateMode,
  ShelfListing,
  StockTakeStatus,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { addStockTake } from '../../redux/modules/stockTake/operations';
import { Currency } from '@meeva/service-client-core/modules/common/components/Currency';
import ItemInventoryTaskForm from '../common/ItemInventoryTaskForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  });

interface ShelfDifferencesRouteProps {
  type: string;
  shelfId: string;
}

interface ShelfDifferencesProps {
  tasks: Array<[number, ShelfListing]>;
}

interface ShelfDifferencesConnectedProps
  extends ShelfDifferencesProps,
    RouteComponentProps<ShelfDifferencesRouteProps>,
    WithStyles {
  setTitle: typeof setTitle;
  setSnack: typeof setSnack;
}

// TODO: Mit ShelfDifferences zusammenfügen. Nach selecten der Shelf aus der Route, könnte die ShelfDifferences die TaskDifferences rendern

const TasksDifferences = ({ setTitle, ...props }: ShelfDifferencesConnectedProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [tasks, setTasks] = React.useState([] as ItemInventoryLocalTask[]);
  const [locations, setLocations] = React.useState(new Map<string, string>([]));
  const [tasksWithDifferences, setTasksWithDifferences] = React.useState<ItemInventoryLocalTask[]>([]);
  const [editFormData, setEditFormData] = React.useState<{
    task: ItemInventoryLocalTask;
    location: [string, string];
  } | null>(null);

  const dispatch = useDispatch();
  const { tasks: tasksByShelf, classes, setSnack } = props;
  const { shelfId } = props.match.params;

  useEffect(() => {
    const allTasks = [] as ItemInventoryLocalTask[];
    tasksByShelf.forEach(([, shelf]) => allTasks.push(...shelf.tasks));
    setTasks(allTasks);
    setLoading(false);
  }, [tasksByShelf]);

  const doEditTask = React.useCallback(
    (task: ItemInventoryLocalTask, location: string) => {
      setEditFormData({ task, location: [location, locations.get(location)!] });
    },
    [locations]
  );

  const closeTaskForm = React.useCallback(() => {
    setEditFormData(null);
  }, [setEditFormData]);

  const modifyItemStock = React.useCallback(
    (task: ItemInventoryLocalTask, location: string, mode: ItemInventoryStockUpdateMode, value: number) =>
      dispatch(
        addStockTake(mode, {
          amount: value,
          itemId: task.itemId,
          timestamp: Date.now(),
          location,
          reason: task.reason,
          status: StockTakeStatus.DRAFT,
        })
      ),
    [addStockTake, setSnack]
  );

  const updateTaskCounting = React.useCallback(
    (value?: number) => {
      if (value === undefined || !editFormData) {
        closeTaskForm();

        window.setTimeout(() => {
          setSnack({
            severity: 'info',
            autoHideDuration: 3000,
            text: 'Es wurde kein Angabe erfasst',
          });
        }, 1);
        return;
      }

      modifyItemStock(editFormData.task, editFormData.location[0], 'replace', value);
      closeTaskForm();
    },
    [closeTaskForm, modifyItemStock, editFormData, setSnack]
  );

  useEffect(() => {
    setTitle('Differenzen');
    setLocations(getLocations());
  }, [setTitle]);

  useEffect(() => {
    dispatch(setNavigationUpwardTarget(`/itemInventory/type/${props.match.params.type}/summary`));
  }, []);

  useEffect(() => {
    const diffTasks = tasks.filter((task) => {
      if (task.countings.length === 0) {
        return false;
      }

      const counted = task.countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0);
      const stockDiff = (counted - task.item.stockQty) * (task.item.priceInformation?.stock?.price || 0);

      return Math.abs(stockDiff) >= 15;
    });

    setTasksWithDifferences(diffTasks);
    setLoading(false);
  }, [shelfId, tasks]);

  useEffect(() => {
    dispatch(showGlobalProgressModal(isLoading));
  }, [isLoading]);

  if (isLoading) {
    return (
      <Container>
        <Box mt={8} textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <Typography variant="h5" component="h2" className={classes.title}>
            Freies Scannen
          </Typography>

          <List dense>
            {tasksWithDifferences.map((task, taskIndex) => (
              <React.Fragment key={taskIndex}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={task.item.description}
                    secondary={
                      <List dense>
                        {Array.from(locations.entries()).map(([locationName, locationTitle]) => {
                          return (
                            <ListItem button key={locationName} onClick={() => doEditTask(task, locationName)}>
                              <ListItemText
                                primary={locationTitle}
                                secondary={
                                  task.countings.find((counting) => counting[0] === locationName)?.[1].amount || 0
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => doEditTask(task, locationName)}>
                                  <EditIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}

                        <ListItem>
                          <ListItemText
                            primary="Differenz"
                            secondary={
                              <Currency
                                value={
                                  (task.countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0) -
                                    task.item.stockQty) *
                                  (task.item.priceInformation?.stock?.price || 0)
                                }
                              />
                            }
                          />
                        </ListItem>
                      </List>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" key={`${taskIndex}divider`} />
              </React.Fragment>
            ))}
          </List>

          {editFormData !== null && (
            <ItemInventoryTaskForm
              task={editFormData.task}
              inventoryLocation={editFormData.location}
              onClose={closeTaskForm}
              onSave={updateTaskCounting}
            />
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default connect(null, {
  setTitle,
  setSnack,
})(withRouter(withStyles(styles)(TasksDifferences))) as React.ComponentType<ShelfDifferencesProps>;
