import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import { IncomingTransferStatus } from '@meeva/service-client-core/interfaces/IncomingTransferInterface';

export const getEntries = async () => (await DB.incomingTransfers.toArray()) ?? [];

export const getEntryById = async (id: string) => DB.incomingTransfers.get({ id });

export const setTransfersReady = (ids: string[]): Promise<number> => {
  return DB.incomingTransfers
    .filter((transfer) => ids.includes(transfer.id))
    .and((transfer) => transfer.status === IncomingTransferStatus.DRAFT)
    .modify((transfer) => {
      transfer.status = IncomingTransferStatus.READY;
    });
};
