import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { setTitle } from '../../../redux/interface/actions';
import { getItems } from '../../../redux/items/list/operations';
import ScanInput from './ScanInput';
import { ItemInterface } from '../../../interfaces/ItemListInterface';
import { getConfig } from '../../../redux/common/app/selectors';

const styles = () =>
  createStyles({
    paper: {
      padding: '5px',
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
    },
  });

const List = (props: any) => {
  const { classes, config, getItems, setTitle } = props;
  const customItemNoConfig = config.item?.customItemNo;

  const [itemRows, setItemRows] = React.useState([]);
  const [itemCount, setItemCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  const updateItems = useCallback(async () => {
    const items = await getItems(rowsPerPage, page * rowsPerPage);

    setItemRows(items.rows);
    setItemCount(items.total);
  }, [getItems, page, rowsPerPage, setItemRows, setItemCount]);

  useEffect(() => {
    setTitle(`Artikel`);
  }, [setTitle]);

  useEffect(() => {
    updateItems();
  }, [page, updateItems]);

  return (
    <Container>
      <Paper className={classes.paper}>
        <ScanInput />
        <TableContainer style={{ maxWidth: '100%' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {!customItemNoConfig?.hideItemNo && <TableCell>Art.Nr.</TableCell>}
                {customItemNoConfig?.show && <TableCell>{customItemNoConfig?.label || 'Art.Nr.'}</TableCell>}
                <TableCell>Bezeichnung</TableCell>
                <TableCell>Bestand</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemRows.length === 0 ? (
                <TableRow key={0}>
                  <TableCell align={'center'} colSpan={3}>
                    Keine Artikel geladen
                  </TableCell>
                </TableRow>
              ) : (
                itemRows.map((row: ItemInterface) => (
                  <TableRow key={row.id}>
                    {!customItemNoConfig?.hideItemNo && (
                      <TableCell component="th" scope="row">
                        {row.itemNo}
                      </TableCell>
                    )}
                    {customItemNoConfig?.show && (
                      <TableCell component="th" scope="row">
                        {row.customItemNo}
                      </TableCell>
                    )}
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="right">{row.stockQty}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={'Zeilen:'}
          component="div"
          count={itemRows.length === 0 ? 0 : itemCount}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => setRowsPerPage(Number(event.target.value) || 10)}
        />
      </Paper>
    </Container>
  );
};

export default connect(
  (state) => ({
    config: getConfig(state),
  }),
  {
    getItems,
    setTitle,
  }
)(withStyles(styles)(List));
