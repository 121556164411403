import * as Sentry from '@sentry/react';
import { SessionQueryBusinessUnitGroupInterface, SessionQueryResult } from '../../modules/app/client/authProvider';
import * as types from './types';

export const authenticating = (state = false, action: { type: string; inProgress: boolean }) => {
  switch (action.type) {
    case types.AUTHENTICATING:
      return action.inProgress;
    default:
      return state;
  }
};

export const authenticated = (state = false, action: { type: string }) => {
  switch (action.type) {
    case types.AUTHENTICATE:
      return true;
    case types.UNAUTHENTICATE:
      return false;
    default:
      return state;
  }
};

export const sessionInfo = (state = null, action: { type: string; payload: SessionQueryResult | null }) => {
  switch (action.type) {
    case types.SESSION_INFO:
      if (action.payload === null) {
        Sentry.configureScope((scope) => {
          scope.setUser({});
        });

        return null;
      }

      Sentry.configureScope((scope) => {
        if (action.payload) {
          scope.setUser({
            username: action.payload.username,
            ip_address: action.payload.remoteAddress,
          });
          scope.setTag('businessUnitId', action.payload.businessUnitGroup.unit.id);
          scope.setTag('businessUnitNumber', action.payload.businessUnitGroup.unit.number.toString());
        }
      });

      return { ...action.payload };

    default:
      return state;
  }
};

export const availableBusinessUnitGroups = (
  state: SessionQueryBusinessUnitGroupInterface[] = [],
  action: { type: string; payload: SessionQueryBusinessUnitGroupInterface[] }
) => {
  switch (action.type) {
    case types.AUTHENTICATE_BUSINESS_UNIT_GROUP:
      return action.payload;
    case types.RESET_BUSINESS_UNIT_GROUP:
      return [];
    default:
      return state;
  }
};
