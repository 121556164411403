import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ItemInventoryLocalTask } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { itemDisplayNumber } from '@meeva/service-client-core';

interface ItemInventoryTaskItemProps {
  inventoryLocation: string;
  task: ItemInventoryLocalTask;
  openMenu?: (target: EventTarget, task: ItemInventoryLocalTask) => void;
  onClick: (target: EventTarget, task: ItemInventoryLocalTask) => void;
}
interface ItemInventoryTaskItemConnectedProps extends ItemInventoryTaskItemProps, RouteComponentProps {}

const ItemInventoryTaskItem = ({ task, inventoryLocation, openMenu }: ItemInventoryTaskItemConnectedProps) => {
  const openItemMenu = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (openMenu) {
        openMenu(e.target, task);
      }
    },
    [openMenu, task]
  );

  const amountInThisLocation = React.useMemo(() => {
    const stockTakesInThisLocation = task.countings.filter((stockTake) => stockTake[0] === inventoryLocation);
    if (stockTakesInThisLocation.length === 0) {
      return null;
    }

    const amountInThisLocation = stockTakesInThisLocation.reduce((carry, stockTake) => {
      return carry + stockTake[1].amount;
    }, 0);

    return <React.Fragment>, gezählt: {amountInThisLocation}</React.Fragment>;
  }, [task, inventoryLocation]);

  const itemNumber = itemDisplayNumber(task.item);
  return (
    <ListItem button color="primary" onClick={openItemMenu} disabled={!task.item.posIdentities}>
      <ListItemText
        primary={task.item.description}
        secondary={
          <React.Fragment>
            {itemNumber.label}: {itemNumber.value}
            {amountInThisLocation}
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="more" onClick={openItemMenu} disabled={!task.item.posIdentities}>
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default connect()(withRouter(ItemInventoryTaskItem)) as React.ComponentType<ItemInventoryTaskItemProps>;
