import { DB } from '../../../modules/app/client/dataProvider/storageClient';

export const getItems =
  (limit = 100, offset = 0) =>
  async () => {
    const totalResult = await DB.items.count();

    return {
      total: totalResult,
      rows: await DB.items.offset(offset).limit(limit).toArray(),
    };
  };
