import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  setNavigationUpwardTarget,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import {
  BrandTasksInterface,
  ItemInventoryLocalTask,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import ItemInventoryTaskList from '../common/ItemInventoryTaskList';

interface brandTasksRouteProps {
  type: string;
  brandName: string;
  inventoryLocationName?: string;
}

interface BrandTasksConnectedProps extends BrandTasksProps, RouteComponentProps<brandTasksRouteProps> {
  setTitle: typeof setTitle;
}

interface BrandTasksProps {
  tasks: BrandTasksInterface;
  bulkSetZeroAllowed?: boolean;
}

const BrandTasks = ({ setTitle, ...props }: BrandTasksConnectedProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [brandTasks, setBrandTasks] = React.useState<ItemInventoryLocalTask[]>([]);
  const [inventoryLocation, setInventoryLocation] = React.useState<[string, string]>(['', '']);
  const { history, tasks } = props;
  const { brandName } = props.match.params;
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(`Marke ${brandName}`);
    dispatch(setNavigationUpwardTarget('/itemInventory/type/brands'));
  }, [setTitle, brandName]);

  useEffect(() => {
    setBrandTasks(tasks[brandName]);
    setInventoryLocation([brandName, brandName]);

    setLoading(false);
  }, [tasks, brandName]);

  const onTaskListComplete = () => history.push(`/itemInventory/type/brands/${brandName}/summary`);

  useEffect(() => {
    dispatch(showGlobalProgressModal(isLoading));
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <ItemInventoryTaskList
      shelfId=""
      tasks={brandTasks}
      inventoryLocation={inventoryLocation}
      onListComplete={onTaskListComplete}
    />
  );
};

export default connect(null, {
  setTitle,
})(withRouter(BrandTasks)) as React.ComponentType<BrandTasksProps>;
