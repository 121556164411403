import { ThunkDispatch } from 'redux-thunk';
import {
  sendMessageToDatabaseWorker,
  setSnack,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import { setShelfTasks } from './actions';
import InventoryTasksLoader from '@meeva/service-client-core/webworker/serviceWorker/InventoryTasksLoader';
import { setActiveEventsByInstruction } from '@meeva/service-client-core/redux/event/operations';
import {
  ItemInventoryLocalTask,
  ItemInventoryStockTake,
  ItemInventoryStockUpdateMode,
  ShelfListing,
  StockTakeStatus,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { removeOutdatedStockTakes } from '@meeva/service-client-core/webworker/serviceWorker/StockTakeResultsProcessor';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import { getShelfTasks } from './selectors';
import {
  REASON_MISSING_ITEM_ANALYSIS,
  REASON_FREE_INVENTORY,
  REASON_FREE_INVENTORY_ZONE,
  REASON_MANUAL,
} from './types';
import { AppMode } from '@meeva/service-client-core/redux/appBehavior/appModeSlice';

export const addStockTake =
  <T extends ItemInventoryStockTake | Array<ItemInventoryStockTake>>(
    mode: ItemInventoryStockUpdateMode,
    stockTake: T
  ) =>
  async (dispatch: ThunkDispatch<any, null, any>) => {
    dispatch(showGlobalProgressModal(true));

    // send message to web worker for processing
    dispatch(
      sendMessageToDatabaseWorker(
        'itemInventory/addStockTake',
        {
          mode,
          stockTake,
        },
        async (result: any) => {
          const shelfTasks: [number, ShelfListing][] = (await fetchShelfTasks()) as any;
          dispatch(setShelfTasks(shelfTasks));
          dispatch(showGlobalProgressModal(false));

          if (mode === 'reset' || result === undefined) {
            dispatch(
              setSnack({
                severity: 'success',
                autoHideDuration: 3000,
                text: `Der Inventur-Bestand wurde zurückgesetzt`,
              })
            );
          } else {
            if (Array.isArray(result)) {
              dispatch(
                setSnack({
                  severity: 'success',
                  autoHideDuration: 3000,
                  text: `${result.length} Artikel wurden aktualisiert`,
                })
              );
            } else {
              dispatch(
                setSnack({
                  severity: 'success',
                  autoHideDuration: 3000,
                  text: `Der Bestand wurde auf ${result.amount} aktualisiert`,
                })
              );
            }
          }
        }
      )
    );
  };

export const markTasksAsReady =
  (tasks: ItemInventoryLocalTask[]) => async (dispatch: ThunkDispatch<any, null, any>) => {
    // update stock take status in DB
    await DB.transaction('rw', DB.stockTakeResults, DB.inventoryTasks, () => {
      for (const task of tasks) {
        if (task.reason === REASON_FREE_INVENTORY || task.reason === REASON_FREE_INVENTORY_ZONE) {
          DB.inventoryTasks.delete(task.id);
        }
        DB.stockTakeResults
          .where('itemId')
          .equals(task.itemId)
          .and(({ status }) => status === StockTakeStatus.DRAFT)
          .modify({ status: StockTakeStatus.READY });
      }
    });

    await InventoryTasksLoader.updateShelves(tasks);

    // update tasks for UI
    const shelfTasks: [number, ShelfListing][] = (await fetchShelfTasks()) as any;
    dispatch(setShelfTasks(shelfTasks));

    // notify worker
    await dispatch(triggerBackgroundSync('stockTakeResults', true));

    dispatch(
      setSnack({
        text: 'Die Inventurbestände werden nun im Hintergrund übertragen',
        autoHideDuration: 5000,
        severity: 'success',
      })
    );
  };

export const addTasksIfNotExists =
  (...tasks: ItemInventoryLocalTask[]) =>
  (dispatch: ThunkDispatch<any, null, any>, getState: () => any) => {
    const currentShelfTasks = getShelfTasks(getState());

    return dispatch(
      addTasks({
        tasks: tasks.filter(
          (task) =>
            !currentShelfTasks.some(([, shelf]) =>
              shelf.tasks.find(({ itemId, reason }) => itemId === task.itemId && reason === task.reason)
            )
        ),
      })
    );
  };

export const addTasks =
  ({ tasks, updateShelves = true }: { tasks: ItemInventoryLocalTask[]; updateShelves?: boolean }) =>
  async (dispatch: ThunkDispatch<any, null, any>) => {
    await DB.inventoryTasks.bulkPut(tasks);

    await InventoryTasksLoader.updateShelves(tasks);

    if (updateShelves) {
      const shelfTasks: [number, ShelfListing][] = (await fetchShelfTasks()) as any;
      dispatch(setShelfTasks(shelfTasks));
    }
  };

export const removeTasks =
  (tasks: ItemInventoryLocalTask[], refreshShelfs = true) =>
  async (dispatch: ThunkDispatch<any, null, any>) => {
    await DB.inventoryTasks.bulkDelete(tasks.map((task) => task.id));
    await InventoryTasksLoader.updateShelves(tasks);

    if (refreshShelfs) {
      const shelfTasks: [number, ShelfListing][] = (await fetchShelfTasks()) as any;
      dispatch(setShelfTasks(shelfTasks));
    }
  };

export const removeTasksIfEmpty =
  (tasks: ItemInventoryLocalTask[], refreshShelfs = true) =>
  async (dispatch: ThunkDispatch<any, null, any>) => {
    const emptyTasksIdx = await Promise.all(
      tasks.map(async (task) => {
        return DB.stockTakeResults
          .where('itemId')
          .equals(task.itemId)
          .and(({ status }) => status === StockTakeStatus.DRAFT)
          .count();
      })
    );

    const emptyTasks = tasks.filter((_v, index) => emptyTasksIdx[index] === 0);
    if (emptyTasks.length > 0) {
      await dispatch(removeTasks(emptyTasks, refreshShelfs));
    }
  };

export const fetchShelfTasks = async () => {
  await removeOutdatedStockTakes();

  return DB.transaction('r', DB.inventoryTasksByShelves, DB.stockTakeResults, async () => {
    // Regale abrufen
    const shelfData: [number, ShelfListing][] = (await DB.inventoryTasksByShelves.orderBy('number').toArray()).map(
      (shelf) => {
        return [
          shelf.number,
          {
            name: shelf.name,
            number: shelf.number,
            taskCount: shelf.tasks.length,
            tasks: shelf.tasks.map((task) => {
              return {
                ...task,
                countings: [],
              };
            }),
          },
        ];
      }
    );

    const shelves = new Map<number, ShelfListing>(shelfData);

    // Aufgaben in den Regalen parallel ausfiltern wenn bereits abgearbeitet
    const shelfFilter: Promise<any>[] = [];
    shelves.forEach((shelf) => {
      shelfFilter.push(
        (async (shelf) => {
          const itemIds = shelf.tasks.map((task) => task.itemId);
          const itemCountings = await DB.stockTakeResults.where('itemId').anyOf(itemIds).toArray();
          const itemsReady = itemCountings
            .filter((stockTake) => stockTake.status !== StockTakeStatus.DRAFT)
            .map((stockTake) => stockTake.itemId);

          // Einträge entfernen für die bereits eine Zählung existiert. Außer Freies Scannen, artikel immer anzeigen
          shelf.tasks = shelf.tasks.filter(
            (task) =>
              (!itemsReady.includes(task.itemId) ||
                task.reason === REASON_FREE_INVENTORY ||
                task.reason === REASON_FREE_INVENTORY_ZONE ||
                task.reason === REASON_MISSING_ITEM_ANALYSIS) &&
              task.item
          );

          // Zählungen der Aufgabe zuordnen
          shelf.tasks.forEach((task) => {
            task.countings = itemCountings
              .filter((stockTake) => stockTake.itemId === task.itemId && stockTake.status === StockTakeStatus.DRAFT)
              .map((stockTake) => [stockTake.location, stockTake]);
          });
          shelf.taskCount = shelf.tasks.length;

          // Regale mit -1 oder Aktionsware werden bedingt durch die Menge nicht sortiert und weil sie keine Regalplatzierung haben
          if (-1 !== shelf.number) {
            // Sortierung der Tasks nach Board und Position des Artikels im Warenbild
            shelf.tasks.sort((a, b) => {
              if (!(a.item.shelfPlacements ?? []).length && !(b.item.shelfPlacements ?? []).length) {
                // wenn es keine Platzierungen gibt, neuste Scans nach oben sortieren
                const maxA = a.countings.reduce((timestamp, [, c]) => Math.max(timestamp, c.timestamp), 0);
                const maxB = b.countings.reduce((timestamp, [, c]) => Math.max(timestamp, c.timestamp), 0);

                return maxB - maxA;
              }

              const aBoard = a.item.shelfPlacements?.[0]?.board.position || 9999;
              const bBoard = b.item.shelfPlacements?.[0]?.board.position || 9999;

              const aPosition = a.item.shelfPlacements?.[0]?.position || 9999;
              const bPosition = b.item.shelfPlacements?.[0]?.position || 9999;

              if (aBoard - bBoard === 0) {
                return aPosition - bPosition;
              }

              return aBoard - bBoard;
            });
          }

          if (shelf.taskCount > 0) {
            shelves.set(shelf.number, shelf);
          } else {
            shelves.delete(shelf.number);
          }
        })(shelf)
      );
    });
    // Sentry.captureMessage(`Async filter setup in ${Date.now() - start}ms`, {
    //   level: Sentry.Severity.Debug,
    // });

    await Promise.all(shelfFilter);
    // console.debug(`Completed filter in ${Date.now() - start}ms`);
    // Sentry.captureMessage(`Completed filter in ${Date.now() - start}ms`, {
    //   level: Sentry.Severity.Debug,
    // });

    // Regale alphabetisch sortiert zurückgeben
    return Array.from(shelves.entries()).sort(([, a], [, b]) => (a.name || '').localeCompare(b.name || ''));
  });
};

export const checkOpenShelfTasks = () => async () => {
  if ((await fetchShelfTasks()).filter((task) => typeof task === 'object').length) {
    return {
      allowed: false,
      reason: 'Auf dem Gerät befinden sich noch Inventurdaten. Bitte Inventurdaten absenden.',
    };
  }
  return {
    allowed: true,
  };
};

export const splitShelfTasks =
  (shelfTask: [number, ShelfListing][] | undefined = undefined) =>
  async (dispatch: ThunkDispatch<any, null, any>, getState: () => any) => {
    const state = getState();
    const appModeState = state.app.mode;

    const shelfTasks = shelfTask ?? (await fetchShelfTasks());

    const plannedShelves: [number, ShelfListing][] = [];
    const priorityShelves: [number, ShelfListing][] = [];
    const priorityShelvesByReason: { [key: string]: [number, ShelfListing][] } = {};
    const customShelves: [number, ShelfListing][] = [];
    const zoneShelves: [number, ShelfListing][] = [];
    const brandShelves: { [key: string]: ItemInventoryLocalTask[] } = {};

    shelfTasks.forEach(([number, shelf]) => {
      const priorityTasks = shelf.tasks.filter(
        (task) =>
          task.reason !== 'GPL' && task.reason !== REASON_FREE_INVENTORY && task.reason !== REASON_MISSING_ITEM_ANALYSIS
      );

      const plannedTasks = shelf.tasks.filter((task) => task.reason === 'GPL');
      const customTasks = shelf.tasks.filter((task) => {
        if (AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState) {
          return task.reason === REASON_MISSING_ITEM_ANALYSIS;
        }

        return task.reason === REASON_FREE_INVENTORY;
      });
      const zoneTasks = shelf.tasks.filter((task) => task.reason === REASON_FREE_INVENTORY_ZONE);

      if (plannedTasks.length > 0) {
        plannedShelves.push([number, { ...shelf, tasks: plannedTasks, taskCount: plannedTasks.length }]);
      }

      if (priorityTasks.length > 0) {
        const reasons: string[] = [];
        for (const task of shelf.tasks) {
          if (!reasons.includes(task.reason)) {
            reasons.push(task.reason);
          }
        }

        for (const reason of reasons) {
          const filteredTasks = shelf.tasks.filter(
            (task) =>
              task.reason !== 'GPL' &&
              task.reason !== REASON_FREE_INVENTORY &&
              task.reason !== REASON_MISSING_ITEM_ANALYSIS &&
              task.reason !== REASON_FREE_INVENTORY_ZONE &&
              task.reason === reason
          );
          if (filteredTasks.length > 0) {
            if (priorityShelvesByReason[reason] === undefined) {
              priorityShelvesByReason[reason] = [];
            }
            priorityShelvesByReason[reason].push([
              number,
              { ...shelf, taskCount: filteredTasks.length, tasks: filteredTasks },
            ]);
          }
        }

        priorityShelves.push([number, { ...shelf, tasks: priorityTasks, taskCount: priorityTasks.length }]);
      }

      if (customTasks.length > 0) {
        customShelves.push([number, { ...shelf, tasks: customTasks, taskCount: customTasks.length }]);
      }
      if (zoneTasks.length > 0) {
        zoneShelves.push([number, { ...shelf, tasks: zoneTasks, taskCount: zoneTasks.length }]);
      }

      for (const task of shelf.tasks) {
        if (task.reason === REASON_MANUAL && task.brandName) {
          if (brandShelves[task.brandName] === undefined) {
            brandShelves[task.brandName] = [];
          }
          brandShelves[task.brandName].push(task);
        }
      }
    });

    return {
      brandShelves,
      plannedShelves,
      priorityShelves,
      priorityShelvesByReason,
      customShelves,
      zoneShelves,
    };
  };

export const setClosingDateEvent = () => async (dispatch: ThunkDispatch<any, null, any>) => {
  await dispatch(setActiveEventsByInstruction('closingDate'));
};
