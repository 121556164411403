import { push } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import authProvider, { AUTH, SessionQueryBusinessUnitGroupInterface } from '../../modules/app/client/authProvider';
import { DB } from '../../modules/app/client/dataProvider/storageClient';
import AppConfig from '../../modules/app/config';
import { setSnack, showGlobalProgressModal } from '../interface/actions';
import { setNotAuthenticated, setSessionInfo } from './actions';
import { AUTHENTICATING } from './types';
import createDebugLogger from '../../utils/debug';

export const LOGIN = {
  SUCCESS: 'success',
  WRONG_CREDENTIALS: 'wrongCredentials',
  DEVICE_LOCATION_UNKNOWN: 'deviceLocationUnknown',
  CONNECTION_ISSUES: 'connectionIssues',
};

export const checkUserLoginCredential =
  (username: string, password: string, businessUnitGroup: SessionQueryBusinessUnitGroupInterface | null = null) =>
  (dispatch: ThunkDispatch<any, null, any>) => {
    dispatch({
      type: AUTHENTICATING,
      inProgress: true,
    });
    dispatch(showGlobalProgressModal());

    if (businessUnitGroup) {
      dispatch(authProvider(AUTH.VALIDATE_UNIT, { businessUnitGroup }));
    } else {
      dispatch(authProvider(AUTH.LOGIN, { username, password }));
    }
  };

export const checkLocalStorageToken = () => async (dispatch: ThunkDispatch<any, null, any>) => {
  dispatch({
    type: AUTHENTICATING,
    inProgress: true,
  });

  dispatch(showGlobalProgressModal());
  dispatch(authProvider(AUTH.CHECK));
};

export const logout = () => async (dispatch: ThunkDispatch<any, null, any>) => {
  await dispatch(authProvider(AUTH.LOGOUT));

  await dispatch(setSessionInfo(null));
  dispatch(setNotAuthenticated());
  dispatch(push('/'));
};

export const resetApp = (reason: string) => async (dispatch: ThunkDispatch<any, null, any>) => {
  dispatch(
    setSnack({
      text: `App wird nun zurückgesetzt!`,
      severity: 'error',
    })
  );

  createDebugLogger('App')(`Full app reset requested`, {}, { reason });

  await new Promise((resolve) => setTimeout(resolve, 2000));

  try {
    if ('serviceWorker' in navigator) {
      const swRegistrations = await navigator.serviceWorker.getRegistrations();

      for (const registration of swRegistrations) {
        await registration.unregister();
      }
    }
  } catch (e) {
    // ignore
  }

  try {
    await DB.delete();
  } catch (e) {
    // ignore
  }

  window.history.pushState({}, '', new URL('/', window.location.href).toString());
  window.location.reload();
};

export const resetAppContent = () => async (dispatch: ThunkDispatch<any, null, any>) => {
  dispatch(
    setSnack({
      text: `Filialwechsel erkannt, Daten werden zurücksetzt!`,
      severity: 'warning',
      autoHideDuration: 2000,
    })
  );

  createDebugLogger('App')(`Content reset due to store change`);

  await new Promise((resolve) => setTimeout(resolve, 2000));

  await Promise.all([
    DB.configuration.bulkDelete(
      [
        `sync/itemInventory/version`,
        !AppConfig.getConfig().itemLoader?.laxPriceLoading && `sync/itemPrice/version`,
        `sync/itemStock/version`,
        `sync/stockMovementTasks/version`,
        `sync/stockTakeResults/version`,
      ].filter(Boolean) as string[]
    ),

    DB.inventoryTasks.clear(),
    DB.inventoryTasksByShelves.clear(),
    DB.stockMovementTasks.clear(),
    DB.stockTakeResults.clear(),
  ]);
};
