import {
  AppBar,
  Box,
  Collapse,
  Container,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  ItemInventoryLocalTask,
  ItemInventoryStockUpdateMode,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import NumpadInput from '@meeva/service-client-core/modules/common/components/NumpadInput';
import { validateItemQuantity } from '@meeva/service-client-core/utils/validationHelper';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    itemName: {
      fontSize: theme.typography.pxToRem(22),
      marginBottom: theme.spacing(1),
    },
    itemProps: {
      fontSize: theme.typography.pxToRem(16),
    },
    itemStock: {
      fontSize: theme.typography.pxToRem(16),
    },
    errorText: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: '10px',
      color: '#FF0000',
    },
    itemStockLocation: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
    },
    itemInformationListItem: {
      margin: 0,
      padding: 0,
    },
    itemInformationSubList: {
      margin: 0,
      padding: 0,
    },
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    numPadContainer: {
      marginTop: 'auto',
    },
  });

interface ItemInventoryTaskFormProps {
  task: ItemInventoryLocalTask;
  mode?: ItemInventoryStockUpdateMode;
  inventoryLocation: [string, string];
  onClose: () => void;
  onSave: (value: number | undefined) => void;
  upperStockLimit?: number;
  lowerStockLimit?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemInventoryTaskForm = (props: ItemInventoryTaskFormProps & RouteComponentProps & WithStyles) => {
  const {
    classes,
    mode,
    onClose,
    onSave,
    task,
    inventoryLocation,
    lowerStockLimit = 0,
    upperStockLimit = 99999,
  } = props;

  const [stockInput, setStockInput] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [posIdentityListOpen, setPosIdentityListOpen] = React.useState(false);

  const onFormSave = React.useCallback(() => {
    if (!onSave) {
      return;
    }

    onSave(stockInput === '' ? 0 : Number(stockInput));
  }, [stockInput, onSave]);

  const onChange = React.useCallback(
    (stockInput: string) => {
      const { hasError, message } = validateItemQuantity(stockInput, lowerStockLimit, upperStockLimit);

      if (hasError !== error || message !== errorMessage) {
        setError(hasError);
        setErrorMessage(message);
      }

      setStockInput(stockInput);
    },
    [setStockInput, stockInput, setError, setErrorMessage]
  );
  const onSubmit = React.useCallback(
    (stockInput: string) => {
      const { hasError, message } = validateItemQuantity(stockInput, lowerStockLimit, upperStockLimit);

      if (hasError) {
        setError(hasError);
        setErrorMessage(message);

        return;
      }
      onFormSave();
    },
    [onFormSave, stockInput]
  );

  React.useEffect(() => {
    let amount = 0;
    for (const counting of task.countings) {
      if (counting[0] === inventoryLocation[0]) {
        amount = counting[1].amount;
      }
    }

    setStockInput(amount.toString());
  }, [task]);

  const { item } = task;
  // TODO: Nicht EANs entsprechend ausweisen, statt als "weitere EANs"
  const posIdentities: string[] = item.posIdentities
    .sort(
      (a, b) =>
        (a.index || 0) +
        (a.posItemIdQualifier === 'gtin' ? -1000 : 0) -
        ((b.index || 0) + (b.posItemIdQualifier === 'gtin' ? -1000 : 0))
    )
    .map((posIdentity) => posIdentity.posItemId)
    .filter((posIdentity) => posIdentity !== item.itemNo);

  return (
    <Dialog fullScreen={window.innerWidth <= 600} open={true} onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Bestand eingeben
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Typography variant="h1" className={classes.itemName}>
          {item.description}
        </Typography>
        <Typography variant="caption" className={classes.itemProps}>
          <List component="div">
            <ListItem className={classes.itemInformationListItem}>Artikel-Nr.: {item.itemNo}</ListItem>
            {posIdentities.length ? (
              <React.Fragment>
                <ListItem
                  className={classes.itemInformationListItem}
                  onClick={() => posIdentities.length > 1 && setPosIdentityListOpen((prevState) => !prevState)}
                >
                  EAN: {posIdentities[0]}
                  {posIdentities.length > 1 ? posIdentityListOpen ? <ExpandLess /> : <ExpandMore /> : null}
                </ListItem>
                <Collapse in={posIdentityListOpen}>
                  <Grid container spacing={1}>
                    <Grid item>Weitere EANs:</Grid>
                    <Grid item>
                      <List component="div" className={classes.itemInformationSubList}>
                        {item.posIdentities.map(
                          (posIdentity, index) =>
                            index !== 0 && (
                              <ListItem key={index} className={classes.itemInformationSubList}>
                                {posIdentity.posItemId}
                              </ListItem>
                            )
                        )}
                      </List>
                    </Grid>
                  </Grid>
                </Collapse>
              </React.Fragment>
            ) : null}
          </List>
        </Typography>

        <Box className={classes.numPadContainer} mt={1}>
          <Typography variant="h2" className={classes.itemStock} align="center">
            Bestand im Bereich
          </Typography>

          <Typography variant="h2" className={classes.itemStockLocation} align="center">
            {inventoryLocation[1]}
          </Typography>
          <Typography variant="h1" className={classes.itemStock} align="center">
            {mode === 'replace' && <React.Fragment>Bearbeiten</React.Fragment>}
            {mode === 'add' && <React.Fragment>Hinzufügen</React.Fragment>}
          </Typography>
          {error && (
            <Typography variant="h1" className={classes.errorText} align="center">
              {errorMessage}
            </Typography>
          )}
          <NumpadInput autoFocus value={stockInput} onChange={onChange} onSubmit={onSubmit} />
        </Box>
      </Container>
    </Dialog>
  );
};

export default connect()(
  withRouter(withStyles(styles)(ItemInventoryTaskForm))
) as React.ComponentType<ItemInventoryTaskFormProps>;
