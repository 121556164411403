import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalProgressModal } from '../../../redux/interface/selectors';
import { findItemByCode as helperFindItemByCode } from '../../../utils/items';
import { resetSnack, setSnack, showGlobalProgressModal } from '../../../redux/interface/actions';
import ScannerInput, { DEFAULT_SUPPORTED_CODES } from '../../common/components/ScannerInput';
import { useHistory } from 'react-router';
import { playSuccessSound, playErrorSound } from '../../../utils/audioHelper';
import { AppMode } from '../../../redux/appBehavior/appModeSlice';
import * as ItemCollectorTask from '../../../utils/itemCollectorTaskHelper';
import { ItemCollectorTaskType } from '../../../interfaces/ItemCollectorTaskInterface';
import { ItemInterface } from '../../../interfaces/ItemListInterface';

const ScanInput = () => {
  const [itemNumber, setItemNumber] = useState<string>('');
  const isLoading = useSelector((state) => getGlobalProgressModal(state).visible);
  const appModeState = useSelector((state: any) => state.app.mode);
  const dispatch = useDispatch();
  const history = useHistory();

  // TODO: create generic function for multiple ScanInput components. (Generic ScanInput or custom hook?)
  const addItemToMissingItemAnalysisTask = async (itemToAdd: ItemInterface) => {
    try {
      dispatch(showGlobalProgressModal(true));

      // There should be only one task for this specific list
      const task = (await ItemCollectorTask.getTasksByType(ItemCollectorTaskType.MISSING_ITEM_ANALYSIS_COLLECTION)).at(
        0
      );

      if (!task) {
        ItemCollectorTask.createTask({
          id: '', // will be set later
          description: 'Fehlartikelanalyse',
          timestamp: new Date(),
          type: ItemCollectorTaskType.MISSING_ITEM_ANALYSIS_COLLECTION,
          items: [
            {
              ...itemToAdd,
              timestamp: new Date(),
              quantity: 0, // every item in this tasks should be missing
            },
          ],
        });
      } else {
        const hasItemAlreadyBeenScanned = Boolean(task.items.find((_item) => _item.id === itemToAdd.id));

        if (hasItemAlreadyBeenScanned) {
          dispatch(
            setSnack({
              text: `Der Artikel wurde bereits aufgenommen`,
              severity: 'warning',
              autoHideDuration: 3000,
            })
          );

          return;
        }

        ItemCollectorTask.updateTask(task.id, {
          ...task,
          items: [
            ...task.items,
            {
              ...itemToAdd,
              timestamp: new Date(),
              quantity: 0, // every item in this tasks should be missing
            },
          ],
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(showGlobalProgressModal(false));
    }
  };

  const handleArticleSearch = useCallback(
    async (code: string) => {
      dispatch(showGlobalProgressModal(true));
      const result = await helperFindItemByCode(code);
      setItemNumber('');
      dispatch(showGlobalProgressModal(false));

      if (!result) {
        playErrorSound('item');
        dispatch(
          setSnack({
            severity: 'warning',
            text: 'Artikel nicht gefunden',
          })
        );

        return;
      }

      if (AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState) {
        await addItemToMissingItemAnalysisTask(result);
      }

      playSuccessSound('item');
      dispatch(resetSnack());
      history.push(`/items/details/byCode/${code}`);
    },
    [itemNumber]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScannerInput onScan={handleArticleSearch} supportedCodes={DEFAULT_SUPPORTED_CODES} />
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="article-number-field">Barcode scannen oder eingeben</InputLabel>
          <OutlinedInput
            id="article-number-field"
            type="number"
            label="Barcode scannen oder eingeben"
            value={itemNumber}
            onChange={(event) => setItemNumber(event.target.value as string)}
            disabled={isLoading}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleArticleSearch(itemNumber);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  data-cy="article-search-button"
                  aria-label="Suche starten"
                  onClick={() => handleArticleSearch(itemNumber)}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ScanInput;
