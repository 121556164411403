export const SET_SHELF_TASKS = 'itemInventory/stockTake/SET_SHELF_TASKS';
export const REASON_FREE_INVENTORY = 'FRE';
export const REASON_FREE_INVENTORY_ZONE = 'ZONE';
export const REASON_MISSING_ITEM_ANALYSIS = 'FAA';
export const REASON_MANUAL = 'MAN';

const defaultType = {
  SET_SHELF_TASKS,
};

export default defaultType;
