import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import BrandList from './BrandList';
import ShelfSummary from '../plannedInventory/ShelfSummary';
import BrandTasks from './BrandTasks';
import { BrandTasksInterface } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import ShelfDifferences from '../plannedInventory/ShelfDifferences';

interface ItemInventoryBrandProps {
  tasks: BrandTasksInterface;
}

const ItemInventoryBrands = (props: ItemInventoryBrandProps & RouteComponentProps) => {
  return (
    <Switch>
      <Route
        path={`/itemInventory/type/brands/:brandName/differences`}
        exact
        render={() => <ShelfDifferences {...props} />}
      />
      <Route path="/itemInventory/type/brands/:brandName/summary" exact render={() => <ShelfSummary {...props} />} />
      <Route
        path="/itemInventory/type/brands/:brandName"
        exact
        render={(routeProps) => <BrandTasks {...props} {...routeProps} />}
      />
      {/*<Route*/}
      {/*  path="/itemInventory/type/:type/shelf/:shelfId/location/:inventoryLocationName"*/}
      {/*  exact*/}
      {/*  render={() => <ShelfTasks {...props} />}*/}
      {/*/>*/}
      <Route
        path="/itemInventory/type/brands"
        exact
        render={(routeProps) => <BrandList {...props} {...routeProps} />}
      />
    </Switch>
  );
};

export default connect()(withRouter(ItemInventoryBrands)) as React.ComponentType<ItemInventoryBrandProps>;
