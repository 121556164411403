enum IncomingTransferStatus {
  DRAFT,
  READY,
  SENT,
  ERROR,
}

interface IncomingTransferInterface {
  id: string;
  sourceBusinessUnit: IncomingTransferBusinessUnitInterface;
  containerCode: string;
  lineItems: IncomingTransferLineItemInterface[];
  status: IncomingTransferStatus;
}

interface IncomingTransferLineItemInterface {
  item: {
    id: string;
    itemNo: string;
    name: string;
    customItemNo: string | null;
  };
  unitCount: number;
}

interface IncomingTransferBusinessUnitInterface {
  id: string;
  name: string;
  number?: number;
}

export { IncomingTransferStatus };

export type { IncomingTransferInterface, IncomingTransferLineItemInterface };
