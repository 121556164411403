import { createSlice } from '@reduxjs/toolkit';

enum AppMode {
  NORMAL_MODE = '',
  MISSING_ITEM_ANALYSIS_MODE = 'MISSING_ITEM_ANALYSIS',
}

const initialState = AppMode.NORMAL_MODE;

const appModeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    setAppMode: (state, action) => action.payload,
    resetAppMode: () => initialState,
  },
});

export { AppMode };

export const { setAppMode, resetAppMode } = appModeSlice.actions;

export default appModeSlice.reducer;
