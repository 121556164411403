import FindInPage from '@material-ui/icons/FindInPage';

export default [
  {
    id: 'Fehlartikelanalyse',
    title: 'Fehlartikelanalyse',
    icon: <FindInPage />,
    route: '/missing-item-analysis',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:missingItemAnalysis',
      canRead: true,
      canWrite: true,
    },
  },
];
