import * as types from './types';

export const enabled = (state = true, action: { type: string }) => {
  switch (action.type) {
    case types.ENABLE:
      return true;
    case types.DISABLE:
      return false;
    default:
      return state;
  }
};
