import { deDE } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';

/**
 * http://mcg.mbitson.com/#!?mcgpalette0=%23e23349
 *
 * Für besseren Kontrast auf Dunklem Hintergrund
 */
const PaletteLightRed = {
  50: '#fce7e9',
  100: '#f6c2c8',
  200: '#f199a4',
  300: '#eb7080',
  400: '#e65264',
  500: '#e23349',
  600: '#df2e42',
  700: '#da2739',
  800: '#d62031',
  900: '#cf1421',
  A100: '#ffffff',
  A200: '#ffcccf',
  A400: '#ff999e',
  A700: '#ff8086',
  light: '#e65264',
  main: '#da2739',
  dark: '#cf1421',
};

const theme = createTheme(
  {
    palette: {
      primary: PaletteLightRed,
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  },
  deDE
);

export const lightTheme: any = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiContainer: {
      root: {
        padding: '5px !important',
      },
    },
  },
};
