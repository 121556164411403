import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Backspace as BackspaceIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import Numpad, { NumpadMobileKeyboardLayout } from '@meeva/service-client-core/modules/common/components/Numpad';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StockTransferTaskFormInterface } from '@meeva/service-client-core/src/interfaces/StockTransferInterface';
import { validateItemQuantity } from '@meeva/service-client-core/utils/validationHelper';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import { itemDisplayNumber } from '@meeva/service-client-core';

const useDialogStyle = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    itemName: {
      fontSize: theme.typography.pxToRem(22),
      marginBottom: theme.spacing(1),
    },
    itemProps: {
      fontSize: theme.typography.pxToRem(16),
    },
    itemStock: {
      fontSize: theme.typography.pxToRem(16),
    },
    errorText: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: '10px',
      color: '#FF0000',
    },
    itemInformationListItem: {
      margin: 0,
      padding: 0,
    },
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    stockInput: {
      marginTop: theme.spacing(1),
      '& input': {
        textAlign: 'center',
        fontSize: '22px',
        fontWeight: 900,
        color: '#000',
        padding: '14px 0 14px 60px',
      },
      '& fieldset': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    numPadContainer: {
      marginTop: 'auto',
    },
    numPad: {
      marginTop: 'auto',
      border: '1px solid #aeaeae',
      borderTop: 0,
    },
  })
);

interface Props {
  task: StockTransferTaskFormInterface;
  onClose: () => void;
  onSave: (value: number) => void;
}

const getNextValue = (previousValue: number, keyPressCode: string, valueWillBeOverriden: boolean): number => {
  let nextValue = previousValue;

  if ('backspace' === keyPressCode && previousValue.toString().length) {
    nextValue = Number(previousValue.toString().slice(0, -1));
  }

  if (!isNaN(Number(keyPressCode))) {
    nextValue = Number(previousValue.toString() + keyPressCode);

    if (valueWillBeOverriden) {
      nextValue = Number(keyPressCode);
    }
  }

  return nextValue;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StockTransferItemQuantityDialog = ({ onClose, onSave, task }: Props) => {
  const [quantity, setQuantity] = useState<number>(0);
  const [numPadFirstSet, setNumPadFirstSet] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const classes = useDialogStyle();

  const {
    removalByZero = true,
    lowerLimit = 20,
    upperLimit = 99_999,
  } = AppConfig.getConfig().stockTransfer?.quantityChangeLimit ?? {};
  const { item } = task;

  useEffect(() => {
    setQuantity(task.scannedQuantity);
  }, [task]);

  const onFormSave = useCallback(() => {
    if (!onSave) {
      return;
    }

    onSave(quantity);
  }, [quantity, onSave]);

  const onNumpadInput = useCallback(
    (keyCode: string) => {
      let hasError = false;
      let message = '';

      const nextValue: number = getNextValue(quantity, keyCode, numPadFirstSet);

      if (!(removalByZero && 0 === nextValue)) {
        const validationResult = validateItemQuantity(nextValue, lowerLimit, upperLimit);

        hasError = validationResult.hasError;
        message = validationResult.message;
      }

      if (hasError !== error || message !== errorMessage) {
        setError(hasError);
        setErrorMessage(message);
      }

      if ('return' === keyCode && !hasError) {
        return onFormSave();
      }

      setQuantity(nextValue);
      numPadFirstSet && setNumPadFirstSet(false);
    },
    [onFormSave, quantity, numPadFirstSet]
  );

  const itemNumber = itemDisplayNumber(item);
  const customItemNo = itemDisplayNumber(item, 'customItemNo');
  return (
    <Dialog fullScreen={window.innerWidth <= 600} open={true} onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Gesamtmenge eingeben
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Typography variant="h1" className={classes.itemName}>
          {item.description}
        </Typography>
        <Typography variant="caption" className={classes.itemProps}>
          <List component="div">
            {customItemNo.value && (
              <ListItem className={classes.itemInformationListItem}>
                {customItemNo.label}: {customItemNo.value}
              </ListItem>
            )}
            {itemNumber.hint !== 'itemNo' && (
              <ListItem className={classes.itemInformationListItem}>
                {itemNumber.label}: {itemNumber.value}
              </ListItem>
            )}
            <ListItem className={classes.itemInformationListItem}>Artikel-Nr.: {item.itemNo}</ListItem>
          </List>
        </Typography>

        <Box className={classes.numPadContainer} mt={1}>
          <Typography variant="h2" className={classes.itemStock} align="center">
            Menge
          </Typography>
          {error && (
            <Typography variant="h1" className={classes.errorText} align="center">
              {errorMessage}
            </Typography>
          )}
          <TextField
            fullWidth
            disabled
            type="number"
            variant="outlined"
            value={quantity}
            className={classes.stockInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="backspace" onClick={() => onNumpadInput('backspace')}>
                    <BackspaceIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Numpad keys={NumpadMobileKeyboardLayout} onClick={onNumpadInput} className={classes.numPad} />
        </Box>
      </Container>
    </Dialog>
  );
};

export default StockTransferItemQuantityDialog;
