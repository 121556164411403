export const AUTHENTICATING = 'security/AUTHENTICATING';
export const AUTHENTICATE = 'security/AUTHENTICATE';
export const UNAUTHENTICATE = 'security/UNAUTHENTICATE';
export const SESSION_INFO = 'security/SESSION_INFO';
export const AUTHENTICATE_BUSINESS_UNIT_GROUP = 'security/AUTHENTICATE_BUSINESS_UNIT_GROUP';
export const RESET_BUSINESS_UNIT_GROUP = 'security/RESET_BUSINESS_UNIT_GROUP';

const defaultType = {
  AUTHENTICATING,
  AUTHENTICATE,
  UNAUTHENTICATE,
  SESSION_INFO,
  AUTHENTICATE_BUSINESS_UNIT_GROUP,
  RESET_BUSINESS_UNIT_GROUP,
};

export default defaultType;
