import { SessionQueryBusinessUnitGroupInterface, SessionQueryResult } from '../../modules/app/client/authProvider';
import types from './types';

export const setIsAuthenticated = () => ({
  type: types.AUTHENTICATE,
});

export const setSessionInfo = (info: SessionQueryResult | null) => ({
  type: types.SESSION_INFO,
  payload: info,
});

export const setNotAuthenticated = () => ({
  type: types.UNAUTHENTICATE,
});

export const setAvailableBusinessUnitGroups = (businessUnitGroups: SessionQueryBusinessUnitGroupInterface[]) => ({
  type: types.AUTHENTICATE_BUSINESS_UNIT_GROUP,
  payload: businessUnitGroups,
});

export const resetAvailableBusinessUnitGroups = () => ({ type: types.RESET_BUSINESS_UNIT_GROUP });
