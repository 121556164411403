import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';

interface PlannedShelvesProps {
  tasks: Array<[number, ShelfListing]>;
}

interface ShelfItemProps {
  shelf: ShelfListing;
  onClick: MouseEventHandler;
}

export const getShelfName = (shelf: ShelfListing) => {
  if (shelf.name) {
    return shelf.name;
  }

  // Artikel ohne Regalzuordnung sind Aktionsware
  if (shelf.number === -1) {
    return 'Aktionsware';
  }

  return `Regal ${shelf.number}`;
};

export const ShelfName = ({ shelf }: { shelf: ShelfListing }) => {
  const shelfName = useMemo(() => {
    return getShelfName(shelf);
  }, [shelf]);

  return <>{shelfName}</>;
};

const ShelfItem = ({ shelf, onClick }: ShelfItemProps) => {
  return (
    <ListItem button color="primary" onClick={onClick}>
      <ListItemText primary={<ShelfName shelf={shelf} />} />
      <ListItemSecondaryAction>
        <Avatar>{shelf.taskCount > 99 ? '99+' : shelf.taskCount}</Avatar>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ShelfList = ({ tasks, history, match: routeMatch }: PlannedShelvesProps & RouteComponentProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [shelves, setShelves] = React.useState(new Map<number, ShelfListing>());
  const dispatch = useDispatch();

  const navigateToShelf = useCallback(
    (shelf: ShelfListing) => {
      history.push(`/itemInventory/type/${(routeMatch.params as any).type}/shelf/${shelf.number}`);
    },
    [routeMatch]
  );

  React.useEffect(() => {
    dispatch(setTitle('Inventur nach Regal'));
    dispatch(setNavigationUpwardTarget(`/itemInventory`));
    setShelves(new Map(tasks));
    setLoading(false);
  }, [tasks]);

  if (isLoading) {
    return (
      <Container>
        <Box mt={8} textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (shelves.size === 0) {
    return (
      <Container>
        <Alert severity="info">
          <AlertTitle>Nichts zu tun</AlertTitle>
          Es stehen aktuell keine Inventuraufgaben aus.
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <List component="nav">
            {Array.from(shelves.values()).map((shelf, i) => (
              <ShelfItem key={i} shelf={shelf} onClick={() => navigateToShelf(shelf)} />
            ))}
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default connect()(withRouter(ShelfList)) as React.ComponentType<PlannedShelvesProps>;
