import InventoryIcon from '@material-ui/icons/LocalShipping';

export default [
  {
    id: 'Filialtausch Packen',
    title: 'Filialtausch Packen',
    icon: <InventoryIcon />,
    route: '/stock-transfer/request',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:stockTake',
      canRead: true,
      canWrite: true,
    },
  },
];
