import { SyncStatus } from './reducers';

export const getCurrentLocation = (state: any) => state.router.location.pathname;
export const getTitle = (state: any) => state.interface.title;
export const getOnlineStatus = (state: any) => state.interface.onlineStatus;
export const getSnackMessage = (state: any) => state.interface.snackMessage;
export const getSyncStatus = (state: any): SyncStatus[] => state.interface.syncStatus || [];
export const getSyncMessageQueue = (state: any): any => state.interface.syncMessages || [];
export const getDatabaseMessageQueue = (
  state: any
): { name: string; args: any; responseId: string; callback?: (result: any) => void }[] =>
  state.interface.databaseMessages || [];
export const getGlobalProgressModal = (state: any): { visible: boolean } =>
  state.interface.progressModal || { visible: false };
export const getUpwardNavigationTarget = (state: any): string | null => state.interface.upwardNavigationTarget || null;
