import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Paper, Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { setTitle } from '../../../redux/interface/actions';
import { getCurrentSession, hasPermission } from '../../../redux/security/selectors';
import { SessionPermissionRequest, SessionQueryResult } from '../client/authProvider';
import ScanInput from '../items/ScanInput';
import AppConfig from '../config';
import { CategoryChildInterface } from '../layout/navigator';

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    itemName: {
      paddingBottom: theme.spacing(1),
    },
  });

interface HomeScreenProps extends RouteComponentProps, WithStyles {
  hasPermission: (request: SessionPermissionRequest) => boolean;
  setTitle: typeof setTitle;
  sessionInfo: null | SessionQueryResult;
}

const HomeScreen = (props: HomeScreenProps) => {
  const { history, hasPermission, setTitle, sessionInfo } = props;

  const [menuMap, setMenuMap] = React.useState<CategoryChildInterface[]>([]);

  useEffect(() => {
    if (!sessionInfo || !sessionInfo.businessUnitGroup) {
      return;
    }

    setTitle(`Filiale ${sessionInfo.businessUnitGroup.unit.number}`);
  }, [sessionInfo, setTitle]);

  useEffect(() => {
    const menu: CategoryChildInterface[] = AppConfig.getNavigationMap();

    const visibleMenu = menu.filter((item) => {
      return item.requiredPermission === undefined || hasPermission(item.requiredPermission);
    });

    setMenuMap(visibleMenu);
  }, AppConfig.getNavigationMap());

  const navigateTo = (url: string) => {
    history.push(url);
  };

  return (
    <Container>
      {hasPermission({ name: 'document:item', canRead: true }) && (
        <Box mt={1}>
          <Paper style={{ padding: '5px' }}>
            <ScanInput />
          </Paper>
        </Box>
      )}
      <Box mt={1}>
        <Paper>
          <List component="nav" data-cy="home-navigation-menu">
            {menuMap.map((item, i) => (
              <ListItem
                key={i}
                button
                onClick={() => item.route && navigateTo(item.route)}
                color="primary"
                data-cy="home-navigation-item"
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default connect(
  (state) => ({
    sessionInfo: getCurrentSession(state),
    hasPermission: (request: SessionPermissionRequest) => hasPermission(state, request),
  }),
  {
    setTitle,
  }
)(withRouter(withStyles(styles)(HomeScreen)));
