import { LinearProgress } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import HomeIcon from '@material-ui/icons/Home';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, DispatchProp, useDispatch, useSelector } from 'react-redux';
import { checkForAppUpdate } from '../../../redux/interface/operations';
import { SyncStatus } from '../../../redux/interface/reducers';
import { getCurrentLocation, getSyncStatus } from '../../../redux/interface/selectors';
import { resetApp } from '../../../redux/security/operations';
import { getCurrentSession, getIsAuthenticated, hasPermission } from '../../../redux/security/selectors';
import longPressEvents from '../../common/components/TouchHelper';
import { SessionPermissionRequest } from '../client/authProvider';
import { getConfig } from '../../../redux/common/app/selectors';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    drawer: {
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
    },
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      paddingTop: 5,
      paddingBottom: 5,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    syncContainer: {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${(theme.overrides as any).MuiDivider.root.backgroundColor}`,
    },
    syncButton: {
      width: '100%',
      flexDirection: 'column',
      color: 'rgba(255, 255, 255, 0.7)',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'left',
      alignItems: 'flex-start',
      fontSize: theme.typography.pxToRem(14),

      '& .MuiSvgIcon-root': {
        display: 'inline-flex',
        marginRight: theme.spacing(2),
      },
    },
    syncProgressbar: {
      marginTop: theme.spacing(0.5),
    },
    itemAppUpdate: {
      marginTop: 'auto',
      paddingBottom: theme.spacing(1),
      '& hr': {
        marginBottom: theme.spacing(1),
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    appVersion: {
      fontSize: 10,
    },
    itemActiveItem: {
      color: theme.palette.primary.main,
    },
    itemPrimary: {
      fontSize: 'inherit',
    },
    itemSecondary: {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

const syncProgressBarStyle = (theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      backgroundColor: ({ syncState }: { syncState: SyncStatus }) =>
        syncState.status === 'error' ? theme.palette.error.light : theme.palette.success.light,
    },
  });

export interface CategoryChildInterface {
  id: string;
  icon: React.ReactElement;
  active?: boolean;
  route?: string;
  requiredPermission?: SessionPermissionRequest;
  title?: string;
}

export interface CategoryInterface {
  id: string;
  children: CategoryChildInterface[];
}

export interface NavigatorProps extends WithStyles<typeof styles> {
  config?: any;
  categories: CategoryInterface[];
  hasPermission: (request: SessionPermissionRequest) => boolean;
  currentLocation: string;
  push: (route: string) => void;
  resetApp: (reason: string) => Promise<void>;
  PaperProps: any;
  variant?: any;
  open?: any;
  onClose?: any;
}

interface SyncStatusOverviewProps extends DispatchProp<any>, WithStyles {
  syncStatus: SyncStatus[];
}

interface SyncProgressProps extends WithStyles {
  syncState: SyncStatus;
}

const SyncProgress = withStyles(syncProgressBarStyle)(({ syncState, classes }: SyncProgressProps) => {
  const [hideTimer, setHideTimer] = useState<number | null>(null);
  const [isHidden, setHidden] = useState(false);

  const { status } = syncState;

  useEffect(() => {
    let hideTimer: number | undefined;

    if (status === 'complete') {
      hideTimer = window.setTimeout(() => {
        setHidden(true);
      }, 5000);

      setHideTimer(hideTimer);
    } else {
      setHidden(false);
    }

    return () => {
      if (hideTimer) {
        window.clearTimeout(hideTimer);
      }
    };
  }, [status]);

  useEffect(() => {
    if (status !== 'complete') {
      clearTimeout(hideTimer || undefined);
    }
  }, [status, hideTimer]);

  if (isHidden) {
    return null;
  }

  return (
    <LinearProgress
      classes={classes}
      variant={status === 'initializing' ? 'indeterminate' : 'determinate'}
      value={syncState.progress * 100}
    />
  );
});

const SyncStatusOverview = ({ syncStatus }: SyncStatusOverviewProps) => {
  return (
    <>
      {syncStatus.map((status, i) => (
        <SyncProgress key={i} syncState={status} />
      ))}
    </>
  );
};

const ConnectedSyncStatusOverview = connect((state) => ({
  syncStatus: getSyncStatus(state),
}))(withStyles(styles)(SyncStatusOverview));

const Navigator = (props: NavigatorProps) => {
  const { categories, classes, currentLocation, push, resetApp: forceAppReset, hasPermission, ...other } = props;
  const dispatch = useDispatch();
  const session = useSelector((state) => getCurrentSession(state));
  const syncStatus = useSelector((state) => getSyncStatus(state));
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state));

  const canSync = useMemo(() => {
    return isAuthenticated && !syncStatus.some((syncStatus) => ['active', 'initializing'].includes(syncStatus.status));
  }, [syncStatus, isAuthenticated]);

  const showSyncOverview = useCallback(() => {
    push(`/sync`);
    other.onClose && other.onClose();
  }, [push, other]);

  const filteredCategories = useMemo(() => {
    return _.cloneDeep(categories)
      .map((category) => {
        category.children = category.children.filter((child) => {
          return undefined === child.requiredPermission || hasPermission(child.requiredPermission);
        });

        return category;
      })
      .filter((category) => category.children.length > 0);
  }, [categories, hasPermission]);

  const navigateTo = useCallback(
    (route: string) => {
      push(route);
      other.onClose && other.onClose();
    },
    [push, other]
  );

  const forceAppUpdate = useCallback(async () => {
    dispatch(checkForAppUpdate(true));
  }, [dispatch]);

  const AppTitle = useMemo(() => {
    if (session === null) {
      return process.env.REACT_APP_NAME;
    }

    return `${process.env.REACT_APP_NAME} - Filiale ${session.businessUnitGroup?.unit.number}`;
  }, [session]);

  return (
    <Drawer variant="permanent" {...other} className={classes.drawer}>
      <List disablePadding>
        <ListItem className={clsx(classes.item, classes.itemCategory)} onClick={() => navigateTo('/')}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              secondary: classes.itemSecondary,
            }}
            primary={AppTitle}
            secondary={props.config?.showUserName ? session?.username : null}
          />
        </ListItem>
        {filteredCategories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route, title }) => (
              <ListItem
                key={childId}
                button
                onClick={() => route && navigateTo(route)}
                className={clsx(classes.item, route === currentLocation && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {title || childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>

      <div className={classes.itemAppUpdate}>
        <Divider className={classes.divider} />

        <List disablePadding>
          {isAuthenticated && (
            <ListItem button className={classes.item} alignItems="flex-start" onClick={showSyncOverview}>
              <ListItemIcon className={classes.itemIcon}>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                disableTypography={true}
                primary={canSync ? 'Synchronisieren' : 'Synchronisierung läuft'}
                secondary={<ConnectedSyncStatusOverview />}
              />
            </ListItem>
          )}
          <ListItem
            button
            onClick={forceAppUpdate}
            className={classes.item}
            disabled={!navigator.serviceWorker || !navigator.serviceWorker.controller}
            {...longPressEvents(() => forceAppReset('manual'), 5000)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <RefreshIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              App aktualisieren
              {process.env.REACT_APP_VERSION_HASH && (
                <div className={classes.appVersion}>Version: {process.env.REACT_APP_VERSION_HASH}</div>
              )}
            </ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default connect(
  (state) => ({
    config: getConfig(state),
    currentLocation: getCurrentLocation(state),
    hasPermission: (request: SessionPermissionRequest) => hasPermission(state, request),
  }),
  {
    push,
    resetApp,
  }
)(withStyles(styles)(Navigator));
