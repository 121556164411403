import { LinearProgressProps } from '@material-ui/core/LinearProgress/LinearProgress';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../redux/interface/actions';
import { getSyncStatus } from '../../../redux/interface/selectors';
import { Box, Button, Container, LinearProgress, List, ListItem, Paper, Typography } from '@material-ui/core';
import { triggerBackgroundSync } from '../../../redux/interface/operations';
import { getIsAuthenticated } from '../../../redux/security/selectors';
import { SyncStatus, SyncStatusAdditionalInfo } from '../../../redux/interface/reducers';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const syncProgressBarStyle = (theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      backgroundColor: ({ syncState }: { syncState: SyncStatus }) =>
        syncState.status === 'error' ? theme.palette.error.light : theme.palette.success.light,
    },
  });

export function SyncOverview() {
  const dispatch = useDispatch();
  const syncStatus = useSelector((state) => getSyncStatus(state));
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state));

  const canSync = useMemo(() => {
    return isAuthenticated && !syncStatus.some((syncStatus) => ['active', 'initializing'].includes(syncStatus.status));
  }, [syncStatus, isAuthenticated]);

  useEffect(() => {
    dispatch(setTitle(`Synchronisierung`));
  }, []);

  return (
    <Container>
      <Box mt={1}>
        <Paper style={{ padding: '0.5rem' }}>
          {canSync ? (
            <>
              Wenn Sie die Synchronisation unmittelbar erzwingen möchten, klicken Sie den nachfolgenden Button.
              <p>Die Synchronisation läuft ansonsten automatisch periodisch und bei Bedarf im Hintergrund.</p>
            </>
          ) : (
            <>Die Synchronisation läuft momentan, Sie können die Synchronisation daher nicht starten.</>
          )}
          <ForceSyncButton />
        </Paper>
        <SyncStatusList status={syncStatus} />
      </Box>
    </Container>
  );
}

function ForceSyncButton() {
  const dispatch = useDispatch();
  const syncStatus = useSelector((state) => getSyncStatus(state));
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state));

  const canSync = useMemo(() => {
    return isAuthenticated && !syncStatus.some((syncStatus) => ['active', 'initializing'].includes(syncStatus.status));
  }, [syncStatus, isAuthenticated]);

  const forceSync = useCallback(() => {
    if (!canSync) {
      return;
    }

    dispatch(triggerBackgroundSync('full'));
  }, [canSync]);

  return (
    <Button disabled={!canSync} variant="contained" color="primary" onClick={forceSync} style={{ width: '100%' }}>
      Synchronisation starten
    </Button>
  );
}

const StyledProgress = withStyles(syncProgressBarStyle)(
  ({ syncState, ...props }: LinearProgressProps & { syncState: SyncStatus }) => <LinearProgress {...props} />
);

export const STATUS_MAPPING: { [key: string]: string } = {
  items: 'Artikel: Stammdaten',
  itemShelf: 'Artikel: Regale',
  itemStock: 'Artikel: Bestände',
  itemPrice: 'Artikel: Preise',
  itemInventoryFacts: 'Artikel: Bestandsdaten',
  itemMedia: 'Artikel: Medien',
  event: 'Kalender',
  suppliers: 'Lieferanten',
  distributionPackagingSlips: 'Filial-Verteilung: Lieferungen',
  distributionPackagingSlipsItemScanTasks: 'Filial-Verteilung: Aufgaben',
  requestTransferDocuments: 'Packliste: Dokumente',
  businessUnits: 'Filialen',
  stockMovementTasks: 'Warenbewegungen',
  orderProposalTasks: 'Bestellungen',
  itemCollectorTasks: 'Artikellisten: Aufgaben',
  itemCollectionList: 'Artikellisten: Listen',
  itemInventory: 'Inventur: Aufgaben',
  stockTakeResults: 'Inventur: Ergebnisse',
};

function SyncStatusName({ name }: { name: string }) {
  return <>{name in STATUS_MAPPING ? STATUS_MAPPING[name] : name}</>;
}

function SyncDate({ label, value }: { label: string; value: Date | undefined }) {
  if (!value) {
    return null;
  }

  return (
    <>
      {label}:{' '}
      {new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(value)}
      <br />
    </>
  );
}

function secondsToTime(seconds: number) {
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');

  return `${h}:${m}:${s}`;
}

const StatusAdditionalInfo = ({ info }: { info?: SyncStatusAdditionalInfo }) => {
  if (!info) {
    return null;
  }

  return (
    <>
      {Object.entries(info).map(([label, value], index) => (
        <React.Fragment key={index}>
          {label}: {value === null || value === undefined ? 'unbekannt' : value}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

function SyncStatusList({ status }: { status: SyncStatus[] }) {
  // Liste gemäß Mapping sortieren
  const sortedStatus: SyncStatus[] = useMemo(() => {
    const result: SyncStatus[] = [];
    Object.keys(STATUS_MAPPING).forEach((name) => {
      const item = status.find((s) => s.name === name);

      if (item) {
        result.push(item);
      }
    });

    return result;
  }, [status]);

  return (
    <Box mt={2}>
      <Paper style={{ padding: '1rem' }}>
        <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>
          Übersicht
        </Typography>

        <List disablePadding>
          {sortedStatus.map((status) => (
            <ListItem disableGutters key={status.name} style={{ flexDirection: 'column' }} alignItems="flex-start">
              <SyncStatusName name={status.name} />
              <Typography color="textSecondary" style={{ fontSize: '0.75rem' }}>
                <SyncDate label="Letzte Prüfung" value={status.lastRun} />
                <SyncDate label="Datenstand" value={status.lastUpdate} />
                {status.estimation !== undefined && (
                  <StatusAdditionalInfo info={{ 'Geschätzte Restzeit': secondsToTime(status.estimation) }} />
                )}
                <StatusAdditionalInfo info={status.additionalInfo} />
              </Typography>
              <StyledProgress
                syncState={status}
                variant={status.status === 'initializing' ? 'indeterminate' : 'determinate'}
                value={status.progress * 100}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
