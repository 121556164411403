import * as types from './types';

export type SyncStatusAdditionalInfo = {
  [label: string]: string | number | null | undefined;
};

export interface SyncStatus {
  name: string;
  status: 'initializing' | 'active' | 'complete' | 'error';
  progress: number;
  estimation?: number;
  lastRun?: Date;
  lastUpdate?: Date;
  additionalInfo?: SyncStatusAdditionalInfo;
}

type SeverityTypes = 'warning' | 'error' | 'info' | 'success';

export interface SnackInterface {
  severity: SeverityTypes;
  text: string;
  autoHideDuration?: number;
  positionTop?: number;
}

const defaultSnack = {
  open: false,
  severity: 'warning',
  text: '',
};

export const snackMessage = (state = defaultSnack, action: { type: string; payload?: SnackInterface }) => {
  switch (action.type) {
    case types.SET_SNACK:
      return action.payload;

    case types.RESET_SNACK:
      // just close to avoid FUC
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const progressModal = (state = { visible: false }, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case types.SHOW_PROGRESS_MODAL:
      return {
        ...state,
        visible: action.payload,
      };

    default:
      return state;
  }
};

export const title = (state = null, action: { type: string; payload?: string }) => {
  switch (action.type) {
    case types.SET_TITLE:
      return action.payload;

    default:
      return state;
  }
};

export const onlineStatus = (state = false, action: { type: string; payload: boolean | undefined }) => {
  switch (action.type) {
    case types.SET_ONLINE_STATUS:
      return action.payload;

    default:
      return state;
  }
};

export const syncStatus = (state: SyncStatus[] = [], action: { type: string; payload: SyncStatus }) => {
  switch (action.type) {
    case types.SYNC_STATUS:
      return [...state.filter((status) => status.name !== action.payload.name), action.payload];

    default:
      return state;
  }
};

export const syncMessages = (state: any[] = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case types.SYNC_MESSAGE:
      return [...state, action.payload];

    default:
      return state;
  }
};

export const databaseMessages = (state: any[] = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case types.DATABASE_MESSAGE:
      return [...state, action.payload];

    default:
      return state;
  }
};

export const upwardNavigationTarget = (
  state: string | null = null,
  action: { type: string; payload: string | null }
) => {
  switch (action.type) {
    case types.SET_NAVIGATION_UPWARD_TARGET:
      return action.payload;

    default:
      return state;
  }
};
