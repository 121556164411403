import ArchiveIcon from '@material-ui/icons/Archive';

export default [
  {
    id: 'Filialtausch Annahme',
    title: 'Filialtausch Annahme',
    icon: <ArchiveIcon />,
    route: '/stock-transfer/receive',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:stockTake',
      canRead: true,
      canWrite: true,
    },
  },
];
