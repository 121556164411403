import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

export default [
  {
    id: 'Freie Kistenbestückung',
    title: 'Freie Kistenbestückung',
    icon: <CompareArrowsIcon />,
    route: '/stock-transfer/free',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:stockTake',
      canRead: true,
      canWrite: true,
    },
  },
];
