import { Info as InfoIcon } from '@material-ui/icons';
import React from 'react';
import { App, Module } from '../../interfaces/AppInterface';

export default class CoreModule implements Module {
  init(app: App) {
    app.addNavigations([
      {
        id: 'itemDetails',
        route: '/items/details',
        icon: <InfoIcon />,
        title: 'Artikel-Info',
        requiredPermission: { name: 'document:item', canRead: true },
      },
    ]);
  }
}
