import * as React from 'react';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

export default [
  {
    id: 'Lieferung',
    title: 'Lieferung',
    icon: <LocalShippingIcon />,
    route: '/packagingSlip',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:packagingSlip',
      canRead: true,
      canWrite: true,
    },
  },
];
